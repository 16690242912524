import * as actionTypes from "../actionTypes.js";

const initialState = {
  contactRequestsList: null
};

const watchTowerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTACT_REQUESTS_WT:
      return { ...state, contactRequestsList: action.payload };

    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default watchTowerReducer;