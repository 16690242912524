import React from 'react';

import { 
  Box,
} from '@mui/material';

import TopBar from '../../../../components/utils/TopBar';

const BusinessPage = () => {
  return (
    <Box> 
      <TopBar 
        title={"Business"}
        />

    </Box>
  )
}

export default BusinessPage