import React from 'react';

import { 
  Box,
  Typography
} from '@mui/material';

import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import PhishingOutlinedIcon from '@mui/icons-material/PhishingOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import "../styles.scss";

const FusionOrum = ({
  navigate,
  currentPage
}) => {
  return (
    <Box className="nav__side-common" 
      display={"flex"} flexDirectsion={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/orum");
          }}>
          <HomeOutlinedIcon />
          <Typography variant='body2'>
            Home Page
          </Typography>
        </Box>
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/orum/businesses");
          }}>
          <BusinessOutlinedIcon />
          <Typography variant='body2'>
            Businesses
          </Typography>
        </Box>
				<Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/orum/persons");
          }}>
          <PersonOutlinedIcon />
          <Typography variant='body2'>
            Persons
          </Typography>
        </Box>
        <Box className={`${currentPage === 3 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/orum/transfers");
          }}>
          <SyncAltIcon />
          <Typography variant='body2'>
            Transfers
          </Typography>
        </Box>
        <Box className={`${currentPage === 4 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/orum/verify");
          }}>
          <VerifiedOutlinedIcon />
          <Typography variant='body2'>
            Verify
          </Typography>
        </Box>
        <Box className={`${currentPage === 5 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/orum/webhooks");
          }}>
          <PhishingOutlinedIcon />
          <Typography variant='body2'>
            Webhooks
          </Typography>
        </Box>
      </Box>
    </Box>  
  )
}

export default FusionOrum;