import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import useOnBehalfOfAccount from '../../../../../components/hooks/useOnBehalfOfAccount';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import DateFormatter from '../../../../../components/utils/DateFormatter';
import Copyable from '../../../../../components/utils/Copyable';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import { 
  GetConversionsBIZfusionCC 
} from '../../../../../redux/actions/business/fusion/cc/conversionsActions';

const ConversionsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.fusion.cc"]);
  const [loading, setLoading] = useState(true);
  const contactId = useOnBehalfOfAccount();
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const [conversionsList, setConversionsList] = useState([]);
  const [conversionsTotal, setConversionsTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();
  
  //   {
    //     "id": "15f16169-e892-45f3-a4e8-c8498b53c82f",
    //     "shortReference": "20241119-TVGBNC",
    //     "uniqueRequestId": "123457INR",

//     "settlementDate": "2024-11-19T16:30:00+00:00",
//     "conversionDate": "2024-11-19T00:00:00+00:00",

//     "currencyPair": "USDINR",
//     "buyCurrency": "USD",
//     "sellCurrency": "INR",

//     "clientBuyAmount": "100000.00",
//     "clientSellAmount": "8534740.00",

//     "fixedSide": "buy",

//     "coreRate": "84.8806",
//     "clientRate": "85.3474",

//     "midMarketRate": "84.0317"

//     "status": "closed",

// }

  const tableHeadingsList = [
    {
      label: "Id",
      sort: null,
    },
    {
      label: "Dates",
      sort: null,
    },
    {
      label: "Pair",
      sort: null,
    },
    {
      label: "Amounts",
      sort: null,
    }, 
    {
      label: "Fixed side",
      sort: null,
    },
    {
      label: "Rates",
      sort: null,
    },
    {
      label: "Status",
      sort: null,
    },
    {
      label: "Mid market rate",
      sort: null,
    },
    {
      label: "Time stamps",
      sort: null,
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
		handleGetConversionsList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sort, sortDirection]);

  useEffect(() => {
    try {
      formatConversionsList(state.conversionsList.records || []);
      setConversionsTotal(state.conversionsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatConversionsList = (list) => {
    setConversionsList(list);
  };

	async function handleGetConversionsList(customLoading) {
    let query = ""
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    query += `&on_behalf_of=${contactId}`;
    dispatch(GetConversionsBIZfusionCC(page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
	};

  return (
    <Box>

      <TopBar
        title={"Conversions"}
        button1Text={"Add Conversion"}
        button1OnClick={() => {

        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          conversionsList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No conversion found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                conversionsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
												<Copyable>
                        	{row.id}
												</Copyable>
                      </Typography>
                      <Tooltip
                        title="Short reference">
                        <Typography
                          variant='caption'
                          color={"grey"}>
                          <Copyable>
                            {row.shortReference}
                          </Copyable>
                        </Typography>
                      </Tooltip>
                      <Tooltip
                        title="Unique request id">
                        <Typography
                          variant='caption'
                          color={"grey"}>
                          <Copyable>
                            {row.uniqueRequestId}
                          </Copyable>
                        </Typography>
                      </Tooltip>
                    </TableCell>
										<TableCell>
                      <Box>
                        <Tooltip
                          title="Conversion date">
                          <Typography
                            variant='caption'>
                              {row.conversionDate ? DateFormatter(row.conversionDate) : <NA />}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Tooltip
                        title="Settlement date">
                        <Typography
                          variant='caption'>
                            {row.settlementDate ? DateFormatter(row.settlementDate) : <NA />}
                        </Typography>
                      </Tooltip>
										</TableCell>
										<TableCell>
                      <Box
                        className="flexCenter_Row">
                        <Tooltip  
                          title="Buy currency">
                          <Typography
                            variant='caption'>
                            {row.buyCurrency || <NA />}
                          </Typography>
                        </Tooltip>  
                        &nbsp;-&nbsp;
                        <Tooltip
                          title="Sell currency">
                          <Typography
                            variant='caption'>
                            {row.sellCurrency || <NA />}
                          </Typography>
                        </Tooltip>
                      </Box>
										</TableCell>
                    <TableCell>
                      <Tooltip  
                        title="Client buy amount">
                        <Typography
                          variant='caption'>
                          {row.clientBuyAmount || <NA />}
                        </Typography>
                      </Tooltip>  
                      &nbsp;-&nbsp;
                      <Tooltip
                        title="Client sell amount">
                        <Typography
                          variant='caption'>
                          {row.clientSellAmount || <NA />}
                        </Typography>
                      </Tooltip>
										</TableCell>
                    <TableCell>
                      {row.fixedSide || <NA />}
										</TableCell>
                    <TableCell>
                      <Box
                        className="flexCenter_Row">
                        <Tooltip  
                          title="Core rate">
                          <Typography
                            variant='caption'>
                            {row.coreRate || <NA />}
                          </Typography>
                        </Tooltip>  
                        &nbsp;-&nbsp;
                        <Tooltip
                          title="Client rate">
                          <Typography
                            variant='caption'>
                            {row.clientRate || <NA />}
                          </Typography>
                        </Tooltip>
                      </Box>
										</TableCell>
                    <TableCell>
											<Typography
												variant='caption'>
												<Copyable>
													{row.midMarketRate || <NA />}
												</Copyable>
											</Typography>
										</TableCell>
                    <TableCell>
											{row.status || <NA />}
										</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                   rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={conversionsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default ConversionsPage;