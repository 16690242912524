import React, { useEffect } from 'react';

import {
  Box,
  Button,
  Divider,
  Modal, 
  Typography
} from '@mui/material';


import style from "../../../global.scss";
import "./styles.scss";


const IssuesDetailsModal = ({open, setOpen, title}) => {
  useEffect(() => {
    if(open) {

    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box 
          sx={{ maxWidth: "lg", margin: "10px" }}
          className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px 15px 30px 15px"}}>
            <Typography variant='h6' sx={{ color: style["primary"] }} mb="20px">
              {title}
            </Typography>
          </Box>

            
        
          <Divider />
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='outlined'
              onClick={handleClose}>
              Cancel
            </Button>
            {/* <LoadingButton 
              variant='contained'
              onClick={handleSubmit}>
              Submit
            </LoadingButton> */}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default IssuesDetailsModal;