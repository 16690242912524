import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import global from "../../../../global.scss";
import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateCurrencyCONvcn, 
  GetCurrenciesCONvcn, 
  UpdateCurrencyCONvcn
} from '../../../../redux/actions/consumer/virtualCards/currenciesAction';

const Data = {
  Name: "",
  CharacterCode: "",
  NumericCode: ""
}

const CurrenciesModal = ({
  open, 
  setOpen, 
  title,
  update,
  page, 
  rowsPerPage
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  }

  useEffect(() => {
    try {
      if(open){
        if(title === "Add Currency"){
          // mode = 1 - create
          setMode(1);
        }else if(title.includes("Edit Currency")){
          // mode = 2 - edit
          setMode(2);
          setFormData({
            ...formData,
            Name: update.Name || "",
            CharacterCode: update.CharacterCode || "",
            NumericCode: update.NumericCode || "",
          })
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // --------------- handle change ----------------
  const handleChange = (e) => {
    if(e.target.name === "CharacterCode"){
      setFormData({ ...formData, [e.target.name]: e.target.value.toString().toUpperCase() });
    }else{
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  }

  const handleSubmit = () => {
    if(mode === 1){
      // create 
      dispatch(CreateCurrencyCONvcn(formData, setLoading)).then(() => {
        dispatch(GetCurrenciesCONvcn(page+1, rowsPerPage, setLoading)).then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // edit
      dispatch(UpdateCurrencyCONvcn(update.Id, formData, setLoading)).then(() => {
        dispatch(GetCurrenciesCONvcn(page+1, rowsPerPage, setLoading)).then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Name"
                fullWidth
                name="Name"
                value={formData.Name}
                onChange={handleChange}
                />
              <TextField 
                label="CharacterCode"
                fullWidth
                name="CharacterCode"
                inputProps={{ maxLength: 3 }}
                value={formData.CharacterCode}
                onChange={handleChange}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="NumericCode"
                fullWidth
                type='number'
                name="NumericCode"
                value={formData.NumericCode}
                onChange={handleChange}
                />
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.Name !== "" && formData.CharacterCode !== "" && formData.NumericCode !== "")}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default CurrenciesModal;