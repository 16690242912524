import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import useOnBehalfOfAccount from '../../../../../components/hooks/useOnBehalfOfAccount';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import DateFormatter from '../../../../../components/utils/DateFormatter';
import Copyable from '../../../../../components/utils/Copyable';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import { 
  GetPaymentsBIZfusionCC 
} from '../../../../../redux/actions/business/fusion/cc/paymentsActions';

const PaymentsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.fusion.cc"]);
  const [loading, setLoading] = useState(true);
  const contactId = useOnBehalfOfAccount();
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const [paymentsList, setPaymentsList] = useState([]);
  const [paymentsTotal, setPaymentsTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: null,
    },
    {
      label: "Amount",
      sort: null,
    },
    {
      label: "Beneficiary",
      sort: null,
    },
    {
      label: "Dates",
      sort: null,
    }, 
    {
      label: "Conversion Id",
      sort: null,
    },
    {
      label: "Payment type",
      sort: null,
    },
    {
      label: "Reason",
      sort: null,
    },
    {
      label: "Fees",
      sort: null,
    },
    {
      label: "Status",
      sort: null,
    },
    {
      label: "Time stamps",
      sort: null,
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
		handleGetPaymentsList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sort, sortDirection]);

  useEffect(() => {
    try {
      formatPaymentsList(state.paymentsList.records || []);
      setPaymentsTotal(state.paymentsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatPaymentsList = (list) => {
    setPaymentsList(list);
  };

	async function handleGetPaymentsList(customLoading) {
    let query = ""
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    query += `&on_behalf_of=${contactId}`;
    dispatch(GetPaymentsBIZfusionCC(page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
	};

  return (
    <Box>

      <TopBar
        title={"Payments"}
        button1Text={"Add Payment"}
        button1OnClick={() => {

        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          paymentsList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No payment found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                paymentsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                     <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
												<Copyable>
                        	{row.id}
												</Copyable>
                      </Typography>
                      <Tooltip
                        title="Short reference">
                        <Typography
                          variant='caption'
                          color={"grey"}>
                          <Copyable>
                            {row.shortReference}
                          </Copyable>
                        </Typography>
                      </Tooltip>
                      <Tooltip
                        title="Unique request id">
                        <Typography
                          variant='caption'
                          color={"grey"}>
                          <Copyable>
                            {row.uniqueRequestId}
                          </Copyable>
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    {/* //   {

//     "reviewStatus": "passed",
//     "status": "completed",

// } */}
										<TableCell>
											<Typography
												variant='caption'>
													{row.currency || <NA />}&nbsp;
                          {row.amount || <NA />}
											</Typography>
										</TableCell>
										<TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        <Copyable>
                          {row.beneficiaryId || <NA />}
                        </Copyable>
                      </Typography>
										</TableCell>
                    <TableCell>
                      <Box>
                        <Tooltip
                          title="Payment date">
                          <Typography
                            variant='caption'>
                              {row.paymentDate ? DateFormatter(row.paymentDate) : <NA />}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Tooltip
                        title="Transferred at">
                        <Typography
                          variant='caption'>
                            {row.transferredAt ? DateFormatter(row.transferredAt) : <NA />}
                        </Typography>
                      </Tooltip>
										</TableCell>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        <Copyable>
                          {row.conversionId || <NA />}
                        </Copyable>
                      </Typography>
										</TableCell>
                    <TableCell>
                      {row.paymentType || <NA />}
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Tooltip
                          title="Reason">
                          <Typography
                            variant='caption'>
                              {row.reason || <NA />}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Tooltip
                        title="Reference">
                        <Typography
                          variant='caption'>
                            {row.reference || <NA />}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant='caption'>
													{row.feeCurrency || <NA />}&nbsp;
                          {row.feeAmount || <NA />}
											</Typography>
										</TableCell>
                    <TableCell>
                      <Box>
                        <Tooltip
                          title="Status">
                          <Typography
                            variant='caption'>
                              {row.status || <NA />}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Tooltip
                        title="Review status">
                        <Typography
                          variant='caption'>
                            {row.reviewStatus || <NA />}
                        </Typography>
                      </Tooltip>
										</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                   rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={paymentsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default PaymentsPage;