import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

import { CookiesProvider } from 'react-cookie';

import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider defaultSetOptions={{ path: '/' }}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer
          position="bottom-right"
          autoClose={4000}
          // hideProgressBar
          closeOnClick
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          closeButton={false}
          limit={3}
          transition={Slide}
          theme="dark"
          />
        <App />
      </PersistGate>
    </Provider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
