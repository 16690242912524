import * as actionTypes from "../actionTypes.js";

const initialState = {
  activitiesList: null,
  webhookLogsList: null
};

const businessWebhookReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVITIES_BIZ_WEBHOOK:
      return { ...state, activitiesList: action.payload };
    case actionTypes.GET_WEBHOOK_LOGS_BIZ_WEBHOOK:
      return { ...state, webhookLogsList: action.payload };
   

    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default businessWebhookReducer;