import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateCompanyPlanAddonBIZsubs, 
  UpdateCompanyPlanAddonBIZsubs 
} from '../../../../redux/actions/business/subscriptions/companyPlanAddonsActions';
import { 
  GetCompaniesBIZidentity 
} from '../../../../redux/actions/business/identity/companiesActions';
import { 
  GetIntegrationsBIZsubs 
} from '../../../../redux/actions/business/subscriptions/integrationsActions';
import { 
  GetPlanAddonsBIZsubs 
} from '../../../../redux/actions/business/subscriptions/planAddonsActions';

import global from "../../../../global.scss";

const Data = {
  Company: "",
  Addon: "",
  Integration: "",
};

const CompanyPlanAddonsModal = ({
  open, 
  setOpen, 
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.subscriptions"]);
  const companies = useSelector(state => state["business.identity"]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const [companiesList, setCompaniesList] = useState([]);
  const [planAddonsList, setPlanAddonsList] = useState([]);
  const [integrationsList, setIntegrationsList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  }

  useEffect(() => {
    try {
      dispatch(GetCompaniesBIZidentity(1, 1000, "", setLoading));
      dispatch(GetPlanAddonsBIZsubs(1, 1000, "", setLoading));
      dispatch(GetIntegrationsBIZsubs(1, 1000, "", setLoading));
      if(open){
        if(update.defaultMode === 1){
          // mode = 1 - create company plan addon
          setMode(1);
        }else if(update.defaultMode === 2){
          // mode = 2 - edit company plan addon
          setMode(2);
          setFormData({
            ...formData,
            Company: update.ref_company_id || "",
            Addon: update.ref_addon_id || "",
            Integration: update.ref_integration_id || "",
          })
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      setCompaniesList(companies.companiesList.records || []);
    } catch (err) {}
  }, [companies]);

  useEffect(() => {
    try {
      formatIntegrationsList(state.integrationsList.records || []);
      formatPlanAddonsList(state.planAddonsList.records || []);
    } catch (err) {}
  }, [state]);

  const formatIntegrationsList = (list) => {
    setIntegrationsList(list);
  };

  const formatPlanAddonsList = (list) => {
    setPlanAddonsList(list.filter(i => i.is_active === true) || []);
  };

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = () => {
    if(mode === 1){
      // create company plan addon
      let obj = {
        ref_company_id: formData.Company || "",
        ref_addon_id: formData.Addon || "",
        ref_integration_id: formData.Integration || "",
      };
      dispatch(CreateCompanyPlanAddonBIZsubs(obj, setLoading)).then(() => {
        handleGet().then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // update company plan addon
      let obj = {
        ref_company_id: formData.Company || "",
        ref_addon_id: formData.Addon || "",
        ref_integration_id: formData.Integration || "",
      };
      dispatch(UpdateCompanyPlanAddonBIZsubs(update.id, obj, setLoading)).then(() => {
        handleGet().then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Company"
                fullWidth
                name="Company"
                value={formData.Company}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  companiesList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}>
                      {item.legal_name}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Plan addon"
                fullWidth
                name="Addon"
                value={formData.Addon}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  planAddonsList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}>
                      {item.addon_name}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Integration"
                fullWidth
                name="Integration"
                value={formData.Integration}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  integrationsList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}>
                      {item.integ_with_software}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.Company !== "" && formData.Addon !== "" && formData.Integration !== "" )}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default CompanyPlanAddonsModal;