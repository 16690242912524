import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

// import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import Copyable from '../../../../../components/utils/Copyable';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { 
  DeleteWebhookBIZfusionORUM,
  GetWebhooksBIZfusionORUM 
} from '../../../../../redux/actions/business/fusion/orum/webhooksActions';

import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import WebhooksModal from './WebhooksModal';

import global from "../../../../../global.scss";

const WebhooksPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.fusion.orum"]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [webhooksModalOpen, setWebhooksModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [webhooksList, setWebhooksList] = useState([]);

  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const [webhooksMenuOpen, setWebhooksMenuOpen] = useState(false);
  const [webhooksMenuAnchor, setWebhooksMenuAnchor] = useState(null);

  // const [
  //   page, 
  //   rowsPerPage, 
  //   handleChangePage, 
  //   handleChangeRowsPerPage
  // ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: null,
    },
    {
      label: "Enterprise name",
      sort: null,
    },
    {
      label: "Enabled",
      sort: null,
    },
    {
      label: "Event types",
      sort: null,
    }, 
    {
      label: "Url",
      sort: null,
    },
    {
      label: "Data version",
      sort: null,
    },
    {
      label: "Time stamps",
      sort: null,
    },
    {
      label: null,
      sort: null,
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
		handleGetWebhooks();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      formatWebhooksList(state.webhooksList.webhook_configurations || []);
    } catch (err) {}
  }, [state]);

  const formatWebhooksList = (list) => {
    setWebhooksList(list);
  };

	async function handleGetWebhooks(customLoading) {
    dispatch(GetWebhooksBIZfusionORUM(customLoading ? customLoading : setLoading));
	};

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteWebhookBIZfusionORUM(deleteId, setDeleting)).then(() => {
      handleGetWebhooks(setDeleting).then(() => {
        setDeleteId(null);
      })
    });
  };

  return (
    <Box>

      <WebhooksModal  
        open={webhooksModalOpen}
        setOpen={setWebhooksModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetWebhooks}
        />

      <ConfirmationModal 
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete}
        />

      <TopBar
        title={"Webhooks"}
        button1Text={"Add webhook"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add webhook")
          setWebhooksModalOpen(!webhooksModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          webhooksList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No webhook found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                webhooksList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
												<Copyable>
                        	{row.id}
												</Copyable>
                      </Typography>
                    </TableCell>
										<TableCell>
											<Typography
												variant='caption'>
												<Copyable>
													{row.enterprise_name || <NA />}
												</Copyable>
											</Typography>
										</TableCell>
										<TableCell>
                      {row.enabled ? "Yes" : "No" || <NA />}
										</TableCell>
										<TableCell>
                      {row.event_types.join(", ") || <NA />}
										</TableCell>
										<TableCell>
											<Copyable>
												{row.url || <NA />}
											</Copyable>
										</TableCell>
										<TableCell>
											{row.data_version || <NA />}
										</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.created_at}
                        updatedAt={row.updated_at}
                        />
                    </TableCell>
                    <TableCell>
                      {
                        row.id === deleteId && deleting
                        ?
                        <CircularProgress size={26} sx={{ margin: "7px" }}/>
                        :
                        <IconButton
                          onClick={(e) => {
                            setWebhooksMenuAnchor(e.currentTarget);
                            setWebhooksMenuOpen(true);
                            setDeleteId(row.id);
                            setUpdate(row);
                            setModalTitle(row.url || "");
                          }}>
                          <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            {/* <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                   rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={webhooksTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter> */}
          </Table>
      }
      <Menu
        open={webhooksMenuOpen}
        anchorEl={webhooksMenuAnchor}
        onClose={() => setWebhooksMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setWebhooksMenuOpen(false);
            setWebhooksModalOpen(!webhooksModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setWebhooksMenuOpen(false);
            setDeleteConfirmationModalOpen(true);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default WebhooksPage;