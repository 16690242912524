import React from 'react';

import { 
  Box, 
  Divider, 
  Typography 
} from '@mui/material';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import AddCardIcon from '@mui/icons-material/AddCard';

import "./styles.scss";

const ConsumerNavigation = ({
  path, 
  currentPage, 
  navigate
}) => {
  return (
    path.length > 2 && 
    path[2] === "extension"
    ?
    <Box className="nav__side-common" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/consumer");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/consumer/extension");
          }}>
          <ExtensionOutlinedIcon />
          <Typography variant='body2'>
            Extension
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/consumer/extension/card");
          }}>
          <AddCardOutlinedIcon />
          <Typography variant='body2'>
            Card Rules
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/consumer/extension/price");
          }}>
          <LocalOfferOutlinedIcon />
          <Typography variant='body2'>
            Price Rules
          </Typography>
        </Box>
      </Box>
    </Box>    
    :
      path.length > 2 && 
      path[2] === "payments"
      ?
      <Box className="nav__side-common" 
        display={"flex"} flexDirection={"column"} gap={"10px"}>
        <Box className="nav__side-container">
          <Box className={`nav__side-item flexCenter_Row`}
            onClick={() => {
              navigate("/consumer");
            }}>
            <ArrowBackIcon />
            <Typography variant='body2'>
              Back
            </Typography>
          </Box>
          <Divider />
          <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
            onClick={() => {
              navigate("/consumer/payments/currencies");
            }}>
            <AttachMoneyOutlinedIcon />
            <Typography variant='body2'>
              Currencies
            </Typography>
          </Box>
          <Divider />
          <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
            onClick={() => {
              navigate("/consumer/payments/users");
            }}>
            <PeopleAltOutlinedIcon />
            <Typography variant='body2'>
              Users
            </Typography>
          </Box>
        </Box>
      </Box>  
      :
        path.length > 2 && 
        path[2] === "virtual-cards"
        ?
        <Box className="nav__side-common" 
          display={"flex"} flexDirection={"column"} gap={"10px"}>
          <Box className="nav__side-container">
            <Box className={`nav__side-item flexCenter_Row`}
              onClick={() => {
                navigate("/consumer");
              }}>
              <ArrowBackIcon />
              <Typography variant='body2'>
                Back
              </Typography>
            </Box>
            <Divider />
            <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
              onClick={() => {
                navigate("/consumer/virtual-cards/currencies");
              }}>
              <AttachMoneyOutlinedIcon />
              <Typography variant='body2'>
                Currencies
              </Typography>
            </Box>
          </Box>
        </Box>
        :
        <Box className="nav__side-common flex_SBColumn" 
          display={"flex"} flexDirection={"column"} gap={"10px"}>
          <Box className="nav__side-container">
            <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
              onClick={() => {
                navigate("/consumer");
              }}>
              <HomeOutlinedIcon />
              <Typography variant='body2'>
                Home Page - Consumer
              </Typography>
            </Box>
            <Divider />
            <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
              onClick={() => {
                navigate("/consumer/users");
              }}>
              <PeopleAltOutlinedIcon />
              <Typography variant='body2'>
                Users
              </Typography>
            </Box>
            <Divider />
            <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
              onClick={() => {
                navigate("/consumer/internal-users");
              }}>
              <ManageAccountsOutlinedIcon />
              <Typography variant='body2'>
                Internal Users
              </Typography>
            </Box>
          </Box>

            
          <Box display={"flex"}
            flexDirection={"column"}
            gap={"10px"}>
            <Box className="nav__side-container">
              <Box className={`nav__side-item flexCenter_Row`}
                onClick={() => navigate("/consumer/payments/currencies")}>
                <CreditCardOutlinedIcon />
                <Typography variant='body2'>
                  Payments - MS
                </Typography>
              </Box>
            </Box>

            <Box className="nav__side-container">
              <Box className={`nav__side-item flexCenter_Row`}
                onClick={() => navigate("/consumer/virtual-cards/currencies")}>
                <AddCardIcon />
                <Typography variant='body2'>
                  VirtualCards - MS
                </Typography>
              </Box>
            </Box>

            <Box className="nav__side-container">
              <Box className={`nav__side-item flexCenter_Row`}
                onClick={() => navigate("/consumer/extension")}>
                <ExtensionOutlinedIcon />
                <Typography variant='body2'>
                  Extension
                </Typography>
              </Box>
            </Box>
          </Box>  
        </Box>
  )
}

export default ConsumerNavigation