import { useSelector } from 'react-redux';

const useOnBehalfOfAccount = () => {
  const state = useSelector(state => state["business.fusion.cc"]);

  // uuid of the contact in the account   
  return (
    state.selectedAccount
    ? state.selectedAccount.contactId  
    : null
  )
}

export default useOnBehalfOfAccount;