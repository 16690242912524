import React from 'react';

import { 
  Box,
  MenuItem,
  TextField, 
  Typography
} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import NA from '../utils/NA';

import "../../global.scss";

const AmountTextField = (props) => {
  let currenciesList = JSON.parse(localStorage.getItem("CurrencyList"));

  function convertStringToFormattedAmount(amount){
    let cleanedAmount
    cleanedAmount = amount.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '.')

    // Split the amount into the integer and decimal parts
    let parts = cleanedAmount.split('.');
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? parts[1] : '';

    // Limit the decimal part to two digits
    const selectedCurrency = currenciesList.find((cur) => cur.code === props.currency);
    decimalPart = decimalPart.substring(0, selectedCurrency.decimalPosition || 2);

    // Add commas to the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Combine the integer and decimal parts
    let formattedAmount = parts.length === 2 ? `${integerPart}.${decimalPart}` : integerPart;
    return formattedAmount;
  };

  function getCurrencySymbol () {
    const currency = currenciesList.find((cur) => cur.code === props.currency);
    return currency.symbol || <NA />;
  }

  return (
    <TextField 
      { ...props }
      type='text'
      value={props.value ? (props.value+"").replace(/[^0-9,.]/g, "") : ""}
      onChange={(e) => {
        e.target.value = convertStringToFormattedAmount(e.target.value);
        props.onChange(e);
      }}
      InputProps={{
        startAdornment: 
          <Box
            className="flexCenter_Row"
            gap={"5px"}
            mr={"4px"}>
            {
              props.hideCurrencySelector === true
              ?
              <></>
              :
              <TextField
                select
                size='small'
                variant='standard'
                name={props.currencyName}
                value={props.currency}
                onChange={(e) => props.onCurrencyChange(e)}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "grey", // Change text color
                    padding: "5px",
                    borderRadius: "8px",
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "transparent", // Remove background color
                    borderRadius: "8px",
                  },
                  "& .MuiInputBase-root:hover": {
                    backgroundColor: "#434343", // Remove background color on hover
                  },
                  "& .MuiInputBase-root.Mui-focused": {
                    backgroundColor: "transparent", // Remove background color when focused
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                SelectProps={{
                  IconComponent: (props) => <ArrowDropDownIcon {...props} style={{ color: 'grey' }} />,
                  renderValue: (value) => {
                    const currency = currenciesList.find((cur) => cur.code === value);
                    return currency 
                      ? <Box>
                          <Typography
                            variant='body1'
                            color={"grey"}>
                            {currency.code || <NA />}
                          </Typography>
                        </Box>
                      : ''; 
                  },
                }}>
                {
                  currenciesList.map((currency, currencyIndex) => (
                    <MenuItem
                      key={currencyIndex}
                      value={currency.code}>
                      <span>
                        {currency.name || <NA />}
                      </span>
                      &nbsp;
                      ({currency.code || <NA />})
                    </MenuItem>
                  ))
                }
              </TextField>
            }
            <Typography>
              { getCurrencySymbol() }
            </Typography>
          </Box>
      }}
      />
  )
}

export default AmountTextField;