import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import useSelectedCompany from '../../../../../components/hooks/useSelectedCompany';
import LoadingButton from '../../../../../components/buttons/Button';
import NA from '../../../../../components/utils/NA';

import { 
  GetBankAccountTypesBIZcore 
} from '../../../../../redux/actions/business/core/bankAccountTypesActions';
import { 
  UpdateCompanyBankAccountBIZcore 
} from '../../../../../redux/actions/business/core/companyBankAccountsActions';

import global from "../../../../../global.scss";

const Data = {
  AchRouting: "",
  AccountNumber: "",
  AccountType: "",
  OrumId: ""
};

const CompanyBankAccountsModal = ({
  open, 
  setOpen, 
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.core"]);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const [accountTypesList, setAccountTypesList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  };

  useEffect(() => {
    try {
      if(open){
        let tenant_id = state?.selectedCompany?.tanent_id?.tenant_id;
        dispatch(GetBankAccountTypesBIZcore(tenant_id, 1, 100, "", setLoading));
        if(update.defaultMode === 1){
          // mode = 1 - create company bank account
          setMode(1);
        }else if(update.defaultMode === 2){
          // mode = 2 - edit company bank account
          setMode(2);
          setFormData({
            ...formData,
            AchRouting: update.ach_routing || "",
            AccountNumber: update.account_number || "",
            AccountType: update.account_type_id?.id || "",
            OrumId: update.orum_id || ""
          })
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatAccountTypesList(state?.bankAccountTypesList.records || [])
    } catch (err) {}
  }, [state]);
  
  const formatAccountTypesList = (list) => {
    setAccountTypesList(list);
  };

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if(mode === 1){
      // create company bank account
      
    }else if(mode === 2){
      // update company bank account
      let obj = {
        ach_routing: formData.AchRouting || "",
        account_number: formData.AccountNumber || "",
        account_type_id: formData.AccountType || "",
        orum_id: formData.OrumId || ""
      };
      dispatch(UpdateCompanyBankAccountBIZcore(companyId, update.id, obj, setLoading)).then(() => {
        handleGet(setLoading).then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="ACH routing"
                fullWidth
                name="AchRouting"
                value={formData.AchRouting}
                onChange={handleChange}
                inputProps={{ maxLength: 100 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Account number"
                fullWidth
                name="AccountNumber"
                value={formData.AccountNumber}
                onChange={handleChange}
                inputProps={{ maxLength: 100 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Account type"
                fullWidth
                name="AccountType"
                value={formData.AccountType}
                onChange={handleChange}
                inputProps={{ maxLength: 40 }}>
                {
                  accountTypesList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}>
                      {item.type || <NA />}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Orum Id"
                fullWidth
                name="OrumId"
                value={formData.OrumId}
                onChange={handleChange}
                inputProps={{ maxLength: 40 }}
                />
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.AccountNumber !== "" && formData.AchRouting !== "" && 
                  formData.AccountType !== "")}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default CompanyBankAccountsModal;