import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateServiceBIZsubs, 
  UpdateServiceBIZsubs 
} from '../../../../redux/actions/business/subscriptions/servicesActions';
import { 
  GetDeliveryMethodsBIZsubs 
} from '../../../../redux/actions/business/subscriptions/deliveryMethodsActions';
import { 
  GetSubscriptionPlansBIZsubs 
} from '../../../../redux/actions/business/subscriptions/subscriptionPlansActions';
import { 
  GetProductsBIZsubs 
} from '../../../../redux/actions/business/subscriptions/productsActions';

import global from "../../../../global.scss";

const Data = {
  ServiceCode: "",
  FeeType: "",
  WhoPays: "",
  Description: "",
  DeliveryMethod: "",
  Plan: "",
  Product: ""
};

const FeeTypeList = [
  {
    label: "Per Transaction",
    value: "PER_TRX"
  },
  {
    label: "Per Month",
    value: "PER_MONTH"
  }
];

const WhoPaysList = [
  {
    label: "Payer",
    value: "PAYER"
  },
  {
    label: "Receiver",
    value: "RECEIVER"
  },
  {
    label: "Configurations",
    value: "CONFIGURATIONS"
  }
];

const ServicesModal = ({
  open, 
  setOpen, 
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.subscriptions"]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const [deliveryMethodsList, setDeliveryMethodsList] = useState([]);
  const [subscriptionPlansList, setSubscriptionPlansList] = useState([]);
  const [productsList, setProductsList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  }

  useEffect(() => {
    try {
      dispatch(GetDeliveryMethodsBIZsubs(1, 1000, "", setLoading));
      dispatch(GetSubscriptionPlansBIZsubs(1, 1000, "", setLoading));
      dispatch(GetProductsBIZsubs(1, 1000, "", setLoading));
      if(open){
        if(update.defaultMode === 1){
          // mode = 1 - create service
          setMode(1);
        }else if(update.defaultMode === 2){
          // mode = 2 - edit service
          setMode(2);
          setFormData({
            ...formData,
            ServiceCode: update.service_code || "", 
            FeeType: update.fee_type || "", 
            WhoPays: update.who_pays || "", 
            Description: update.description || "", 
            DeliveryMethod: update.ref_delivery_method_id || "", 
            Plan: update.ref_plan_id || "",
            Product: update.ref_product_id || "",
          })
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatProductsList(state.productsList.records || []);
      setDeliveryMethodsList(state.deliveryMethodsList.records || []);
      formatSubscriptionPlansList(state.subscriptionPlansList.records || []);
    } catch (err) {}
  }, [state]);

  const formatProductsList = (list) => {
    setProductsList(list.filter(i => i.is_active === true) || []);
  };

  const formatSubscriptionPlansList = (list) => {
    setSubscriptionPlansList(list.filter(i => i.is_active === true) || []);
  };

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = () => {
    if(mode === 1){
      // create service
      let obj = {
        service_code: formData.ServiceCode || "", 
        fee_type: formData.FeeType || "", 
        who_pays: formData.WhoPays || "", 
        description: formData.Description || "", 
        ref_delivery_method_id: formData.DeliveryMethod || "", 
        ref_plan_id: formData.Plan || "",
        ref_product_id: formData.Product || "",
      };
      dispatch(CreateServiceBIZsubs(obj, setLoading)).then(() => {
        handleGet().then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // update service
      let obj = {
        service_code: formData.ServiceCode || "", 
        fee_type: formData.FeeType || "", 
        who_pays: formData.WhoPays || "", 
        description: formData.Description || "", 
        ref_delivery_method_id: formData.DeliveryMethod || "", 
        ref_plan_id: formData.Plan || "",
        ref_product_id: formData.Product || "",
      };
      dispatch(UpdateServiceBIZsubs(update.id, obj, setLoading)).then(() => {
        handleGet().then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Service code"
                fullWidth 
                name="ServiceCode"
                value={formData.ServiceCode}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Fee type"
                fullWidth 
                name="FeeType"
                value={formData.FeeType}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  FeeTypeList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                }
              </TextField>
              <TextField 
                select
                label="Who pays"
                fullWidth 
                name="WhoPays"
                value={formData.WhoPays}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  WhoPaysList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Description"
                fullWidth 
                name="Description"
                value={formData.Description}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Product"
                fullWidth
                name="Product"
                value={formData.Product}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  productsList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}>
                      {item.product_name}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Delivery method"
                fullWidth
                name="DeliveryMethod"
                value={formData.DeliveryMethod}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  deliveryMethodsList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}>
                      {item.delivery_method}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Subscription plan"
                fullWidth
                name="Plan"
                value={formData.Plan}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  subscriptionPlansList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}>
                      {item.plan_name}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.ServiceCode !== "" && formData.FeeType !== "" && 
                formData.WhoPays !== "" && formData.Product !== "" &&
                formData.DeliveryMethod !== "" && formData.Plan !== ""
              )}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ServicesModal;