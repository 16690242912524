import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../components/utils/TableTimeStamps';
import Copyable from '../../../../components/utils/Copyable';
import TopBar from '../../../../components/utils/TopBar';
import NA from '../../../../components/utils/NA';

import { 
	GetActivitiesBIZfusion 
} from '../../../../redux/actions/business/fusion/activitiesActions';

const ActivitiesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.fusion"]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");

	const [activitiesList, setActivitiesList] = useState([]);
	const [activitiesTotal, setActivitiesTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Request",
      sort: "req",
    },
    {
      label: "Response",
      sort: "res",
    },
    {
      label: "Is Processed",
      sort: "is_processed",
    }, 
    {
      label: "Track Id",
      sort: "track_id",
    },
    {
      label: "Stage",
      sort: "stage",
    },
    {
      label: "Time stamps",
      sort: "createdAt",
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
		HandleGetActivities();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatActivitiesList(state.activitiesList.records || []);
      setActivitiesTotal(state.activitiesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatActivitiesList = (list) => {
    setActivitiesList(list);
  };

	async function HandleGetActivities(customLoading) {
		let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetActivitiesBIZfusion(page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
	};

  return (
    <Box>

      <TopBar
        title={"Activities"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          activitiesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No activity found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                activitiesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
												<Copyable>
                        	{row.id}
												</Copyable>
                      </Typography>
                    </TableCell>
										<TableCell>
											<Typography
												variant='caption'>
												<Copyable>
													{row.req || <NA />}
												</Copyable>
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												variant='caption'>
												<Copyable>
													{row.res || <NA />}
												</Copyable>
											</Typography>
										</TableCell>
										<TableCell>
											{row.is_processed ? "Yes" : "No" || <NA />}
										</TableCell>
										<TableCell>
											<Copyable>
												{row.track_id || <NA />}
											</Copyable>
										</TableCell>
										<TableCell>
											{row.stage || <NA />}
										</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                   rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={activitiesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default ActivitiesPage;