import React from 'react';

import { 
  Box, 
  Divider, 
  Typography
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';

import "../styles.scss";

const Notifications = ({
  navigate, 
  currentPage
}) => {
  return (
    <Box className="nav__side-common" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/notifications/email-templates");
          }}>
          <AttachEmailOutlinedIcon />
          <Typography variant='body2'>
            Email Templates
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/notifications/email-activities");
          }}>
          <EmailOutlinedIcon />
          <Typography variant='body2'>
            Email Activities
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/notifications/sms-templates");
          }}>
          <RateReviewOutlinedIcon />
          <Typography variant='body2'>
            Sms Templates
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 3 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/notifications/sms-activities");
          }}>
          <MarkUnreadChatAltOutlinedIcon />
          <Typography variant='body2'>
            Sms Activities
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 4 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/notifications/otp-records");
          }}>
          <PasswordOutlinedIcon />
          <Typography variant='body2'>
            OTP Records
          </Typography>
        </Box>
      </Box>
    </Box> 
  )
}

export default Notifications;