import * as actionTypes from "../actionTypes.js";

const initialState = {
  productsList: null, 
  deliveryMethodsList: null, 
  integrationsList: null, 
  subscriptionPlansList: null, 
  servicesList: null, 
  planAddonsList: null, 
  companyPlanAddonsList: null, 
  disallowedServicesList: null
};

const businessSubscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCTS_BIZ_SUBSCRIPTIONS:
      return { ...state, productsList: action.payload };
    case actionTypes.GET_DELIVERY_METHODS_BIZ_SUBSCRIPTIONS:
      return { ...state, deliveryMethodsList: action.payload };
    case actionTypes.GET_INTEGRATIONS_BIZ_SUBSCRIPTIONS:
      return { ...state, integrationsList: action.payload };
    case actionTypes.GET_SUBSCRIPTION_PLAN_BIZ_SUBSCRIPTIONS:
      return { ...state, subscriptionPlansList: action.payload };
    case actionTypes.GET_SERVICES_BIZ_SUBSCRIPTIONS:
      return { ...state, servicesList: action.payload };
    case actionTypes.GET_PLAN_ADDONS_BIZ_SUBSCRIPTIONS:
      return { ...state, planAddonsList: action.payload };
    case actionTypes.GET_COMPANY_PLAN_ADDONS_BIZ_SUBSCRIPTIONS:
      return { ...state, companyPlanAddonsList: action.payload };
    case actionTypes.GET_DISALLOWED_SERVICES_BIZ_SUBSCRIPTIONS:
      return { ...state, disallowedServicesList: action.payload };

    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default businessSubscriptionsReducer;