import React from 'react';

import { 
  Box, 
  Divider, 
  Typography 
} from '@mui/material';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlumbingTwoToneIcon from '@mui/icons-material/PlumbingTwoTone';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import "./styles.scss";

const SupportNavigation = ({
  path, 
  currentPage,
  navigate
}) => {
  return (
    path.length > 2 && 
    path[2] === "config"
    ?
    <Box className="nav__side-common" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/support");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/support/config/categories");
          }}>
          <CategoryOutlinedIcon />
          <Typography variant='body2'>
            Categories
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/support/config/stages");
          }}>
          <ApprovalOutlinedIcon />
          <Typography variant='body2'>
            Stages
          </Typography>
        </Box>
      </Box>
    </Box>    
    :
    <Box className="nav__side-common flex_SBColumn" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/support");
          }}>
          <HomeOutlinedIcon />
          <Typography variant='body2'>
            Home Page - Support
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/support/issues");
          }}>
          <ReportProblemOutlinedIcon />
          <Typography variant='body2'>
            Issues
          </Typography>
        </Box>
      </Box>

      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/support/config/categories");
          }}>
          <PlumbingTwoToneIcon />
          <Typography variant='body2'>
            Configurations
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SupportNavigation