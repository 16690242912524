import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress, 
  Switch, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../components/utils/TableTimeStamps';
import TopBar from '../../../components/utils/TopBar';
import NA from '../../../components/utils/NA';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { 
  GetAllUsersCON, 
  ToggleUserCON 
} from '../../../redux/actions/consumer/usersActions';

import ConfirmationModal from '../../../components/modals/ConfirmationModal';

import "./styles.scss";

const UsersPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.consumer);
  const [loading, setLoading] = useState(true);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  
  const [usersList, setUsersList] = useState([]);
  const [usersTotal, setUsersTotal] = useState(0);
  
  // eslint-disable-next-line no-unused-vars
  const [toggling, setToggling] = useState(false);
  const [toggleId, setToggleId] = useState(null);

  const [
    page, 
    rowsPerPage,
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  useEffect(() => {
    dispatch(GetAllUsersCON(page+1, rowsPerPage, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    try { 
      formatUsersList(state.usersList.Records || []);
      setUsersTotal(state.usersList.Total || 0);
    } catch (err) {}
  }, [state]);

  // --------------------- list formatter ------------------------
  const formatUsersList = (list) => {
    setUsersList(list);
  }


  const handleToggle = () => {
    setConfirmationModalOpen(false);
    dispatch(ToggleUserCON(toggleId, setToggling)).then(() => {
      dispatch(GetAllUsersCON(page+1, rowsPerPage, setToggling));
      setToggleId(null);
    })
  }

  return (
    <Box width={"100%"}>

      <ConfirmationModal 
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        title={modalTitle}
        onConfirm={() => handleToggle()}
        onCancel={() => {setToggleId(null)}}
        />

      <TopBar
        title={"Users"}
        />
    
    {
      loading
      ?
      <Box height={"300px"} className="flexCenterCenterRow">
        <CircularProgress />
      </Box>
      :
        usersList.length === 0
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <Typography>No users found</Typography>
        </Box>
        :
        <Table className="block">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell> Time stamps</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              usersList.map((row, index) => (
                <TableRow 
                  key={index}>
                  <TableCell sx={{ pt: 1, pb: 1}}>
                    <Box>{row.Configurations.FirstName}&nbsp;{row.Configurations.MiddleName}&nbsp;{row.Configurations.LastName}</Box>
                    <i style={{ color: "grey"}}>{row.Id}</i>
                  </TableCell>
                  <TableCell>
                    <Box className="flexCenter_Row">
                      {row.Email || <NA />}&nbsp;
                      <Tooltip title="Email verification status">
                      {
                        row.EmailVerificationStatus === true
                        ? <CheckCircleOutlinedIcon color='success'/>
                        : <CancelOutlinedIcon color='error'/>
                      }
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell>{row.CountryCode}</TableCell>
                  <TableCell>{row.MobileNumber}</TableCell>
                  <TableCell sx={{ pt: 1, pb: 1}}>
                    <TableTimeStamps
                      createdAt={row.createdAt}
                      updatedAt={row.updatedAt}
                      />
                  </TableCell>
                  <TableCell sx={{ pt: 1, pb: 1}}>
                    <Switch 
                      checked={row.AccountStatus}
                      onChange={() => {
                        setModalTitle("Changing Status for - " + row.Id);
                        setToggleId(row.Id);
                        setConfirmationModalOpen(!confirmationModalOpen);
                      }}/>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{ borderBottom: 0 }}
                rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                colSpan={10}
                count={usersTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                />
            </TableRow>
          </TableFooter>
        </Table>
    }
    </Box>
  )
}

export default UsersPage;