import * as actionTypes from "../actionTypes.js";

const initialState = {
  selectedTenant: null,
  selectedCompany: null,

  // global level
  currenciesList: null,
  industriesList: null,
  accountTypesList: null,
  chartOfAccountsList: null,

  tenantsList: null,
  companiesList: null,
  
  // tenants level
  bankAccountTypesList: null,
  banksList: null,
  holidaysList: null, 
  costObjectsList: null,
  tenantBankAccountsList: null,
  
  paymentFrequenciesList: null,
  deliveryMethodsList: null, 
  serviceFeesList: null,
  
  paymentMethodsList: null, 
  categoriesList: null,

  // companies level
  employeesList: null,
  companyBankAccountsList: null,

  discountsList: null,
  billingPaysList: null,
  billingReceivesList: null, 
  billingExpensesList: null
  
};

const businessCoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_TENANT_BIZ_CORE:
      return { ...state, selectedTenant: action.payload };
    case actionTypes.CLEAR_TENANT_BIZ_CORE:
      return { ...state, selectedTenant: null };

    case actionTypes.SELECT_COMPANY_BIZ_CORE:
      return { ...state, selectedCompany: action.payload };
    case actionTypes.CLEAR_COMPANY_BIZ_CORE:
      return { ...state, selectedCompany: null };

    case actionTypes.GET_CURRENCIES_BIZ_CORE:
      return { ...state, currenciesList: action.payload };
    case actionTypes.GET_INDUSTRIES_BIZ_CORE:
      return { ...state, industriesList: action.payload };
    case actionTypes.GET_ACCOUNT_TYPES_BIZ_CORE:
      return { ...state, accountTypesList: action.payload };
    case actionTypes.GET_CHART_OF_ACCOUNTS_BIZ_CORE:
      return { ...state, chartOfAccountsList: action.payload };

    case actionTypes.GET_TENANTS_BIZ_CORE:
      return { ...state, tenantsList: action.payload };
    case actionTypes.GET_COMPANIES_BIZ_CORE:
      return { ...state, companiesList: action.payload };

    case actionTypes.GET_BANK_ACCOUNT_TYPES_BIZ_CORE:
      return { ...state, bankAccountTypesList: action.payload };
    case actionTypes.GET_BANKS_BIZ_CORE:
      return { ...state, banksList: action.payload };
    case actionTypes.GET_HOLIDAYS_BIZ_CORE:
      return { ...state, holidaysList: action.payload };
    case actionTypes.GET_COST_OBJECTS_BIZ_CORE:
      return { ...state, costObjectsList: action.payload };
    case actionTypes.GET_TENANT_BANK_ACCOUNT_BIZ_CORE:
      return { ...state, tenantBankAccountsList: action.payload };

    case actionTypes.GET_PAYMENT_FREQUENCIES_BIZ_CORE:
      return { ...state, paymentFrequenciesList: action.payload };
    case actionTypes.GET_DELIVERY_METHODS_BIZ_CORE:
      return { ...state, deliveryMethodsList: action.payload };
    case actionTypes.GET_SERVICE_FEES_BIZ_CORE:
      return { ...state, serviceFeesList: action.payload };

    case actionTypes.GET_PAYMENT_METHODS_BIZ_CORE:
      return { ...state, paymentMethodsList: action.payload };
    case actionTypes.GET_CATEGORIES_BIZ_CORE:
      return { ...state, categoriesList: action.payload };

    case actionTypes.GET_EMPLOYEES_BIZ_CORE:
      return { ...state, employeesList: action.payload };
    case actionTypes.GET_COMPANY_BANK_ACCOUNT_BIZ_CORE:
      return { ...state, companyBankAccountsList: action.payload };

    case actionTypes.GET_DISCOUNTS_BIZ_CORE:
      return { ...state, discountsList: action.payload };
    case actionTypes.GET_BILLING_PAYS_BIZ_CORE:
      return { ...state, billingPaysList: action.payload };
    case actionTypes.GET_BILLING_RECEIVES_BIZ_CORE:
      return { ...state, billingReceivesList: action.payload };
    case actionTypes.GET_BILLING_EXPENSES_BIZ_CORE:
      return { ...state, billingExpensesList: action.payload };


    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default businessCoreReducer;