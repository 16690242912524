import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../components/utils/TopBar';
import NA from '../../../../components/utils/NA';

import { 
  GetBranchesBIZidentity 
} from '../../../../redux/actions/business/identity/branchesActions';

const BranchesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.identity"]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const [branchesList, setBranchesList] = useState([]);
  const [branchesTotal, setBranchesTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Display name | Legal name",
      sort: "legal_name",
    },
    {
      label: "Company",
      sort: "company_id",
    },
    {
      label: "Address",
      sort: "address",
    }, 
    {
      label: "City",
      sort: "city",
    },
    {
      label: "State",
      sort: "state",
    },
    {
      label: "Country",
      sort: "country",
    },
    {
      label: "Super admin",
      sort: "admin_user_id",
    },
    {
      label: "Tenant",
      sort: "tenant_id",
    },
    {
      label: "Time stamps",
      sort: "createdAt",
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetBranchesBIZidentity(page+1, rowsPerPage, query, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatBranchesList(state.branchesList.records || []);
      setBranchesTotal(state.branchesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatBranchesList = (list) => {
    setBranchesList(list)
  };

  return (
    <Box>

      <TopBar
        title={"Branches"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          branchesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No branches found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                branchesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.id}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {row.legal_name || ""}
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{row?.company?.legal_name || <NA />}</Typography>
                      </Box>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row?.company?.id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {row.address || <NA />}
                    </TableCell>
                    <TableCell>
                      {row.city || <NA />}
                    </TableCell>
                    <TableCell>
                      {row.state || <NA />}
                    </TableCell>
                    <TableCell>
                      {row.country || <NA />}{" - "}{row.pincode || <NA />}
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{row?.user?.first_name || <NA />}&nbsp;{row.user?.last_name}</Typography>
                        <i><Typography>{row?.user.user_name || <NA />}</Typography></i>
                      </Box>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.admin_user_id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{row?.tenant?.legal_name || <NA />}</Typography>
                        <i><Typography>{row?.tenant.name || <NA />}</Typography></i>
                      </Box>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.tenant_id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                   rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={branchesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default BranchesPage;