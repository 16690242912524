import * as actionTypes from "../actionTypes.js";

const initialState = {
  emailTemplatesList: null,
  emailTemplateDetails: null,

  emailActivitiesList: null, 

  smsTemplatesList: null,
  smsTemplateDetails: null,

  smsActivitiesList: null,

  otpRecordsList: null
};

const businessNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EMAIL_TEMPLATES_BIZ_NOTIFICATIONS:
      return { ...state, emailTemplatesList: action.payload };
    case actionTypes.GET_EMAIL_TEMPLATE_BIZ_NOTIFICATIONS:
      return { ...state, emailTemplateDetails: action.payload };

    case actionTypes.GET_EMAIL_ACTIVITIES_BIZ_NOTIFICATIONS:
      return { ...state, emailActivitiesList: action.payload };

    case actionTypes.GET_SMS_TEMPLATES_BIZ_NOTIFICATIONS:
      return { ...state, smsTemplatesList: action.payload };
    case actionTypes.GET_SMS_TEMPLATE_BIZ_NOTIFICATIONS:
      return { ...state, smsTemplateDetails: action.payload };

    case actionTypes.GET_SMS_ACTIVITIES_BIZ_NOTIFICATIONS:
      return { ...state, smsActivitiesList: action.payload };

    case actionTypes.GET_OTP_RECORDS_BIZ_NOTIFICATIONS:
      return { ...state, otpRecordsList: action.payload };


    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default businessNotificationsReducer;