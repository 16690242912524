import React from 'react';

import { 
  Box,
  Typography
} from '@mui/material';

import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// import "../styles.scss";

const FusionCurrencyCloud = ({
  navigate,
  currentPage
}) => {
  return (
    <Box className="nav__side-common" 
      display={"flex"} flexDirectsion={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/currency-cloud");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
				<Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/currency-cloud/beneficiaries");
          }}>
          <PeopleAltOutlinedIcon />
          <Typography variant='body2'>
            Beneficiaries
          </Typography>
        </Box>
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/currency-cloud/conversions");
          }}>
          <CurrencyExchangeOutlinedIcon />
          <Typography variant='body2'>
            Conversions
          </Typography>
        </Box>
        <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/currency-cloud/rates");
          }}>
          <TrendingUpOutlinedIcon />
          <Typography variant='body2'>
            Rates
          </Typography>
        </Box>
        <Box className={`${currentPage === 3 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/currency-cloud/payments");
          }}>
          <PaymentsOutlinedIcon />
          <Typography variant='body2'>
            Payments
          </Typography>
        </Box>
        <Box className={`${currentPage === 4 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/currency-cloud/transfers");
          }}>
          <ImportExportOutlinedIcon />
          <Typography variant='body2'>
            Transfers
          </Typography>
        </Box>
      </Box>
    </Box>  
  )
}

export default FusionCurrencyCloud;



