import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  TextField, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import Copyable from '../../../../../components/utils/Copyable';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { 
  GetChartOfAccountsBIZcore 
} from '../../../../../redux/actions/business/core/chartOfAccountsActions';
import { 
  GetIndustriesBIZcore 
} from '../../../../../redux/actions/business/core/industriesActions';
import { 
  GetAccountTypesBIZcore 
} from '../../../../../redux/actions/business/core/accountTypesActions';

import ChartOfAccountsModal from './ChartOfAccountsModal';

import global from "../../../../../global.scss";

const ChartOfAccountsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.core"]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loading2, setLoading2] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [chartOfAccountsModalOpen, setChartOfAccountsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [chartOfAccountsList, setChartOfAccountsList] = useState([]);
  const [chartOfAccountsTotal, setChartOfAccountsTotal] = useState(0);

  const [chartOfAccountsMenuOpen, setChartOfAccountsMenuOpen] = useState(false);
  const [chartOfAccountsMenuAnchor, setChartOfAccountsMenuAnchor] = useState(null);

  const [industriesList, setIndustriesList] = useState([]);
  const [selectedIndusty, setSelectedIndustry] = useState(null);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Account type",
      sort: "ref_account_type_id",
    },
    {
      label: "Account name",
      sort: "account_name",
    },
    {
      label: "Description",
      sort: "account_description",
    },
    {
      label: "Account number",
      sort: "account_number",
    },
    {
      label: "Time stamps",
      sort: "created_at",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    dispatch(GetIndustriesBIZcore(1, 100, "", setLoading2));
    dispatch(GetAccountTypesBIZcore(1, 1000, "&ordering=account_category", setLoading2));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(selectedIndusty) handleGetChartOfAccounts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection, selectedIndusty]);

  useEffect(() => {
    try {
      setIndustriesList(state.industriesList.records || []);
      if(!selectedIndusty && state.industriesList.records.length > 0){
        setSelectedIndustry(state.industriesList.records[0]);
      }
      formatChartOfAccountsList(state.chartOfAccountsList.records || []);
      setChartOfAccountsTotal(state.chartOfAccountsList.total || 0);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const formatChartOfAccountsList = (list) => {
    setChartOfAccountsList(list)
  };

  async function handleGetChartOfAccounts(customLoading){
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    dispatch(GetChartOfAccountsBIZcore(selectedIndusty.id, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  return (
    <Box>

      <ChartOfAccountsModal 
        open={chartOfAccountsModalOpen}
        setOpen={setChartOfAccountsModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetChartOfAccounts}
        selectedIndusty={selectedIndusty}
        />

      <TopBar
        title={"Chart of accounts"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add account"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add account");
          setChartOfAccountsModalOpen(!chartOfAccountsModalOpen);
        }}
        />

      <Box
        className="flexCenter_Row"
        gap={"20px"}
        mb={"15px"}
        sx={{ width: "350px" }}>
        <Typography>
          Industry
        </Typography>
        <TextField
          select
          label="Industry"
          fullWidth
          name="IndustryId"
          value={selectedIndusty || ""}
          onChange={(e) => {
            setSelectedIndustry(e.target.value);
          }}
          inputProps={{ maxLength: 32 }}
          >
          {
            industriesList.map((item, index) => (
              <MenuItem
                key={index}
                value={item}>
                {item.industry || <NA />}
              </MenuItem>
            ))
          }
        </TextField>
      </Box>

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          chartOfAccountsList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No account found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                chartOfAccountsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        <Copyable>
                          {row.id || <NA />}
                        </Copyable>
                      </Typography>
                    </TableCell>
                    <TableCell>{row.ref_account_type_id?.account_category || <NA />}{" > "}{row.ref_account_type_id?.account_type || <NA />}</TableCell>
                    <TableCell>{row.account_name || <NA />}</TableCell>
                    <TableCell>{row.account_description || <NA />}</TableCell>
                    <TableCell>{row.account_number || <NA />}</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.created_at}
                        updatedAt={row.updated_at}
                        />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          setChartOfAccountsMenuAnchor(e.currentTarget);
                          setChartOfAccountsMenuOpen(true);
                          setUpdate(row);
                          setModalTitle(row.name || "");
                        }}>
                        <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={chartOfAccountsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={chartOfAccountsMenuOpen}
        anchorEl={chartOfAccountsMenuAnchor}
        onClose={() => setChartOfAccountsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setChartOfAccountsMenuOpen(false);
            setChartOfAccountsModalOpen(!chartOfAccountsModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default ChartOfAccountsPage;