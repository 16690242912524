import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box, 
  Container,
  Typography
} from '@mui/material';

import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

import "../../../../global.scss";
import "./styles.scss";

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <Container 
      maxWidth="sm">
      <Box
        className="flexCenterCenterColumn"
        gap={"20px"}
        height={"calc(100vh - 150px)"}>
        <Box
          className="core__block block flexCenterCenterColumn"
          gap={"15px"}
          onClick={() => navigate("global/currencies")}>
          <PublicOutlinedIcon 
            sx={{ width: "60px", height: "60px" }}
            />
          <Typography
            variant='h6'>
            Global Level
          </Typography>
        </Box>
        <ArrowForwardIosRoundedIcon 
          fontSize='large'
          sx={{ transform: "rotate(90deg)", color: "grey" }}
          />
        <Box
          className="block core__block flexCenterCenterColumn"
          gap={"15px"}
          onClick={() => navigate("tenants")}>
          <BusinessOutlinedIcon 
            sx={{ width: "60px", height: "60px" }}
            />
          <Typography
            variant='h6'>
            Tenant Level
          </Typography>
        </Box>
        <ArrowForwardIosRoundedIcon 
          fontSize='large'
          sx={{ transform: "rotate(90deg)", color: "grey" }}
          />
        <Box
          className="block core__block flexCenterCenterColumn"
          gap={"15px"}
          onClick={() => navigate("companies")}>
          <StoreOutlinedIcon 
            sx={{ width: "60px", height: "60px" }}
            />
          <Typography
            variant='h6'>
            Company Level
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default HomePage