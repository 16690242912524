import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../../../components/buttons/Button';

import { 
  UpdateCompanyBIZidentity 
} from '../../../../redux/actions/business/identity/companiesActions';
import {  
  GetSubscriptionPlansBIZsubs, 
} from '../../../../redux/actions/business/subscriptions/subscriptionPlansActions';

import global from "../../../../global.scss";

const Data = {
  LegalName: "",
  TradingName: "",
  AdminUser: "",
  LogoUrl: "",
  Plan: "",
  PlanType: ""
};

const PlanTypesList = [
  {
    label: "Monthly",
    value: "Monthly"
  },
  {
    label: "Yearly",
    value: "Yearly"
  },
];

const CompaniesModal = ({
  open, 
  setOpen, 
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.subscriptions"]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const [subscriptionPlansList, setSubscriptionPlansList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  }

  useEffect(() => {
    try {
      dispatch(GetSubscriptionPlansBIZsubs(1, 1000, "", setLoading));
      if(open){
        if(update.defaultMode === 2){
          // mode = 2 - edit company
          setMode(2);
          setFormData({
            ...formData,
            LegalName: update.legal_name || "",
            TradingName: update.trading_name || "",
            AdminUser: update.admin_user_id || "",
            LogoUrl: update.logo_url || "",
            Plan: update.ref_plan_id || "",
            PlanType: update.plan_type || "",
          })
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatSubscriptionPlansList(state.subscriptionPlansList.records || []);
    } catch (err) {}
  }, [state]);

  const formatSubscriptionPlansList = (list) => {
    setSubscriptionPlansList(list.filter(i => i.is_active === true) || []);
  };

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = () => {
    if(mode === 2){
      // update company
      let obj = {
        legal_name: formData.LegalName || "",
        trading_name: formData.TradingName || "",
        admin_user_id: formData.AdminUser || "",
        logo_url: formData.LogoUrl || "",
        ref_plan_id: formData.Plan || "",
        plan_type: formData.PlanType || "",
      };
      dispatch(UpdateCompanyBIZidentity(update.id, obj, setLoading)).then(() => {
        handleGet().then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Legal name"
                fullWidth
                name="LegalName"
                value={formData.LegalName}
                onChange={handleChange}
                inputProps={{ maxLength: 128 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Trading name"
                fullWidth
                name="TradingName"
                value={formData.TradingName}
                onChange={handleChange}
                inputProps={{ maxLength: 128 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Admin user id"
                fullWidth
                name="AdminUser"
                value={formData.AdminUser}
                onChange={handleChange}
                inputProps={{ maxLength: 40 }}
                />
              <TextField 
                label="Logo url"
                fullWidth
                name="LogoUrl"
                value={formData.LogoUrl}
                onChange={handleChange}
                inputProps={{ maxLength: 256 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Subscription plan"
                fullWidth
                name="Plan"
                value={formData.Plan}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  subscriptionPlansList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}>
                      {item.plan_name}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
               <TextField 
                select
                label="Plan type"
                fullWidth
                name="PlanType"
                value={formData.PlanType}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  PlanTypesList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.LegalName !== "" && formData.TradingName !== "" &&
                formData.AdminUser !== ""
              )}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default CompaniesModal;