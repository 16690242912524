import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Switch,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import useSelectedTenant from '../../../../../components/hooks/useSelectedTenant';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { 
  GetBankAccountTypesBIZcore, 
  UpdateBankAccountTypeBIZcore
} from '../../../../../redux/actions/business/core/bankAccountTypesActions';

import BankAccountTypesModal from './BankAccountTypesModal';

import global from "../../../../../global.scss";

const BankAccountTypesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.core"]);
  const tenantId = useSelectedTenant();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [bankAccountTypesModalOpen, setBankAccountTypesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [bankAccountTypesList, setBankAccountTypesList] = useState([]);
  const [bankAccountTypesTotal, setBankAccountTypesTotal] = useState(0);

  const [bankAccountTypesMenuOpen, setBankAccountTypesMenuOpen] = useState(false);
  const [bankAccountTypesMenuAnchor, setBankAccountTypesMenuAnchor] = useState(null);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Type",
      sort: "type",
    },
    {
      label: "Active",
      sort: "is_active",
    },
    {
      label: "Time stamps",
      sort: "created_at",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    dispatch(GetBankAccountTypesBIZcore(tenantId, page+1, rowsPerPage, query, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatBankAccountTypesList(state.bankAccountTypesList.records || []);
      setBankAccountTypesTotal(state.bankAccountTypesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatBankAccountTypesList = (list) => {
    setBankAccountTypesList(list)
  };

  const handleSwitchBankAccountType = (id, type, bool) => {
    let obj = {
      is_active: bool,
      type: type
    }
    dispatch(UpdateBankAccountTypeBIZcore(tenantId, id, obj, setLoading)).then(() => {
      dispatch(GetBankAccountTypesBIZcore(tenantId, page+1, rowsPerPage, "", setLoading));
    })
  };

  return (
    <Box>

      <BankAccountTypesModal 
        open={bankAccountTypesModalOpen}
        setOpen={setBankAccountTypesModalOpen}
        title={modalTitle}
        update={update}
        page={page}
        rowsPerPage={rowsPerPage}
        />

      <TopBar
        title={"Bank account types"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add Bank account type"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add Bank account type");
          setBankAccountTypesModalOpen(!bankAccountTypesModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          bankAccountTypesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No bank account type found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                bankAccountTypesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.type || <NA />}</TableCell>
                    <TableCell>
                      <Switch 
                        checked={row.is_active}
                        onChange={(e) => {
                          handleSwitchBankAccountType(row.id, row.type, e.target.checked)
                        }}
                        />
                    </TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.created_at}
                        updatedAt={row.updated_at}
                        />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          setBankAccountTypesMenuAnchor(e.currentTarget);
                          setBankAccountTypesMenuOpen(true);
                          setUpdate(row);
                          setModalTitle(row.type || "");
                        }}>
                        <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={bankAccountTypesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={bankAccountTypesMenuOpen}
        anchorEl={bankAccountTypesMenuAnchor}
        onClose={() => setBankAccountTypesMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setBankAccountTypesMenuOpen(false);
            setBankAccountTypesModalOpen(!bankAccountTypesModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default BankAccountTypesPage;