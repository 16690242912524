import React from 'react';

import { 
  Box,
} from '@mui/material';

import TopBar from '../../../../components/utils/TopBar';

const SDKPage = () => {
  return (
    <Box>
      <TopBar 
        title={"SDK"}
        />
        
    </Box>
  )
}

export default SDKPage