import * as actionTypes from "../actionTypes.js";

const initialState = {
  selectedAccount: null,

  accountsList: null,
  contactsList: null,
  beneficiariesList: null,
  conversionsList: null,
  basicRatesObj: null, 
  detailedRatesObj: null, 
  paymentsList: null, 
  transfersList: null,

};

const businessFusionCCReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_ACCOUNT_BIZ_FUSION_CC:
      return { ...state, selectedAccount: action.payload };
    case actionTypes.CLEAR_ACCOUNT_BIZ_FUSION_CC:
      return { ...state, selectedAccount: null };

    case actionTypes.GET_ACCOUNTS_BIZ_FUSION_CC:
      return { ...state, accountsList: action.payload };
    case actionTypes.GET_CONTACTS_BIZ_FUSION_CC:
      return { ...state, contactsList: action.payload };
    case actionTypes.GET_BENEFICIARIES_BIZ_FUSION_CC:
      return { ...state, beneficiariesList: action.payload };
    case actionTypes.GET_CONVERSIONS_BIZ_FUSION_CC:
      return { ...state, conversionsList: action.payload };
    case actionTypes.GET_BASIC_RATES_BIZ_FUSION_CC:
      return { ...state, basicRatesObj: action.payload };
    case actionTypes.GET_DETAILED_RATES_BIZ_FUSION_CC:
      return { ...state, detailedRatesObj: action.payload };
    case actionTypes.GET_PAYMENTS_BIZ_FUSION_CC:
      return { ...state, paymentsList: action.payload };
    case actionTypes.GET_TRANSFERS_BIZ_FUSION_CC:
      return { ...state, transfersList: action.payload }; 


    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default businessFusionCCReducer;