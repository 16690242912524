import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../../../../components/buttons/Button';
import NA from '../../../../../components/utils/NA';

import { 
  CreateChartOfAccountsBIZcore, 
  UpdateChartOfAccountsBIZcore 
} from '../../../../../redux/actions/business/core/chartOfAccountsActions';

import global from "../../../../../global.scss";

const Data = {
  AccountTypeId: "",
  IndustryId: "",
  Name: "",
  Description: "",
  Number: "",
};

const ChartOfAccountsModal = ({
  open, 
  setOpen, 
  title,
  update,
  handleGet,
  selectedIndusty
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.core"]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const [industriesList, setIndustriesList] = useState([]);
  const [accountTypesList, setAccountsTypesList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  };

  useEffect(() => {
    try {
      if(open){
        if(update.defaultMode === 1){
          // mode = 1 - create account
          setMode(1);
          setFormData({
            ...formData,
            IndustryId: selectedIndusty.id
          });
        }else if(update.defaultMode === 2){
          // mode = 2 - edit account
          setMode(2);
          setFormData({
            ...formData,
            AccountTypeId: update.ref_account_type_id.id || "",
            IndustryId: update.ref_industry_id || "",
            Name: update.account_name || "",
            Description: update.account_description || "",
            Number: update.account_number || "",
          });
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      setIndustriesList(state.industriesList.records || []);
      setAccountsTypesList(state.accountTypesList.records || []);
    } catch (err) {}
  }, [state]);
  
  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if(mode === 1){
      // create account
      let obj = {
        ref_account_type_id: formData.AccountTypeId || "",
        ref_industry_id: formData.IndustryId || "",
        account_name: formData.Name || "",
        account_description: formData.Description || "",
        account_number: formData.Number || "",
      };
      dispatch(CreateChartOfAccountsBIZcore(obj, setLoading)).then(() => {
        handleGet(setLoading).then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // update account
      let obj = {
        ref_account_type_id: formData.AccountTypeId || "",
        ref_industry_id: formData.IndustryId || "",
        account_name: formData.Name || "",
        account_description: formData.Description || "",
        account_number: formData.Number || "",
      };
      dispatch(UpdateChartOfAccountsBIZcore(update.id, obj, setLoading)).then(() => {
        handleGet(setLoading).then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' 
              sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Industry"
                fullWidth
                name="IndustryId"
                value={formData.IndustryId}
                onChange={handleChange}
                inputProps={{ maxLength: 32 }}
                >
                {
                  industriesList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}>
                      {item.industry || <NA />}
                    </MenuItem>
                  ))
                }
              </TextField>
              <TextField 
                select
                label="Account type"
                fullWidth
                name="AccountTypeId"
                value={formData.AccountTypeId}
                onChange={handleChange}
                inputProps={{ maxLength: 64 }}
                >
                {
                  accountTypesList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}>
                      {item.account_category || <NA />}{" > "}{item.account_type || <NA />}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Account name"
                fullWidth
                name="Name"
                value={formData.Name}
                onChange={handleChange}
                inputProps={{ maxLength: 128 }}
                />
              <TextField 
                label="Account number"
                fullWidth
                name="Number"
                value={formData.Number}
                onChange={handleChange}
                inputProps={{ maxLength: 128 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Account description"
                fullWidth
                name="Description"
                value={formData.Description}
                onChange={handleChange}
                inputProps={{ maxLength: 256 }}
                />
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.AccountTypeId !== "" && formData.IndustryId !== "" &&
                formData.Name !== ""
              )}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ChartOfAccountsModal;