import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
  Box,
  Button,
  Container, 
  TextField,  
  Typography
} from '@mui/material';

import Logo from "../../assets/icons/logo-white.svg";

import { 
  LogIn 
} from '../../redux/actions/authActions';

import "./styles.scss";

const Data = {
  Email: "",
  Password: ""
}

const AuthPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);

  useEffect(() => {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleLogin = () => {
    console.log(formData);
    dispatch(LogIn(formData, navigate, setLoading))
  }

  return (
    <Box>
      <Box className='auth__dackdrop'></Box>
      <Box className='auth__top'>
        <Container maxWidth="lg">
          <Box className='flexCenterSBRow'>
            <img
              src={Logo}
              alt="lusid-logo"
              style={{ width: "140px" }}
              />
            <Typography variant='h5' sx={{ color: "white" }}>
              {"Internal"}
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box className="auth flexCenterCenterRow">
          <Box className="auth__content flexCenterCenterRow" 
            p={"25px"}>
            <Box className="flex_CenterColumn" gap={"20px"}>
              <TextField 
                variant="outlined"
                label="Email"
                name="Email"
                placeholder='Enter your email'
                value={formData.Email}
                onChange={handleChange}
                />
              <TextField 
                variant="outlined"
                label="Password"
                name="Password"
                type="password"
                placeholder='Enter password'
                value={formData.Password}
                onChange={handleChange}
                />
              <Button
                variant='contained'
                onClick={handleLogin}>
                Login
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default AuthPage