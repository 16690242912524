import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../../../components/buttons/Button';
import NA from '../../../../components/utils/NA';

import { countryList } from '../../../../components/utils/countryCodeList';

import { 
  CreateTenantBIZidentity, 
  GetTenantsBIZidentity, 
  UpdateTenantBIZidentity 
} from '../../../../redux/actions/business/identity/tenantsActions';

import global from "../../../../global.scss";

const Data = {
  Name: "",
  About: "",
  LegalName: "",
  Address: "",
  City: "",
  State: "",
  Country: "",
  Pincode: "",
  TaxId: "",
  LogoUrl: ""
};

const TenantsModal = ({
  open, 
  setOpen, 
  title,
  update,
  page, 
  rowsPerPage
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  }

  useEffect(() => {
    try {
      if(open){
        if(update.defaultMode === 1){
          // mode = 1 - create tenant
          setMode(1);
        }else if(update.defaultMode === 2){
          // mode = 2 - edit tenant
          setMode(2);
          setFormData({
            ...formData,
            Name: update.name || "",
            About: update.about || "",
            LegalName: update.legal_name || "",
            Address: update.address || "",
            City: update.city || "",
            State: update.state || "",
            Country: update.country || "",
            Pincode: update.pincode || "",
            TaxId: update.tax_id || "",
            LogoUrl: update.logo_url || "",
          })
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = () => {
    if(mode === 1){
      // create tenant
      let obj = {
        name: formData.Name,
        about: formData.About,
        legal_name: formData.LegalName,
        address: formData.Address,
        city: formData.City,
        state: formData.State,
        country: formData.Country,
        pincode: formData.Pincode,
        tax_id: formData.TaxId,
        logo_url: formData.LogoUrl,
      };
      dispatch(CreateTenantBIZidentity(obj, setLoading)).then(() => {
        dispatch(GetTenantsBIZidentity(page+1, rowsPerPage, "", setLoading)).then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // update tenant
      let obj = {
        name: formData.Name,
        about: formData.About,
        legal_name: formData.LegalName,
        address: formData.Address,
        city: formData.City,
        state: formData.State,
        country: formData.Country,
        pincode: formData.Pincode,
        tax_id: formData.TaxId,
        logo_url: formData.LogoUrl,
      };
      dispatch(UpdateTenantBIZidentity(update.id, obj, setLoading)).then(() => {
        dispatch(GetTenantsBIZidentity(page+1, rowsPerPage, "", setLoading)).then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Name"
                fullWidth
                name="Name"
                value={formData.Name}
                onChange={handleChange}
                inputProps={{ maxLength: 128 }}
                />
              <TextField 
                label="Legal name"
                fullWidth
                name="LegalName"
                value={formData.LegalName}
                onChange={handleChange}
                inputProps={{ maxLength: 128 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="About"
                fullWidth
                name="About"
                value={formData.About}
                onChange={handleChange}
                inputProps={{ maxLength: 256 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Address"
                fullWidth
                name="Address"
                value={formData.Address}
                onChange={handleChange}
                inputProps={{ maxLength: 256 }}
                />
              <TextField 
                label="City"
                fullWidth
                name="City"
                value={formData.City}
                onChange={handleChange}
                inputProps={{ maxLength: 128 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="State"
                fullWidth
                name="State"
                value={formData.State}
                onChange={handleChange}
                inputProps={{ maxLength: 256 }}
                />
              <TextField 
                select
                label="Country"
                fullWidth
                name="Country"
                value={formData.Country}
                onChange={handleChange}
                >
                {
                  countryList.map((item, index) => (
                    <MenuItem
                      index={index}
                      value={item.code}>
                      {item.name || <NA />}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Pincode"
                fullWidth
                name="Pincode"
                value={formData.Pincode}
                onChange={handleChange}
                inputProps={{ maxLength: 32 }}
                />
              <TextField 
                label="Tax Id"
                fullWidth
                name="TaxId"
                value={formData.TaxId}
                onChange={handleChange}
                inputProps={{ maxLength: 32 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Logo url"
                fullWidth
                name="LogoUrl"
                value={formData.LogoUrl}
                onChange={handleChange}
                inputProps={{ maxLength: 256 }}
                />
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.Name !== "" && formData.LegalName !== "" && formData.Address !== "")}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default TenantsModal;