import axios from "axios";

const API = axios.create({
  baseURL: `https://alpha-api.lusid.ai/us/`,
});

const LocalIdenityAPI = axios.create({
  // baseURL: `http://localhost:8001/us/`,
  baseURL: `https://alpha-api.lusid.ai/us/`,
});

const LocalCoreAPI = axios.create({
  // baseURL: `http://127.0.0.1:8000/`,
  baseURL: `https://alpha-api.lusid.ai/us/`,
});

const LocalFusionAPI = axios.create({
  // baseURL: `http://localhost:3500/`,
  baseURL: `https://alpha-api.lusid.ai/us/`,
});

const LocalWebhookAPI = axios.create({
  // baseURL: `http://localhost:3501/`,
  baseURL: `https://alpha-webhook.lusid.ai/us/`,
});

API.interceptors.request.use((req) => {
  if (sessionStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(sessionStorage.getItem("user"))?.accessToken}`;
  }
  return req;
});

API.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});

// Local Idenity API
LocalIdenityAPI.interceptors.request.use((req) => {
  if (sessionStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(sessionStorage.getItem("user"))?.accessToken}`;
  }
  return req;
});

LocalIdenityAPI.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});

// Local Core API
LocalCoreAPI.interceptors.request.use((req) => {
  if (sessionStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(sessionStorage.getItem("user"))?.accessToken}`;
  }
  return req;
});

LocalCoreAPI.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});

// Local Fusion API
LocalFusionAPI.interceptors.request.use((req) => {
  if (sessionStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(sessionStorage.getItem("user"))?.accessToken}`;
  }
  return req;
});

LocalFusionAPI.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});

// Local Webhook API
LocalWebhookAPI.interceptors.request.use((req) => {
  if (sessionStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(sessionStorage.getItem("user"))?.accessToken}`;
  }
  return req;
});

LocalWebhookAPI.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});



export const login = (form) => API.post("b2c/identity/internal/v1/internal-user/login", form);

// ========================== WatchTower ==========================
export const getContactRequestsWT = (page, limit) => API.get(`b2c/notification/v1/contact-requests?page=${page}&limit=${limit}`);
export const updateContactRequestsWT = (id) => API.patch(`b2c/notification/v1/contact-requests/${id}`);


// ========================== Consumer ============================
export const getAllUsersCON = (page, limit) => API.get(`b2c/identity/internal/v1/user?page=${page}&limit=${limit}`);
export const toggleUserCON = (id) => API.patch(`b2c/identity/internal/v1/user/${id}`);

export const getInternalUsersCON  = (page, limit) => API.get(`b2c/identity/internal/v1/internal-user?page=${page}&limit=${limit}`);

// SUBMODULE - Payments
export const getCurrenciesCONpayments = (page, limit) => API.get(`b2c/payments/internal/v1/currency?page=${page}&limit=${limit}`);
export const createCurrencyCONpayments = (form) => API.post(`b2c/payments/internal/v1/currency`, form);
export const updateCurrencyCONpayments = (id, form) => API.put(`b2c/payments/internal/v1/currency/${id}`, form);
export const deleteCurrencyCONpayments = (id) => API.delete(`b2c/payments/internal/v1/currency/${id}`);

export const getUsersCONpayments = (page, limit) => API.get(`b2c/payments/internal/v1/users?page=${page}&limit=${limit}`);
export const createUserCONpayments = (form) => API.post(`b2c/payments/internal/v1/users`, form);
export const updateUserCONpayments = (id, form) => API.put(`b2c/payments/internal/v1/users/${id}`, form);

// SUBMODULE - VirtualCards
export const getCurrenciesCONvcn = (page, limit) => API.get(`b2c/payments/internal/v1/currency/vcn?page=${page}&limit=${limit}`);
export const createCurrencyCONvcn = (form) => API.post(`b2c/payments/internal/v1/currency/vcn`, form);
export const updateCurrencyCONvcn = (id, form) => API.put(`b2c/payments/internal/v1/currency/vcn/${id}`, form);
export const deleteCurrencyCONvcn = (id) => API.delete(`b2c/payments/internal/v1/currency/vcn/${id}`);


// ========================== Business ============================
export const getUploadedFilesBIZ = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/upload-files?page=${page}&limit=${limit}${query}`);

export const getCurrenciesBIZ = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/currencies?page=${page}&limit=${limit}${query}`);
export const createCurrencyBIZ = (form) => LocalIdenityAPI.post(`identity/internal/v1/currencies`, form);
export const updateCurrencyBIZ = (id, form) => LocalIdenityAPI.put(`identity/internal/v1/currencies/${id}`, form);
export const deleteCurrencyBIZ = (id) => LocalIdenityAPI.delete(`identity/internal/v1/currencies/${id}`,);

// SUBMODULE - Notifications
export const getEmailTemplatesBIZnotifications = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/email-templates?page=${page}&limit=${limit}${query}`);
export const getEmailTemplateBIZnotifications = (id) => LocalIdenityAPI.get(`identity/internal/v1/email-templates/${id}`);
export const createEmailTemplateBIZnotifications = (form) => LocalIdenityAPI.post(`identity/internal/v1/email-templates`, form);
export const updateEmailTemplateBIZnotifications = (id, form) => LocalIdenityAPI.put(`identity/internal/v1/email-templates/${id}`, form);
export const deleteEmailTemplateBIZnotifications = (id) => LocalIdenityAPI.delete(`identity/internal/v1/email-templates/${id}`);

export const getEmailActivitiesBIZnotifications = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/email?page=${page}&limit=${limit}${query}`);

export const getSmsTemplatesBIZnotifications = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/sms-templates?page=${page}&limit=${limit}${query}`);
export const getSmsTemplateBIZnotifications = (id) => LocalIdenityAPI.get(`identity/internal/v1/email-templates/${id}`);
export const createSmsTemplateBIZnotifications = (form) => LocalIdenityAPI.post(`identity/internal/v1/email-templates`, form);
export const updateSmsTemplateBIZnotifications = (id, form) => LocalIdenityAPI.put(`identity/internal/v1/email-templates/${id}`, form);
export const deleteSmsTemplateBIZnotifications = (id) => LocalIdenityAPI.delete(`identity/internal/v1/email-templates/${id}`);

export const getSmsActivitiesBIZnotifications = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/sms?page=${page}&limit=${limit}${query}`);

export const getOtpRecordsBIZnotifications = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/otp-records?page=${page}&limit=${limit}${query}`);

// SUBMODULE - Subscriptions 
export const getProductsBIZsubs = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/products?page=${page}&limit=${limit}${query}`);
export const createProductBIZsubs = (form) => LocalIdenityAPI.post(`identity/internal/v1/products`, form);
export const updateProductBIZsubs = (id, form) => LocalIdenityAPI.put(`identity/internal/v1/products/${id}`, form);
export const deleteProductBIZsubs = (id) => LocalIdenityAPI.delete(`identity/internal/v1/products/${id}`);

export const getDeliveryMethodsBIZsubs = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/delivery-methods?page=${page}&limit=${limit}${query}`);
export const createDeliveryMethodBIZsubs = (form) => LocalIdenityAPI.post(`identity/internal/v1/delivery-methods`, form);
export const updateDeliveryMethodBIZsubs = (id, form) => LocalIdenityAPI.put(`identity/internal/v1/delivery-methods/${id}`, form);
export const deleteDeliveryMethodBIZsubs = (id) => LocalIdenityAPI.delete(`identity/internal/v1/delivery-methods/${id}`);

export const getIntegrationsBIZsubs = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/integrations?page=${page}&limit=${limit}${query}`);
export const createIntegrationBIZsubs = (form) => LocalIdenityAPI.post(`identity/internal/v1/integrations`, form);
export const updateIntegrationBIZsubs = (id, form) => LocalIdenityAPI.put(`identity/internal/v1/integrations/${id}`, form);
export const deleteIntegrationBIZsubs = (id) => LocalIdenityAPI.delete(`identity/internal/v1/integrations/${id}`);

export const getSubscriptionPlansBIZsubs = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/subscription-plans?page=${page}&limit=${limit}${query}`);
export const createSubscriptionPlanBIZsubs = (form) => LocalIdenityAPI.post(`identity/internal/v1/subscription-plans`, form);
export const updateSubscriptionPlanBIZsubs = (id, form) => LocalIdenityAPI.put(`identity/internal/v1/subscription-plans/${id}`, form);
export const deleteSubscriptionPlanBIZsubs = (id) => LocalIdenityAPI.delete(`identity/internal/v1/subscription-plans/${id}`);

export const getServicesBIZsubs = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/services?page=${page}&limit=${limit}${query}`);
export const createServiceBIZsubs = (form) => LocalIdenityAPI.post(`identity/internal/v1/services`, form);
export const updateServiceBIZsubs = (id, form) => LocalIdenityAPI.put(`identity/internal/v1/services/${id}`, form);
export const deleteServiceBIZsubs = (id) => LocalIdenityAPI.delete(`identity/internal/v1/services/${id}`);

export const getPlanAddonsBIZsubs = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/plan-addons?page=${page}&limit=${limit}${query}`);
export const createPlanAddonBIZsubs = (form) => LocalIdenityAPI.post(`identity/internal/v1/plan-addons`, form);
export const updatePlanAddonBIZsubs = (id, form) => LocalIdenityAPI.put(`identity/internal/v1/plan-addons/${id}`, form);
export const deletePlanAddonBIZsubs = (id) => LocalIdenityAPI.delete(`identity/internal/v1/plan-addons/${id}`);

export const getCompanyPlanAddonsBIZsubs = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/company-plan-addons?page=${page}&limit=${limit}${query}`);
export const createCompanyPlanAddonBIZsubs = (form) => LocalIdenityAPI.post(`identity/internal/v1/company-plan-addons`, form);
export const updateCompanyPlanAddonBIZsubs = (id, form) => LocalIdenityAPI.put(`identity/internal/v1/company-plan-addons/${id}`, form);
export const deleteCompanyPlanAddonBIZsubs = (id) => LocalIdenityAPI.delete(`identity/internal/v1/company-plan-addons/${id}`);

export const getDisallowedServicesBIZsubs = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/disallowed-services?page=${page}&limit=${limit}${query}`);
export const createDisallowedServiceBIZsubs = (form) => LocalIdenityAPI.post(`identity/internal/v1/disallowed-services`, form);
export const updateDisallowedServiceBIZsubs = (id, form) => LocalIdenityAPI.put(`identity/internal/v1/disallowed-services/${id}`, form);
export const deleteDisallowedServiceBIZsubs = (id) => LocalIdenityAPI.delete(`identity/internal/v1/disallowed-services/${id}`);


// SUBMODULE - Identity
export const getTenantsBIZidentity = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/tenants?page=${page}&limit=${limit}${query}`);
export const createTenantBIZidentity = (form) => LocalIdenityAPI.post(`identity/internal/v1/tenants`, form);
export const updateTenantBIZidentity = (id, form) => LocalIdenityAPI.put(`identity/internal/v1/tenants/${id}`, form);
export const deleteTenantBIZidentity = (id) => LocalIdenityAPI.delete(`identity/internal/v1/tenants/${id}`);

export const getCompaniesBIZidentity = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/companies?page=${page}&limit=${limit}${query}`);
export const updateCompanyBIZidentity = (id, form) => LocalIdenityAPI.put(`identity/internal/v1/companies/${id}`, form);

export const getBranchesBIZidentity = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/branches?page=${page}&limit=${limit}${query}`);

export const getUsersBIZidentity = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/users?page=${page}&limit=${limit}${query}`);

export const getCompanyEmployessBIZidentity = (page, limit, query) => LocalIdenityAPI.get(`identity/internal/v1/company-employees?page=${page}&limit=${limit}${query}`);


// SUBMODULE - Core
export const getCurrenciesBIZcore = (page, limit, query) =>  LocalCoreAPI.get(`core/internal/v1/currency?page=${page}&page_size=${limit}${query}`);
export const createCurrencyBIZcore = (form) => LocalCoreAPI.post(`core/internal/v1/currency`, form);
export const updateCurrencyBIZcore = (id, form) => LocalCoreAPI.put(`core/internal/v1/currency/${id}`, form);

export const getIndustriesBIZcore = (page, limit, query) => LocalCoreAPI.get(`core/internal/v1/accounting/industry?page=${page}&page_size=${limit}${query}`);
export const createIndustryBIZcore = (form) => LocalCoreAPI.post(`core/internal/v1/accounting/industry`, form);
export const updateIndustryBIZcore = (id, form) => LocalCoreAPI.put(`core/internal/v1/accounting/industry/${id}`, form);

export const getAccountTypesBIZcore = (page, limit, query) => LocalCoreAPI.get(`core/internal/v1/accounting/account-type?page=${page}&page_size=${limit}${query}`);
export const createAccountTypeBIZcore = (form) => LocalCoreAPI.post(`core/internal/v1/accounting/account-type`, form);
export const updateAccountTypeBIZcore = (id, form) => LocalCoreAPI.put(`core/internal/v1/accounting/account-type/${id}`, form);

export const getChartOfAccountsBIZcore = (industryId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/accounting/chart-of-accounts?industry_id=${industryId}&page=${page}&page_size=${limit}${query}`);
export const createChartOfAccountsBIZcore = (form) => LocalCoreAPI.post(`core/internal/v1/accounting/chart-of-accounts`, form);
export const updateChartOfAccountsBIZcore = (id, form) => LocalCoreAPI.put(`core/internal/v1/accounting/chart-of-accounts/${id}`, form);


export const getTenantsBIZcore = (page, limit, query) => LocalCoreAPI.get(`core/internal/v1/account/tenants?page=${page}&page_size=${limit}${query}`);
export const createTenantBIZcore = (form) => LocalCoreAPI.post(`core/internal/v1/account/tenants`, form);
export const updateTenantBIZcore = (id, form) => LocalCoreAPI.put(`core/internal/v1/account/tenants/${id}`, form);

export const getBankAccountTypesBIZcore = (tenantId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/account/bank-account-types?t_id=${tenantId}&page=${page}&page_size=${limit}${query}`);
export const createBankAccountTypeBIZcore = (tenantId, form) => LocalCoreAPI.post(`core/internal/v1/account/bank-account-types?t_id=${tenantId}`, form);
export const updateBankAccountTypeBIZcore = (tenantId, id, form) => LocalCoreAPI.put(`core/internal/v1/account/bank-account-types/${id}?t_id=${tenantId}`, form);

export const getBanksBIZcore = (tenantId, page, limit, query)  => LocalCoreAPI.get(`core/internal/v1/account/banks?t_id=${tenantId}&page=${page}&page_size=${limit}${query}`);
export const createBankBIZcore = (tenantId, form) => LocalCoreAPI.post(`core/internal/v1/account/banks?t_id=${tenantId}`, form);
export const updateBankBIZcore = (tenantId, id, form) => LocalCoreAPI.put(`core/internal/v1/account/banks/${id}?t_id=${tenantId}`, form);

export const getHolidaysBIZcore = (tenantId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/account/holidays?t_id=${tenantId}&page=${page}&page_size=${limit}${query}`);
export const createHolidayBIZcore = (tenantId, form) => LocalCoreAPI.post(`core/internal/v1/account/holidays?t_id=${tenantId}`, form);
export const updateHolidayBIZcore = (tenantId, id, form) => LocalCoreAPI.put(`core/internal/v1/account/holidays/${id}?t_id=${tenantId}`, form);
export const deleteHolidayBIZcore = (tenantId, id) => LocalCoreAPI.delete(`core/internal/v1/account/holidays/${id}?t_id=${tenantId}`);

export const getCostObjectsBIZcore = (tenantId, query) => LocalCoreAPI.get(`core/internal/v1/account/cost-objects-root?t_id=${tenantId}${query}`);
export const createCostObjectsBIZcore = (tenantId, form) => LocalCoreAPI.post(`core/internal/v1/account/cost-objects-root?t_id=${tenantId}`, form);
export const deleteCostObjectsBIZcore = (tenantId, id) => LocalCoreAPI.delete(`core/internal/v1/account/cost-objects-root/${id}?t_id=${tenantId}`);

export const getTenantBankAccountsBIZcore = (tenantId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/account/tenant-bank-accounts?t_id=${tenantId}&page=${page}&page_size=${limit}${query}`);
export const createTenantBankAccountsBIZcore = (tenantId, form) => LocalCoreAPI.post(`core/internal/v1/account/tenant-bank-accounts?t_id=${tenantId}`, form);
export const updateTenantBankAccountBIZcore = (tenantId, id, form) => LocalCoreAPI.put(`core/internal/v1/account/tenant-bank-accounts/${id}?t_id=${tenantId}`, form);

export const getPaymentFrequenciesBIZcore = (tenantId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/pay/payment-frequencies?t_id=${tenantId}&page=${page}&page_size=${limit}${query}`);
export const createPaymentFrequencyBIZcore = (tenantId, form) => LocalCoreAPI.post(`core/internal/v1/pay/payment-frequencies?t_id=${tenantId}`, form);
export const updatePaymentFrequencyBIZcore = (tenantId, id, form) => LocalCoreAPI.put(`core/internal/v1/pay/payment-frequencies/${id}?t_id=${tenantId}`, form);
export const deletePaymentFrequencyBIZcore = (tenantId, id) => LocalCoreAPI.delete(`core/internal/v1/pay/payment-frequencies/${id}?t_id=${tenantId}`);

export const getPaymentMethodsBIZcore = (tenantId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/expense/payment-methods?t_id=${tenantId}&page=${page}&page_size=${limit}${query}`);
export const createPaymentMethodBIZcore = (tenantId, form) => LocalCoreAPI.post(`core/internal/v1/expense/payment-methods?t_id=${tenantId}`, form);
export const updatePaymentMethodBIZcore = (tenantId, id, form) => LocalCoreAPI.put(`core/internal/v1/expense/payment-methods/${id}?t_id=${tenantId}`, form);

export const getCategoriesBIZcore = (tenantId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/expense/categories-root?t_id=${tenantId}&page=${page}&page_size=${limit}${query}`);
export const createCategoryBIZcore = (tenantId, form) => LocalCoreAPI.post(`core/internal/v1/expense/categories-root?t_id=${tenantId}`, form);
export const updateCategoryBIZcore = (tenantId, id, form) => LocalCoreAPI.put(`core/internal/v1/expense/categories-root/${id}?t_id=${tenantId}`, form);
export const toggleCategoryBIZcore = (tenantId, form) => LocalCoreAPI.put(`core/internal/v1/expense/categories-root/toggle?t_id=${tenantId}`, form);
export const toggleCategoryPaymentMethodBIZcore = (tenantId, form) => LocalCoreAPI.put(`core/internal/v1/expense/categories-root/toggle-payment-method?t_id=${tenantId}`, form);

export const getDeliveryMethodsBIZcore = (tenantId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/pay/delivery-methods?t_id=${tenantId}&page=${page}&page_size=${limit}${query}`);
export const createDeliveryMethodBIZcore = (tenantId, form) => LocalCoreAPI.post(`core/internal/v1/pay/delivery-methods?t_id=${tenantId}`, form);
export const updateDeliveryMethodBIZcore = (tenantId, id, form) => LocalCoreAPI.put(`core/internal/v1/pay/delivery-methods/${id}?t_id=${tenantId}`, form);
export const deleteDelivetyMethodBIZcore = (tenantId, id) => LocalCoreAPI.delete(`core/internal/v1/pay/delivery-methods/${id}?t_id=${tenantId}`);

export const getServiceFeesBIZcore = (tenantId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/billing/service-fees?t_id=${tenantId}&page=${page}&page_size=${limit}${query}`);
export const createServiceFeeBIZcore = (tenantId, form) => LocalCoreAPI.post(`core/internal/v1/billing/service-fees?t_id=${tenantId}`, form);
export const updateServiceFeeBIZcore = (tenantId, id, form) => LocalCoreAPI.put(`core/internal/v1/billing/service-fees/${id}?t_id=${tenantId}`, form);
export const deleteServiceFeeBIZcore = (tenantId, id) => LocalCoreAPI.delete(`core/internal/v1/billing/service-fees/${id}?t_id=${tenantId}`);


export const getCompaniesBIZcore = (page, limit, query) => LocalCoreAPI.get(`core/internal/v1/account/companies?page=${page}&page_size=${limit}${query}`);
export const createCompanyBIZcore = (form) => LocalCoreAPI.post(`core/internal/v1/account/companies`, form);
export const updateCompanyBIZcore = (id, form) => LocalCoreAPI.put(`core/internal/v1/account/companies/${id}`, form);

export const getEmployeesBIZcore = (companyId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/account/employees?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const createEmployeeBIZcore = (companyId, form) => LocalCoreAPI.post(`core/internal/v1/account/employees?c_id=${companyId}`, form);
export const updateEmployeeBIZcore = (companyId, id, form) => LocalCoreAPI.put(`core/internal/v1/account/employees/${id}?c_id=${companyId}`, form);

export const getCompanyBankAccountsBIZcore = (companyId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/account/company-bank-accounts?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const updateCompanyBankAccountBIZcore = (companyId, id, form) => LocalCoreAPI.put(`core/internal/v1/account/company-bank-accounts/${id}?c_id=${companyId}`, form);

export const getDiscountsBIZcore = (companyId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/billing/discounts?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);
export const createDiscountBIZcore = (companyId, form) => LocalCoreAPI.post(`core/internal/v1/billing/discounts?c_id=${companyId}`, form);
export const updateDiscountBIZcore = (companyId, id, form) => LocalCoreAPI.put(`core/internal/v1/billing/discounts/${id}?c_id=${companyId}`, form);
export const deleteDiscountBIZcore = (companyId, id) => LocalCoreAPI.delete(`core/internal/v1/billing/discounts/${id}?c_id=${companyId}`);

export const getBillingPaysBIZcore = (companyId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/billing/billing-pay?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);

export const getBillingReceivesBIZcore = (companyId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/billing/billing-receive?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);

export const getBillingExpensesBIZcore = (companyId, page, limit, query) => LocalCoreAPI.get(`core/internal/v1/billing/billing-expense?c_id=${companyId}&page=${page}&page_size=${limit}${query}`);


// SUBMODULE - fusion
export const getActivitiesBIZfusion = (page, limit, query) => LocalFusionAPI.get(`fusion/orum/internal/v1/activities?page=${page}&limit=${limit}${query}`);

export const getWebhooksBIZfusionORUM = () => LocalFusionAPI.get(`fusion/orum/internal/v1/webhooks`);
export const createWebhookBIZfusionORUM = (form) => LocalFusionAPI.post(`fusion/orum/internal/v1/webhooks`, form);
export const updateWebhookBIZfusionORUM = (id, form) => LocalFusionAPI.put(`fusion/orum/internal/v1/webhooks/${id}`, form);
export const deleteWebhookBIZfusionORUM = (id) => LocalFusionAPI.delete(`fusion/orum/internal/v1/webhooks/${id}`);

export const getAccountsBIZfusionCC = (page, limit, query) => LocalFusionAPI.get(`fusion/cc/internal/v1/accounts?page=${page}&limit=${limit}${query}`);

export const getContactsBIZfusionCC = (page, limit, query) => LocalFusionAPI.get(`fusion/cc/internal/v1/contacts?page=${page}&limit=${limit}${query}`);

export const getBeneficiariesBIZfusionCC = (page, limit, query) => LocalFusionAPI.get(`fusion/cc/internal/v1/beneficiaries?page=${page}&limit=${limit}${query}`);
export const createBeneficiaryBIZfusionCC = (form) => LocalFusionAPI.post(`fusion/cc/internal/v1/beneficiaries`, form);
export const deleteBeneficiaryBIZfusionCC = (id) => LocalFusionAPI.delete(`fusion/cc/internal/v1/beneficiaries/${id}`);

export const getConversionsBIZfusionCC = (page, limit, query) => LocalFusionAPI.get(`fusion/cc/internal/v1/conversions?page=${page}&limit=${limit}${query}`);
export const createConversionBIZfusionCC = (form) => LocalFusionAPI.post(`fusion/cc/internal/v1/conversions`, form);

export const getBasicRatesBIZfusionCC = (query) => LocalFusionAPI.get(`fusion/cc/internal/v1/rates/basic${query}`);
export const getDetailedRatesBIZfusionCC = (query) => LocalFusionAPI.get(`fusion/cc/internal/v1/rates/detailed${query}`);

export const getPaymentsBIZfusionCC = (page, limit, query) => LocalFusionAPI.get(`fusion/cc/internal/v1/payments?page=${page}&limit=${limit}${query}`);
export const createPaymentBIZfusionCC = (form) => LocalFusionAPI.post(`fusion/cc/internal/v1/payments`, form);

export const getTransfersBIZfusionCC = (page, limit, query) => LocalFusionAPI.get(`fusion/cc/internal/v1/transfers?page=${page}&limit=${limit}${query}`);
export const createTransferBIZfusionCC = (form) => LocalFusionAPI.post(`fusion/cc/internal/v1/transfers`, form);



// SUBMODULE - webhook
export const getActivitiesBIZwebhook = (page, limit, query) => LocalWebhookAPI.get(`internal/v1/activities?page=${page}&limit=${limit}${query}`);
export const getWebhookLogsBIZwebhook = (page, limit, query) => LocalWebhookAPI.get(`internal/v1/webhook-logs?page=${page}&limit=${limit}${query}`);



// ========================== SDK =================================


// ========================== Support =============================
export const getIssuesSPT = (page, limit) => API.get(`b2c/support/internal/v1/issues?page=${page}&limit=${limit}`);
export const getIssueDetailsSPT = (id) => API.get(`b2c/support/internal/v1/issues/${id}`);
export const updateIssue = (id, status) => API.patch(``);

// SUBMODULE - Config
export const getCategoriesSPTconfig = (page, limit) => API.get(`b2c/support/internal/v1/categories?page=${page}&limit=${limit}`);
export const createCategorySPTconfig = (form) => API.post(`b2c/support/internal/v1/categories`, form);
export const updateCategorySPTconfig = (id, form) => API.put(`b2c/support/internal/v1/categories/${id}`, form);
export const toggleCategorySPTconfig = (id) => API.patch(`b2c/support/internal/v1/categories/${id}`);

export const getStagesSPTconfig = (page, limit) => API.get(`b2c/support/internal/v1/stages?page=${page}&limit=${limit}`);
export const createStageSPTconfig = (form) => API.post(`b2c/support/internal/v1/stages`, form);
export const updateStageSPTconfig = (id, form) => API.put(`b2c/support/internal/v1/stages/${id}`, form);
export const toggleStageSPTconfig = (id) => API.patch(`b2c/support/internal/v1/stages/${id}`);