import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel,  
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import useSelectedTenant from '../../../../../components/hooks/useSelectedTenant';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { 
  DeleteDelivetyMethodBIZcore, 
  GetDeliveryMethodsBIZcore
} from '../../../../../redux/actions/business/core/deliveryMethodsActions';

import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import DeliveryMethodsModal from './DeliveryMethodsModal';

import global from "../../../../../global.scss";

const DeliveryMethodsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.core"]);
  const tenantId = useSelectedTenant();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [deliveryMethodsModalOpen, setDeliveryMethodsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [deliveryMethodsList, setDeliveryMethodsList] = useState([]);
  const [deliveryMethodsTotal, setDeliveryMethodsTotal] = useState(0);

  const [deliveryMethodsMenuOpen, setDeliveryMethodsMenuOpen] = useState(false);
  const [deliveryMethodsMenuAnchor, setDeliveryMethodsMenuAnchor] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Delivery method",
      sort: "delivery_method",
    },
    {
      label: "Delivered by",
      sort: "delivered_by",
    },
    {
      label: "Service Code",
      sort: "ref_sevice_code",
    },
    {
      label: "Delivery speed",
      sort: "delivery_speed",
    },
    {
      label: "Time stamps",
      sort: "created_at",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    dispatch(GetDeliveryMethodsBIZcore(tenantId, page+1, rowsPerPage, query, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatDeliveryMethodsList(state.deliveryMethodsList.records || []);
      setDeliveryMethodsTotal(state.deliveryMethodsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatDeliveryMethodsList = (list) => {
    setDeliveryMethodsList(list)
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteDelivetyMethodBIZcore(tenantId, deleteId, setDeleting)).then(() => {
      let query = ""
      if(search) query += `&search=${search}`;
      if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
      dispatch(GetDeliveryMethodsBIZcore(tenantId, page+1, rowsPerPage, query, setDeleting)).then(() => {
        setDeleteId(null);
      })
    });
  };

  return (
    <Box>

      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete} 
        />

      <DeliveryMethodsModal 
        open={deliveryMethodsModalOpen}
        setOpen={setDeliveryMethodsModalOpen}
        title={modalTitle}
        update={update}
        page={page}
        rowsPerPage={rowsPerPage}
        />

      <TopBar
        title={"Delivery Methods"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add Delivery method"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add Delivery method");
          setDeliveryMethodsModalOpen(!deliveryMethodsModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          deliveryMethodsList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No delivery method found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                deliveryMethodsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.delivery_method || <NA />}</TableCell>
                    <TableCell>{row.delivered_by || <NA />}</TableCell>
                    <TableCell>{row.ref_sevice_code || <NA />}</TableCell>
                    <TableCell>{row.delivery_speed?.toString() || <NA />}</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.created_at}
                        updatedAt={row.updated_at}
                        />
                    </TableCell>
                    <TableCell>
                      {
                        deleteId === row.id && deleting
                        ?
                        <CircularProgress size={24}/>
                        :
                        <IconButton
                          onClick={(e) => {
                            setDeliveryMethodsMenuAnchor(e.currentTarget);
                            setDeliveryMethodsMenuOpen(true);
                            setUpdate(row);
                            setModalTitle(row.delivery_method || "");
                          }}>
                          <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={deliveryMethodsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={deliveryMethodsMenuOpen}
        anchorEl={deliveryMethodsMenuAnchor}
        onClose={() => setDeliveryMethodsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setDeliveryMethodsMenuOpen(false);
            setDeliveryMethodsModalOpen(!deliveryMethodsModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteId(update.id);
            setDeliveryMethodsMenuOpen(false);
            setDeleteConfirmationModalOpen(true);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default DeliveryMethodsPage;