import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../components/utils/TopBar';
import NA from '../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { 
  GetCompaniesBIZidentity 
} from '../../../../redux/actions/business/identity/companiesActions';

import CompaniesModal from './CompaniesModal';

import global from "../../../../global.scss";

const CompaniesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.identity"]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [companiesModalOpen, setCompaniesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [companiesList, setCompaniesList] = useState([]);
  const [companiesTotal, setCompaniesTotal] = useState(0);

  const [companiesMenuOpen, setCompaniesMenuOpen] = useState(false);
  const [companiesMenuAnchor, setCompaniesMenuAnchor] = useState(null);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Legal name",
      sort: "legal_name",
    },
    {
      label: "Trading path",
      sort: "trading_name",
    },
    {
      label: "Super admin",
      sort: "admin_user_id",
    },
    {
      label: "Tenant",
      sort: "tenant_id",
    },
    {
      label: "Subscription Plan",
      sort: "ref_plan_id"
    },
    {
      label: "Plan type",
      sort: "plan_type"
    },
    {
      label: "Time stamps",
      sort: "createdAt",
    },
    {
      label: "",
      sort: null,
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetCompanies();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatCompaniesList(state.companiesList.records || []);
      setCompaniesTotal(state.companiesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatCompaniesList = (list) => {
    setCompaniesList(list)
  };

  async function handleGetCompanies(){
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetCompaniesBIZidentity(page+1, rowsPerPage, query, setLoading));
  };


  return (
    <Box>

      <CompaniesModal
        open={companiesModalOpen}
        setOpen={setCompaniesModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetCompanies}
        />

      <TopBar
        title={"Companies"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          companiesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No company found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                companiesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.legal_name || <NA />}</TableCell>
                    <TableCell>{row.trading_name || <NA />}</TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{row?.user?.first_name || <NA />}&nbsp;{row.user?.last_name}</Typography>
                        <i><Typography>{row?.user.user_name || <NA />}</Typography></i>
                      </Box>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.admin_user_id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{row?.tenant?.legal_name || <NA />}</Typography>
                        <i><Typography>{row?.tenant.name || <NA />}</Typography></i>
                      </Box>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.tenant_id || <NA />}
                      </Typography>
                    </TableCell>
                    
                    <TableCell>
                      <Box>
                        <Typography>{row?.subscription_plan?.plan_name || <NA />}</Typography>
                      </Box>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.ref_plan_id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row?.plan_type || <NA />}</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          setCompaniesMenuAnchor(e.currentTarget);
                          setCompaniesMenuOpen(true);
                          setUpdate(row);
                          setModalTitle(row.legal_name || "");
                        }}>
                        <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={companiesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={companiesMenuOpen}
        anchorEl={companiesMenuAnchor}
        onClose={() => setCompaniesMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setCompaniesMenuOpen(false);
            setCompaniesModalOpen(!companiesModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default CompaniesPage;