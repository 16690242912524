import React from 'react';
import { useNavigate } from 'react-router-dom';

import { 
  Box, 
  Grid,
  Typography
} from '@mui/material';

import CellTowerIcon from '@mui/icons-material/CellTower';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import CodeIcon from '@mui/icons-material/Code';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

import "./styles.scss";

export const moduleList = [
  {
    name: "WatchTower",
    route: "/watch",
    icon: <CellTowerIcon 
            style={{ width: "60px", height: "60px" }}
            />
  },
  {
    name: "Consumer",
    route: "/consumer",
    icon: <StoreOutlinedIcon 
            style={{ width: "60px", height: "60px" }}
            />
  },
  {
    name: "Business",
    route: "/business",
    icon: <BusinessIcon 
            style={{ width: "60px", height: "60px" }}
            />
  },
  {
    name: "SDK",
    route: "/sdk",
    icon: <CodeIcon 
            style={{ width: "60px", height: "60px" }}
            />
  },
  {
    name: "Support",
    route: "/support",
    icon: <SupportAgentIcon 
            style={{ width: "60px", height: "60px" }}
            />
  },
  {
    name: "Docs",
    route: "/docs",
    icon: <ArticleOutlinedIcon 
            style={{ width: "60px", height: "60px" }}
            />
  }
];

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <Box
      className="home flexCenterCenterColumn"
      gap={"40px"}>
      <Typography
        variant='h6'>
        Select Module
      </Typography>
      <Grid
        container
        spacing={2}
        className="home__module">
        {
          moduleList.map((module, moduleIndex) => (
            <Grid
              key={moduleIndex}
              item
              xs={12} sm={12} md={4} lg={4}>
              <Box
                className="block flexCenterCenterColumn home__module-item"
                gap={"15px"}
                onClick={() => {
                  navigate(module.route)
                }}>
                {module.icon}
                <Typography 
                  variant='h6'>
                  {module.name}
                </Typography>
              </Box>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  )
}

export default HomePage;