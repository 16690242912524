import React from 'react';

import "./styles.scss";

const HomePage = () => {
  return (
    <div>HomePage</div>
  )
}

export default HomePage;