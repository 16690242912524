import * as actionTypes from "../actionTypes.js";

const initialState = {
  companiesList: null,
  branchesList: null,
  usersList: null,
  companyEmployeesList: null, 
  tenantsList: null
};

const businessIdentityReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANIES_BIZ_IDENTITY:
      return { ...state, companiesList: action.payload };

    case actionTypes.GET_BRANCHES_BIZ_IDENTITY:
      return { ...state, branchesList: action.payload };

    case actionTypes.GET_USERS_BIZ_IDENTITY:
      return { ...state, usersList: action.payload };

    case actionTypes.GET_COMPANY_EMPLOYEES_BIZ_IDENTITY:
      return { ...state, companyEmployeesList: action.payload };

    case actionTypes.GET_TENANTS_BIZ_IDENTITY:
      return { ...state, tenantsList: action.payload };


    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default businessIdentityReducer;