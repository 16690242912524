import { createTheme } from "@mui/material";

import styles from "../global.scss";

export const theme = createTheme({
    typography: {
        fontFamily: "urbanist",
        h1: {
            fontWeight: 900,
            fontSize: "108px",
            color: styles["primary_light"]
        },
        h2: {
            fontWeight: 800,
            fontSize: "64px",
            color: styles["primary_light"]
        },
        h3: {
            fontWeight: 700,
            fontSize: "48px",
            color: styles["primary_light"]
        },
        h4: {
            fontWeight: 600,
            fontSize: "32px",
            color: styles["primary_light"]
        },
        h5: {
            fontWeight: 600,
            fontSize: "26px",
            color: styles["primary_light"]
        },
        h6: {
            fontWeight: 600,
            fontSize: "22px",
            color: styles["primary_light"]
        },
        subtitle1: {
            fontWeight: 600,
            fontSize: "18px",
            color: styles["primary_light"]
        },
        subtitle2: {
            fontWeight: 600,
            fontSize: "16px",
            color: styles["primary_light"]
        },
        body1: {
            fontWeight: 500,
            fontSize: "15px", 
            color: styles["primary_light"]
        },
        body2: {
            fontWeight: 500,
            fontSize: "14px",
            color: styles["primary_light"]
        },
        button: {
            fontWeight: 700,
            fontSize: "15px",
            color: styles["primary_light"]
        },
        caption: {
            fontWeight: 500,
            fontSize: "13px",
            color: styles["primary_light"]
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    minWidth: "120px",
                    minHeight: "38.56px"
                },
                text: {
                    backgroundColor: "#00000000",
                    color: styles["primary"],
                    minWidth: "120px",
                    "&:hover": {
                        backgroundColor: styles["primary_light"],
                        color: styles["secondary"],
                    }
                },
                contained: {
                    backgroundColor: styles["secondary_light"],
                    color: styles["primary_light"],
                    "&:hover": {
                        backgroundColor: styles["primary"],
                        color: styles["secondary"],
                    }
                },
                outlined: {
                    borderColor: styles["primary_light"],
                    color: styles["primary_light"],
                    "&:hover": {
                        backgroundColor: styles["primary"],
                        borderColor: styles["secondary"],
                        color: styles["secondary"],
                    }
                },
                containedError: {
                    backgroundColor: "#d32f2f",
                    color: styles["primary_light"],
                    "&:hover": {
                        backgroundColor: styles["primary"],
                        color: styles["secondary"],
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    borderColor: "red",
                    color: styles["primary_light"],
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: "#676767",
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: styles["secondary_light"],
                    },
                },
                notchedOutline: {
                    borderColor: "#343434",
                },
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "grey"
                }
            }
        }, 
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    // background: "rgba( 50, 50, 50, 0.1 )",
                    // boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
                    // "backdrop-filter": "blur( 6px )",
                    // "-webkit-backdrop-filter": "blur( 6px )",
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    // pointerEvents: 'none',
                },
                paper: {
                    borderRadius: "12px",
                    // pointerEvents: "auto",
                },
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: "rgba(44, 45, 47, 0.64)",
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                    backdropFilter: "blur(18.3px)",
                    "-webkit-backdrop-filter": "blur(18.3px)",
                    border: "1px solid rgba(44, 45, 47, 0.72)",
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    overflow: "hidden"
                }
            }
        },
        MuiTableSortLabel: {
            // MuiButtonBase-root MuiTableSortLabel-root css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root
            styleOverrides: {
                root: {
                    color: styles["primary_dark"],
                    "&:hover": {
                        color: "#6ec2e7"
                    },
                    '&.Mui-active': {
                        color: styles["secondary_light"]
                    },
                    "&.Mui-active .MuiTableSortLabel-icon": {
                        color: styles["secondary_light"]
                    }

                },

            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: styles["primary_dark"],
                    borderColor: "black",
                    padding: "8px 16px",
                },
                body: {
                    color: styles["primary_light"],
                    borderColor: "black",
                    padding: "8px 16px",
                },
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                select: {
                    color: styles["primary"]
                },
                selectIcon: {
                    color: styles["primary"]
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: "18px"
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: styles["secondary"]
                    }
                },
            }
        },
        MuiChip: {
            styleOverrides: {
                labelSmall: {
                    fontSize: "12px"
                },
                filled: {
                    backgroundColor: "#e1e1e1",
                    color: "#434343",
                    fontWeight: 500,
                    "&.MuiChip-colorError": {
                        backgroundColor: "#b24020",
                        color: "#fadfd8"
                    },
                    "&.MuiChip-colorSuccess": {
                        backgroundColor: "#44712e",
                        color: "#d7ebce"
                    },
                    "&.MuiChip-colorInfo": {
                        backgroundColor: "#004182",
                        color: "#dce6f1"
                    },
                    "&.MuiChip-colorWarning": {
                        backgroundColor: "#915907",
                        color: "#fce2ba"
                    },
                },
                outlined: {
                    fontWeight: 500,
                    "&.MuiChip-colorError": {
                        backgroundColor: "#b24020",
                        color: "#fadfd8"
                    },
                    "&.MuiChip-colorSuccess": {
                        backgroundColor: "#44712e",
                        color: "#d7ebce"
                    },
                    "&.MuiChip-colorInfo": {
                        backgroundColor: "#004182",
                        color: "#dce6f1"
                    },
                    "&.MuiChip-colorWarning": {
                        backgroundColor: "#915907",
                        color: "#fce2ba"
                    },
                },
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    padding: 6,
                },
                thumb: {
                    backgroundColor: styles["primary"],
                    boxShadow: 'none',
                    width: 18,
                    height: 18,
                    margin: 1,
                },
                track: {
                    border: "1.5px solid",
                    borderColor: styles["secondary"],
                    backgroundColor: "red",
                    borderRadius: 26 / 2,
                    '&::before, &::after': {
                        content: '""',
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: 18,
                        height: 18,
                    },
                },
            }
        },
    }
})