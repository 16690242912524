import React from 'react';

import { 
  Box, 
  Typography
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';

import "../styles.scss";

const CoreCompany = ({
  navigate, 
  currentPage
}) => {
  return (
    <Box className="nav__side-common flex__Column" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/companies");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
      </Box>
      <Typography
        mt={"12px"}
        mb={"-5px"}>
        <i>Account</i>
      </Typography>
      <Box className="nav__side-container">
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/companies/employees");
          }}>
          <GroupOutlinedIcon />
          <Typography variant='body2'>
            Employees
          </Typography>
        </Box>
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/companies/bank-accounts");
          }}>
          <AccountBalanceOutlinedIcon />
          <Typography variant='body2'>
            Company Bank accounts
          </Typography>
        </Box>
      </Box>
      <Typography
        mt={"12px"}
        mb={"-5px"}>
        <i>Billing</i>
      </Typography>
      <Box className="nav__side-container">
        <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/companies/discounts");
          }}>
          <DiscountOutlinedIcon />
          <Typography variant='body2'>
            Discount
          </Typography>
        </Box>
        <Box className={`${currentPage === 3 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/companies/billing-pay");
          }}>
          <PaymentOutlinedIcon />
          <Typography variant='body2'>
            Pay
          </Typography>
        </Box>
        <Box className={`${currentPage === 4 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/companies/billing-receive");
          }}>
          <LocalAtmOutlinedIcon />
          <Typography variant='body2'>
            Receive
          </Typography>
        </Box>
        <Box className={`${currentPage === 5 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/companies/billing-expense");
          }}>
          <ShoppingBagOutlinedIcon />
          <Typography variant='body2'>
            Expense
          </Typography>
        </Box>
      </Box> 
    </Box>
  )
}

export default CoreCompany;