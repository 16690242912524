import * as actionTypes from "../actionTypes.js";

const initialState = {
  issuesList: null
};

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ISSUES_SPT:
      return { ...state, issuesList: action.payload };

    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default supportReducer;