import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,    
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../components/utils/TopBar';
import NA from '../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { 
  DeleteIntegrationBIZsubs,
  GetIntegrationsBIZsubs 
} from '../../../../redux/actions/business/subscriptions/integrationsActions';

import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import IntegrationsModal from './IntegrationsModal';

import global from "../../../../global.scss";

const IntegrationsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.subscriptions"]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [integrationsModalOpen, setIntegrationsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [integrationsList, setIntegrationsList] = useState([]);
  const [integrationsTotal, setIntegrationsTotal] = useState(0);

  const [integrationsMenuOpen, setIntegrationsMenuOpen] = useState(false);
  const [integrationsMenuAnchor, setIntegrationsMenuAnchor] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Integration with software",
      sort: "integ_with_software",
    },
    {
      label: "Tenant",
      sort: "ref_tenant_id",
    },
    {
      label: "Time stamps",
      sort: "createdAt",
    },
    {
      label: "",
      sort: null,
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetIntegrationsBIZsubs(page+1, rowsPerPage, query, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatIntegrationsList(state.integrationsList.records || []);
      setIntegrationsTotal(state.integrationsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatIntegrationsList = (list) => {
    setIntegrationsList(list)
  };

  async function handleGetIntegrations(){
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetIntegrationsBIZsubs(page+1, rowsPerPage, query, setLoading));
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteIntegrationBIZsubs(deleteId, setDeleting)).then(() => {
      handleGetIntegrations().then(() => {
        setDeleteId(null);
      })
    });
  };

  return (
    <Box>

      <IntegrationsModal
        open={integrationsModalOpen}
        setOpen={setIntegrationsModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetIntegrations}
        />

      <ConfirmationModal 
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete}
        />

      <TopBar
        title={"Integrations"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add Integration"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add Integration");
          setIntegrationsModalOpen(!integrationsModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          integrationsList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No integration found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                integrationsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.integ_with_software || <NA />}</TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{row?.tenant?.legal_name || <NA />}</Typography>
                        <i><Typography>{row?.tenant.name || <NA />}</Typography></i>
                      </Box>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.ref_tenant_id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                    <TableCell>
                      {
                        row.id === deleteId && deleting
                        ?
                        <CircularProgress size={26} sx={{ margin: "7px" }}/>
                        :
                        <IconButton
                          onClick={(e) => {
                            setIntegrationsMenuAnchor(e.currentTarget);
                            setIntegrationsMenuOpen(true);
                            setDeleteId(row.id);
                            setUpdate(row);
                            setModalTitle(row.integ_with_software || "");
                          }}>
                          <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={integrationsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={integrationsMenuOpen}
        anchorEl={integrationsMenuAnchor}
        onClose={() => setIntegrationsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setIntegrationsMenuOpen(false);
            setIntegrationsModalOpen(!integrationsModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIntegrationsMenuOpen(false);
            setDeleteConfirmationModalOpen(true);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default IntegrationsPage; 