import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../components/utils/TopBar';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  DeleteCurrencyCONvcn,
  GetCurrenciesCONvcn 
} from '../../../../redux/actions/consumer/virtualCards/currenciesAction';


import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import CurrenciesModal from './CurrenciesModal';

import global from "../../../../global.scss";

const CurrenciesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["consumer.vcn"]);
  const [loading, setLoading] = useState(true);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [currenciesModalOpen, setCurrenciesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [updateObj, setUpdateObj] = useState(null);

  const [currenciesList, setCurrenciesList] = useState([]);
  const [currenciesTotal, setCurrenciesTotal] = useState(0);
  
  const [deleteId, setDeleteId] = useState("");
  const [deleting, setDeleting] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();
  
  useEffect(() => {
    dispatch(GetCurrenciesCONvcn(page+1, rowsPerPage, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    try { 
      formatCurrenciesList(state.currenciesList.Records || []);
      setCurrenciesTotal(state.currenciesList.Total || 0);
    } catch (err) {}
  }, [state]);

  // --------------------- list formatter ------------------------
  const formatCurrenciesList = (list) => {
    setCurrenciesList(list);
  };

  const handleDelete = () => {
    setConfirmationModalOpen(false);
    dispatch(DeleteCurrencyCONvcn(deleteId, setDeleting)).then(() => {
      dispatch(GetCurrenciesCONvcn(page+1, rowsPerPage, setLoading));
    })
  };

  return (
    <Box width={"100%"} 
      >

      <ConfirmationModal 
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        title={modalTitle}
        onConfirm={() => handleDelete()}
        onCancel={() => {setDeleteId(null)}}
        />

      <CurrenciesModal
        open={currenciesModalOpen}
        setOpen={setCurrenciesModalOpen}
        title={modalTitle}
        update={updateObj}
        page={page}
        rowsPerPage={rowsPerPage}
        />

      <TopBar
        title={"Currencies"}
        button1Text={"Add Currency"}
        button1OnClick={() => {
          setModalTitle("Add Currency");
          setCurrenciesModalOpen(!currenciesModalOpen);
        }}
        />
    
    {
      loading
      ?
      <Box height={"300px"} className="flexCenterCenterRow">
        <CircularProgress />
      </Box>
      :
        currenciesList.length === 0
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <Typography>No currency found</Typography>
        </Box>
        :
        <Table className="block">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Character Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Numeric Code</TableCell>
              <TableCell>Time stamps</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              currenciesList.map((row, index) => (
                <TableRow 
                  key={index}>
                  <TableCell sx={{ color: "grey" }}>{row.Id}</TableCell>
                  <TableCell>{row.CharacterCode || ""}</TableCell>
                  <TableCell>{row.Name|| ""}</TableCell>
                  <TableCell>{row.NumericCode || ""}</TableCell>
                  <TableCell>
                    <TableTimeStamps
                      createdAt={row.createdAt}
                      updatedAt={row.updatedAt}
                      />
                  </TableCell>
                  <TableCell>
                    <Box
                      className="flexCenter_Row"
                      gap={"20px"}>
                      <Tooltip
                        title={"Edit Currency: " + row.CharacterCode}>
                        <IconButton
                          onClick={() => {
                            setUpdateObj(row);
                            setModalTitle("Edit Currency - " + row.CharacterCode);
                            setCurrenciesModalOpen(!currenciesModalOpen);
                          }}>
                          <EditIcon sx={{ color: global["primary_light"] }}/>
                        </IconButton>
                      </Tooltip>
                      {
                        deleting && deleteId === row.Id
                        ?
                        <CircularProgress size={24}/>
                        :
                        <Tooltip  
                          title={"Delete Currency: " + row.CharacterCode}>
                          <IconButton
                            onClick={() => {
                              setDeleteId(row.Id);
                              setModalTitle("Delete Currency - " + row.CharacterCode);
                              setConfirmationModalOpen(!confirmationModalOpen);
                            }}>
                            <DeleteIcon color='error' />
                          </IconButton>
                        </Tooltip>
                      }
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{ borderBottom: 0 }}
                 rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                colSpan={10}
                count={currenciesTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                />
            </TableRow>
          </TableFooter>
        </Table>
    }
    </Box>
  )
}

export default CurrenciesPage;