import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import useSelectedTenant from '../../../../../components/hooks/useSelectedTenant';
import useCurrencyService from '../../../../../components/hooks/useCurrencyService';
import LoadingButton from '../../../../../components/buttons/Button';
import AmountTextField from '../../../../../components/textFields/AmountTextField';

import {
  CreateServiceFeeBIZcore, 
  GetServiceFeesBIZcore, 
  UpdateServiceFeeBIZcore 
} from '../../../../../redux/actions/business/core/serviceFeesActions';

import global from "../../../../../global.scss";

const Data = {
  ServiceId: "",
  FeeName: "",
  FeePercent: 0.00,
  FeeAmount: 0,
  FeeCurrency: "USD",
  FeeMin: 0,
  FeeMax: 0,
  MinTrxAmount: 0,
  MaxTrxAmount: 0
};

const ServiceFeesModal = ({
  open, 
  setOpen, 
  title,
  update,
  page, 
  rowsPerPage
}) => {
  const dispatch = useDispatch();
  const tenantId = useSelectedTenant();
  const { handleEditAmount, handleCalculateAmount } = useCurrencyService();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  }

  useEffect(() => {
    try {
      if(open){
        if(update.defaultMode === 1){
          // mode = 1 - create service fees
          setMode(1);
        }else if(update.defaultMode === 2){
          // mode = 2 - edit service fees
          setMode(2);
          setFormData({
            ...formData,
            ServiceId: update.ref_service_id || "",
            FeeName: update.fee_name || "",
            FeePercent: update.fee_percent || 0.00,
            FeeAmount: handleEditAmount(update.fee_currency, update.fee_amount || ""),
            FeeCurrency: update.fee_currency || "USD",
            FeeMin: handleEditAmount(update.fee_currency, update.fee_min || ""),
            FeeMax: handleEditAmount(update.fee_currency, update.fee_max || ""),
            MinTrxAmount: handleEditAmount(update.fee_currency, update.min_trx_amount || ""),
            MaxTrxAmount: handleEditAmount(update.fee_currency, update.max_trx_amount || ""),
          })
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      } 
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = () => {
    if(mode === 1){
      // create service fees
      let obj = {
        ref_service_id: formData.ServiceId || "",
        fee_name: formData.FeeName || "",
        fee_percent: parseFloat(formData.FeePercent) || 0.00,
        fee_amount: handleCalculateAmount(update.FeeCurrency, formData.FeeAmount || 0),
        fee_currency: formData.FeeCurrency || "",
        fee_min: handleCalculateAmount(update.FeeCurrency, formData.FeeMin || 0),
        fee_max: handleCalculateAmount(update.FeeCurrency, formData.FeeMax || 0),
        min_trx_amount: handleCalculateAmount(update.FeeCurrency, formData.MinTrxAmount || 0) || 0,
        max_trx_amount: handleCalculateAmount(update.FeeCurrency, formData.MaxTrxAmount || 0) || 0,
      };
      dispatch(CreateServiceFeeBIZcore(tenantId, obj, setLoading)).then(() => {
        dispatch(GetServiceFeesBIZcore(tenantId, page+1, rowsPerPage, "", setLoading)).then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // update service fees
      let obj = {
        ref_service_id: formData.ServiceId || "",
        fee_name: formData.FeeName || "",
        fee_percent: parseFloat(formData.FeePercent) || 0.00,
        fee_amount: handleCalculateAmount(update.FeeCurrency, formData.FeeAmount || 0),
        fee_currency: formData.FeeCurrency || "",
        fee_min: handleCalculateAmount(update.FeeCurrency, formData.FeeMin || 0),
        fee_max: handleCalculateAmount(update.FeeCurrency, formData.FeeMax || 0),
        min_trx_amount: handleCalculateAmount(update.FeeCurrency, formData.MinTrxAmount || 0) || 0,
        max_trx_amount: handleCalculateAmount(update.FeeCurrency, formData.MaxTrxAmount || 0) || 0,
      };
      dispatch(UpdateServiceFeeBIZcore(tenantId, update.fee_id, obj, setLoading)).then(() => {
        dispatch(GetServiceFeesBIZcore(tenantId, page+1, rowsPerPage, "", setLoading)).then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Service Id"
                fullWidth
                name="ServiceId"
                value={formData.ServiceId}
                onChange={handleChange}
                inputProps={{ maxLength: 64 }}
                />
              <TextField 
                label="Fee name"
                fullWidth
                name="FeeName"
                value={formData.FeeName}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}
                />
            </Box>
            {/* <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Fee currency"
                fullWidth
                name="FeeCurrency"
                value={formData.FeeCurrency}
                onChange={handleChange}
                inputProps={{ maxLength: 3 }}
                />
            </Box> */}
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <AmountTextField 
                currencyName="FeeCurrency"
                currency={formData.FeeCurrency}
                onCurrencyChange={handleChange}
                label="Fee amount"
                fullWidth
                name="FeeAmount"
                value={formData.FeeAmount}
                onChange={handleChange}
                />
              <TextField 
                label="Fee percent"
                fullWidth
                name="FeePercent"
                value={formData.FeePercent}
                onChange={handleChange}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <AmountTextField 
                currencyName="FeeCurrency"
                currency={formData.FeeCurrency}
                onCurrencyChange={handleChange}
                label="Fee min"
                fullWidth
                name="FeeMin"
                value={formData.FeeMin}
                onChange={handleChange}
                />
              <AmountTextField 
                currencyName="FeeCurrency"
                currency={formData.FeeCurrency}
                onCurrencyChange={handleChange}
                label="Fee max"
                fullWidth
                name="FeeMax"
                value={formData.FeeMax}
                onChange={handleChange}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <AmountTextField 
                currencyName="FeeCurrency"
                currency={formData.FeeCurrency}
                onCurrencyChange={handleChange}
                label="Minimum transaction amount"
                fullWidth
                name="MinTrxAmount"
                value={formData.MinTrxAmount}
                onChange={handleChange}
                />
              <AmountTextField 
                currencyName="FeeCurrency"
                currency={formData.FeeCurrency}
                onCurrencyChange={handleChange}
                label="Maximum transaction amount"
                fullWidth
                name="MaxTrxAmount"
                value={formData.MaxTrxAmount}
                onChange={handleChange}
                />
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.ServiceId !== "" && formData.FeeName !== "" &&
                  formData.WhoPays !== "" && formData.FeeType !== ""
                )}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ServiceFeesModal;