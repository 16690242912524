import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal,  
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateIntegrationBIZsubs, 
  UpdateIntegrationBIZsubs 
} from '../../../../redux/actions/business/subscriptions/integrationsActions';
import { 
  GetTenantsBIZidentity 
} from '../../../../redux/actions/business/identity/tenantsActions';

import global from "../../../../global.scss";

const Data = {
  IntegWithSoftware: "",
  Tenant: ""
};

const IntegrationsModal = ({
  open, 
  setOpen, 
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.identity"]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const [tenantsList, setTenantsList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  };

  useEffect(() => {
    try {
      dispatch(GetTenantsBIZidentity(1, 1000, "", setLoading));
      if(open){
        if(update.defaultMode === 1){
          // mode = 1 - create integration
          setMode(1);
        }else if(update.defaultMode === 2){
          // mode = 2 - edit integration
          setMode(2);
          setFormData({
            ...formData,
            IntegWithSoftware: update.integ_with_software || "",
            Tenant:  update.ref_tenant_id || "",
          })
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      setTenantsList(state.tenantsList.records || []);
    } catch (err) {}
  }, [state]);

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = () => {
    if(mode === 1){
      // create integration
      let obj = {
        integ_with_software: formData.IntegWithSoftware || "",
        ref_tenant_id: formData.Tenant || ""
      };
      dispatch(CreateIntegrationBIZsubs(obj, setLoading)).then(() => {
        handleGet().then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // update integration
      let obj = {
        integ_with_software: formData.IntegWithSoftware || "",
        ref_tenant_id: formData.Tenant || ""
      };
      dispatch(UpdateIntegrationBIZsubs(update.id, obj, setLoading)).then(() => {
        handleGet().then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px" }}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Tenant"
                fullWidth
                name="Tenant"
                value={formData.Tenant}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  tenantsList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}>
                      {item.name}&nbsp;
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {item.id}
                      </Typography>
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Integration with software"
                fullWidth
                name="IntegWithSoftware"
                value={formData.IntegWithSoftware}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}
                />
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.DeliveryMethod !== "" && formData.DeliverBy !== "")}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default IntegrationsModal;