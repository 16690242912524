import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,   
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../components/utils/TableTimeStamps';
import TopBar from '../../../components/utils/TopBar';
import NA from '../../../components/utils/NA';

import { 
  GetUploadedFilesBIZ 
} from '../../../redux/actions/business/uploadedFilesActions';

const UploadedFilesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.business);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  
  const [uploadedFilesList, setUploadedFilesList] = useState([]);
  const [uploadedFilesTotal, setUploadedFilesTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "File name",
      sort: "file_name",
    },
    {
      label: "File path",
      sort: "file_path",
    },
    {
      label: "File size",
      sort: "file_size",
    },
    {
      label: "File type",
      sort: "file_type",
    },
    {
      label: "Uploaded by",
      sort: null,
    },
    {
      label: "Time stamps",
      sort: "createdAt",
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetUploadedFilesBIZ(page+1, rowsPerPage, query, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      console.log(state);
      formatUploadedFilesList(state.uploadedFilesList.records || []);
      setUploadedFilesTotal(state.uploadedFilesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatUploadedFilesList = (list) => {
    setUploadedFilesList(list);
  };


  return (
    <Box>

      <TopBar
        title={"Uploaded files"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        />
      
      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          uploadedFilesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No uploaded file found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                uploadedFilesList.map((row, index) => (
                  <TableRow 
                    key={index}
                    className="table__clickable-row">
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.file_name || <NA />}</TableCell>
                    <TableCell>{row.file_path || <NA />}</TableCell>    
                    <TableCell>{row.file_size || <NA />}</TableCell>
                    <TableCell>{row.file_type || <NA />}</TableCell>
                    <TableCell>{row.uploaded_by || <NA />}</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={uploadedFilesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default UploadedFilesPage;