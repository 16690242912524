import React from 'react';

import { 
  Box, 
  Button, 
  InputAdornment, 
  TextField, 
  Typography 
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

const TopBar = (props) => {
  return (
    <Box className="flexCenterSBRow"
      mb={"10px"}>
      <Typography variant='subtitle1'>
        {props.title}
      </Typography>

      <Box 
        className="flexCenter_Row" 
        gap={"15px"}>
        {
          (props.onSearchChange)
          &&
          <TextField
            placeholder='Search' 
            size='small'
            value={props.search}
            onChange={props.onSearchChange}
            InputProps={{
              startAdornment: 
                <InputAdornment position="start">
                  <SearchIcon 
                    sx={{ color: "#343434" }}/>
                </InputAdornment>,
            }}
            />
        }
        {
          (props.button2Text && props.button2OnClick)
          &&
          <Button 
            variant='contained'
            size='small'
            onClick={props.button2OnClick}>
            {props.button2Text}
          </Button>
        }
        {
          (props.button1Text && props.button1OnClick)
          &&
          <Button
            variant='contained'
            size='small'
            onClick={props.button1OnClick}>
            {props.button1Text}
          </Button>
        }
      </Box>
    </Box>
  )
}

export default TopBar;