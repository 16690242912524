import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../components/utils/TopBar';
import NA from '../../../../components/utils/NA';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  GetEmailTemplatesBIZnotifications 
} from '../../../../redux/actions/business/notifications/EmailTemplatesActions';

import global from "../../../../global.scss";

const EmailTemplatesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.notifications"]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const [emailTemplatesList, setEmailTemplatesList] = useState([]);
  const [emailTemplatesTotal, setEmailTemplatesTotal] = useState(0);  

  const [deleteId, setDeleteId] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [deleting, setDeleting] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Name",
      sort: "name",
    },
    {
      label: "Subject",
      sort: "subject",
    },
    {
      label: "Group",
      sort: "group",
    },
    {
      label: "Priority",
      sort: "priority",
    },
    {
      label: "From",
      sort: "from",
    },
    {
      label: "Cc",
      sort: "cc",
    },
    {
      label: "Bcc",
      sort: "bcc",
    },
    {
      label: "Time stamps",
      sort: "createdAt",
    },
    {
      label: "",
      sort: null,
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetEmailTemplatesBIZnotifications(page+1, rowsPerPage, query, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      console.log(state);
      formatEmailTemplatesList(state.emailTemplatesList.records || []);
      setEmailTemplatesTotal(state.emailTemplatesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatEmailTemplatesList = (list) => {
    setEmailTemplatesList(list)
  };

  // const handleDelete = () => {
  //   // setConfirmationModalOpen(false);
  //   dispatch(DeleteEmailTemplateBIZnotifications(deleteId, setDeleting)).then(() => {
  //     dispatch(GetEmailTemplatesBIZnotifications(page+1, rowsPerPage, setLoading));
  //   })
  // };

  return (
    <Box>

      <TopBar
        title={"Email Templates"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add Email Template"}
        button1OnClick={() => {

        }}
        />
      
      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          emailTemplatesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No email template found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                emailTemplatesList.map((row, index) => (
                  <TableRow 
                    key={index}
                    className="table__clickable-row">
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.name || <NA />}</TableCell>
                    <TableCell>{row.subject || <NA />}</TableCell>  
                    <TableCell>{row.group || <NA />}</TableCell>  
                    <TableCell>{row.priority || <NA />}</TableCell>
                    <TableCell>{row.from || <NA />}</TableCell>
                    <TableCell>{row.cc || <NA />}</TableCell>
                    <TableCell>{row.bcc || <NA />}</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                    <TableCell>
                      <Box
                        className="flexCenter_Row"
                        gap={"20px"}>
                        <Tooltip
                          title={"Edit email template: " + row.name}>
                          <IconButton
                            onClick={() => {
                              // setUpdateObj(row);
                              // setModalTitle("Edit Currency - " + row.CharacterCode);
                              // setCurrenciesModalOpen(!currenciesModalOpen);
                            }}>
                            <EditIcon sx={{ color: global["primary_light"] }}/>
                          </IconButton>
                        </Tooltip>
                        {
                          deleting && deleteId === row.id
                          ?
                          <CircularProgress size={24}/>
                          :
                          <Tooltip  
                            title={"Delete email template: " + row.name}>
                            <IconButton
                              onClick={() => {
                                setDeleteId(row.id);
                                // setModalTitle("Delete Currency - " + row.CharacterCode);
                                // setConfirmationModalOpen(!confirmationModalOpen);
                              }}>
                              <DeleteIcon color='error' />
                            </IconButton>
                          </Tooltip>
                        }
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[2, 5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={emailTemplatesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default EmailTemplatesPage;