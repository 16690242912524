const DateFormatter = (date) => {
  return (
    date 
    ? 
      new Date(date).toDateString().slice(4, 10) + ", " + new Date(date).getFullYear()
    :
      "NA"
  )
}

export default DateFormatter;