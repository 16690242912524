import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Chip, 
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow,  
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../components/utils/TableTimeStamps';
import TopBar from '../../../components/utils/TopBar';
import NA from '../../../components/utils/NA';


import { 
  GetInternalUsersCON 
} from '../../../redux/actions/consumer/internalUsersAction';

const InternalUsersPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.consumer);
  const [loading, setLoading] = useState(true);
  
  const [internalUsersList, setInternalUsersList] = useState([]);
  const [internalUsersTotal, setInternalUsersTotal] = useState(0);  

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  useEffect(() => {
    dispatch(GetInternalUsersCON(page+1, rowsPerPage, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    try { 
      formatInternalUsersList(state.internalUsersList.Records || []);
      setInternalUsersTotal(state.internalUsersList.Total || 0);
    } catch (err) {}
  }, [state]);

  // --------------------- list formatter ------------------------
  const formatInternalUsersList = (list) => {
    setInternalUsersList(list);
  }

  return (
    <Box width={"100%"}>
      <TopBar
        title={"Internal Users"}
        />
    
    {
      loading
      ?
      <Box height={"300px"} className="flexCenterCenterRow">
        <CircularProgress />
      </Box>
      :
        internalUsersList.length === 0
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <Typography>No intenal user found ( but how? )</Typography>
        </Box>
        :
        <Table className="block">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell> Time stamps</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              internalUsersList.map((row, index) => (
                <TableRow 
                  key={index}>
                  <TableCell>{row.Name || <NA />}</TableCell>
                  <TableCell>{row.Email || <NA />}</TableCell> 
                  <TableCell>
                    <Chip 
        
                      label={row.Status === true ? "Active" : "Inactive"}/>
                  </TableCell>
                  <TableCell>
                    <TableTimeStamps
                      createdAt={row.createdAt}
                      updatedAt={row.updatedAt}
                      />
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{ borderBottom: 0 }}
                 rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                colSpan={10}
                count={internalUsersTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                />
            </TableRow>
          </TableFooter>
        </Table>
    }
    </Box>
  )
}

export default InternalUsersPage;