import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Switch,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import useSelectedTenant from '../../../../../components/hooks/useSelectedTenant';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { 
  DeletePaymentFrequencyBIZcore, 
  GetPaymentFrequenciesBIZcore, 
  UpdatePaymentFrequencyBIZcore 
} from '../../../../../redux/actions/business/core/paymentFrequenciesActions';

import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import PaymentFrequenciesModal from './PaymentFrequenciesModal';

import global from "../../../../../global.scss";

const PaymentFrequenciesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.core"]);
  const tenantId = useSelectedTenant();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [paymentFrequenciesModalOpen, setPaymentFrequenciesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [paymentFrequenciesList, setPaymentFrequenciesList] = useState([]);
  const [paymentFrequenciesTotal, setPaymentFrequenciesTotal] = useState(0);

  const [paymentFrequenciesMenuOpen, setPaymentFrequenciesMenuOpen] = useState(false);
  const [paymentFrequenciesMenuAnchor, setPaymentFrequenciesMenuAnchor] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [deleting, setDeleting] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();


  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Frequency",
      sort: "pmt_frequency",
    },
    {
      label: "Is Recurring",
      sort: "is_recurring",
    },
    {
      label: "Active",
      sort: "is_active",
    },
    {
      label: "Time stamps",
      sort: "created_at",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    dispatch(GetPaymentFrequenciesBIZcore(tenantId, page+1, rowsPerPage, query, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatPaymentFrequenciesList(state.paymentFrequenciesList.records || []);
      setPaymentFrequenciesTotal(state.paymentFrequenciesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatPaymentFrequenciesList = (list) => {
    setPaymentFrequenciesList(list)
  };

  const handleSwitchBank = (id, pmtFrequency, bool) => {
    let obj = {
      ...pmtFrequency,
      is_active: bool,
    }
    dispatch(UpdatePaymentFrequencyBIZcore(tenantId, id, obj, setLoading)).then(() => {
      dispatch(GetPaymentFrequenciesBIZcore(tenantId, page+1, rowsPerPage, "", setLoading));
    })
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeletePaymentFrequencyBIZcore(tenantId, deleteId, setDeleting)).then(() => {
      let query = ""
      if(search) query += `&search=${search}`;
      if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
      dispatch(GetPaymentFrequenciesBIZcore(tenantId, page+1, rowsPerPage, query, setDeleting)).then(() => {
        setDeleteId(null);
      })
    });
  };

  return (
    <Box>

      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete} 
        />

      <PaymentFrequenciesModal 
        open={paymentFrequenciesModalOpen}
        setOpen={setPaymentFrequenciesModalOpen}
        title={modalTitle}
        update={update}
        page={page}
        rowsPerPage={rowsPerPage}
        />

      <TopBar
        title={"Payment frequencies"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add Payment frequency"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add Payment frequency");
          setPaymentFrequenciesModalOpen(!paymentFrequenciesModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          paymentFrequenciesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No payment frequencies found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                paymentFrequenciesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.pmt_frequency || <NA />}</TableCell>
                    <TableCell>{row.is_recurring ? "Yes" : "No"}</TableCell>
                    <TableCell>
                      <Switch 
                        checked={row.is_active}
                        onChange={(e) => {
                          handleSwitchBank(row.id, row, e.target.checked)
                        }}
                        />
                    </TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.created_at}
                        updatedAt={row.updated_at}
                        />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          setPaymentFrequenciesMenuAnchor(e.currentTarget);
                          setPaymentFrequenciesMenuOpen(true);
                          setUpdate(row);
                          setModalTitle(row.pmt_frequency || "");
                        }}>
                        <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={paymentFrequenciesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={paymentFrequenciesMenuOpen}
        anchorEl={paymentFrequenciesMenuAnchor}
        onClose={() => setPaymentFrequenciesMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setPaymentFrequenciesMenuOpen(false);
            setPaymentFrequenciesModalOpen(!paymentFrequenciesModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteId(update.id);
            setPaymentFrequenciesMenuOpen(false);
            setDeleteConfirmationModalOpen(true);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default PaymentFrequenciesPage;