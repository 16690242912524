import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import useSelectedTenant from '../../../../../components/hooks/useSelectedTenant';
import LoadingButton from '../../../../../components/buttons/Button';

import {
  CreateDeliveryMethodBIZcore, 
  GetDeliveryMethodsBIZcore, 
  UpdateDeliveryMethodBIZcore 
} from '../../../../../redux/actions/business/core/deliveryMethodsActions';

import global from "../../../../../global.scss";

const Data = {
  DeliveryMethod: "",
  DeliveredBy: "",
  ServiceCode: "",
  DeliverySpeed: 0
};

const deliveryMethodsList = [
  {
    label: "Standard ACH",
    value : "STANDARD_ACH"
  },
  {
    label: "Same day ACH",
    value : "SAME_DAY_ACH"
  },
  {
    label: "Next day ACH",
    value : "NEXT_DAY_ACH"
  },
  {
    label: "Instant Transfer",
    value : "INSTANT_TRANSFER"
  },
  {
    label: "Domestic Wire",
    value : "DOMESTIC_WIRE"
  },
  {
    label: "Card",
    value : "CARD"
  }
];

const deliveredByList = [
  {
    label: "A2A",
    value : "A2A"
  },
  {
    label: "Email",
    value : "EMAIl"
  },
  {
    label: "Payment Gateway",
    value : "PAYMENT_GATEWAY"
  },
];

const DeliveryMethodsModal = ({
  open, 
  setOpen, 
  title,
  update,
  page, 
  rowsPerPage
}) => {
  const dispatch = useDispatch();
  const tenantId = useSelectedTenant();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  }

  useEffect(() => {
    try {
      if(open){
        if(update.defaultMode === 1){
          // mode = 1 - create delivery method
          setMode(1);
        }else if(update.defaultMode === 2){
          // mode = 2 - edit delivery method
          setMode(2);
          setFormData({
            ...formData,
            DeliveryMethod: update.delivery_method || "",
            DeliveredBy: update.delivered_by || "",
            ServiceCode:  update.ref_sevice_code || "",
            DeliverySpeed:  update.delivery_speed || 0,
          })
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      } 
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = () => {
    if(mode === 1){
      // create delivery method
      let obj = {
        delivery_method: formData.DeliveryMethod || "",
        delivered_by: formData.DeliveredBy || "",
        ref_sevice_code: formData.ServiceCode || "",
        delivery_speed: formData.DeliverySpeed || 0,
      };
      dispatch(CreateDeliveryMethodBIZcore(tenantId, obj, setLoading)).then(() => {
        dispatch(GetDeliveryMethodsBIZcore(tenantId, page+1, rowsPerPage, "", setLoading)).then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // update delivery method
      let obj = {
        delivery_method: formData.DeliveryMethod || "",
        delivered_by: formData.DeliveredBy || "",
        ref_sevice_code: formData.ServiceCode || "",
        delivery_speed: formData.DeliverySpeed || 0,
      };
      dispatch(UpdateDeliveryMethodBIZcore(tenantId, update.id, obj, setLoading)).then(() => {
        dispatch(GetDeliveryMethodsBIZcore(tenantId, page+1, rowsPerPage, "", setLoading)).then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Delivery method"
                fullWidth
                name="DeliveryMethod"
                value={formData.DeliveryMethod}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  deliveryMethodsList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                }
              </TextField>
              <TextField 
                select
                label="Delivered by"
                fullWidth
                name="DeliveredBy"
                value={formData.DeliveredBy}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  deliveredByList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="ServiceCode"
                fullWidth
                name="ServiceCode"
                value={formData.ServiceCode}
                onChange={handleChange}
                inputProps={{ maxLength: 64 }}
                />
              <TextField 
                label="DeliverySpeed"
                fullWidth
                name="DeliverySpeed"
                type='number'
                value={formData.DeliverySpeed}
                onChange={handleChange}
                />
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.DeliveryMethod !== "" && formData.DeliveredBy !== "")}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeliveryMethodsModal;