import React from 'react';
import { useNavigate } from 'react-router-dom';

import { 
  Box, 
  Grid,
  Typography 
} from '@mui/material';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import HubIcon from '@mui/icons-material/Hub';
import PhishingIcon from '@mui/icons-material/Phishing';

import "./styles.scss";

export const moduleList = [
  {
    name: "Identity - MS",
    route: "/business/identity",
    icon: <FingerprintIcon 
            style={{ width: "50px", height: "50px" }}
            />
  },
  {
    name: "Core - MS",
    route: "/business/core",
    icon: <MemoryOutlinedIcon 
            style={{ width: "50px", height: "50px" }}
            />
  },
  {
    name: "Fusion - MS",
    route: "/business/fusion",
    icon: <HubIcon 
            style={{ width: "50px", height: "50px" }}
            />
  },
  {
    name: "Webhook - MS",
    route: "/business/webhook",
    icon: <PhishingIcon 
            style={{ width: "50px", height: "50px" }}
            />
  },
  {
    name: "Products & Subs",
    route: "/business/subscriptions",
    icon: <CategoryOutlinedIcon 
            style={{ width: "50px", height: "50px" }}
            />
  },
  {
    name: "Notifications",
    route: "/business/notifications/email-templates",
    icon: <NotificationsNoneIcon 
            style={{ width: "50px", height: "50px" }}
            />
  }
];


const HomePage = () => {
  const navigate = useNavigate();

  return (
    <Box
      className="business flexCenterCenterColumn"
      gap={"40px"}>
      <Grid
        container
        spacing={2}
        className="business__module">
        {
          moduleList.map((module, moduleIndex) => (
            <Grid
              key={moduleIndex}
              item
              xs={12} sm={12} md={4} lg={4}>
              <Box
                className="block flexCenterCenterColumn business__module-item"
                gap={"15px"}
                onClick={() => {
                  navigate(module.route)
                }}>
                {module.icon}
                <Typography
                  variant='subtitle1'>
                  {module.name}
                </Typography>
              </Box>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  )
}

export default HomePage