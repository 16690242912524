import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal, 
  Switch, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../../../../components/buttons/Button';

import { 
  CreateWebhookBIZfusionORUM, 
  UpdateWebhookBIZfusionORUM 
} from '../../../../../redux/actions/business/fusion/orum/webhooksActions';

import global from "../../../../../global.scss";

const Data = {
  Enabled: true,
  EventType: "",
  Url: ""
};

const eventTypesList = [
  {
    label: "subscribe_all",
    value: "subscribe_all"
  }
]

const WebhooksModal = ({
  open, 
  setOpen, 
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  }

  useEffect(() => {
    try {
      if(open){
        if(update.defaultMode === 1){
          // mode = 1 - create webhook
          setMode(1);
        } else if(update.defaultMode === 2){
          // mode = 2 - edit webhook
          setMode(2);
          setFormData({
            ...formData,
            Enabled: update?.enabled || false,
            EventType: update?.event_types[0] || "",
            Url: update?.url || ""
          });
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if(mode === 1){
      // create webhook
      let obj = {
        "enabled": formData.Enabled,
        "event_types": [
          formData.EventType
        ],
        "url": formData.Url
      };
      dispatch(CreateWebhookBIZfusionORUM(obj, setLoading)).then(() => {
        handleGet(setLoading).then(() => {
          handleClose();
        })
      })

    } else if(mode === 2){
      // update webhook
      let obj = {
        "enabled": formData.Enabled,
        "event_types": [
          formData.EventType
        ],
        "url": formData.Url
      };
      dispatch(UpdateWebhookBIZfusionORUM(update.id, obj, setLoading)).then(() => {
        handleGet(setLoading).then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Event type"
                fullWidth
                name="EventType"
                value={formData.EventType}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  eventTypesList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Url"
                fullWidth
                name="Url"
                value={formData.Url}
                onChange={handleChange}
                inputProps={{ maxLength: 128 }}
                />
            </Box>
            <Box 
              className="flexCenter_Row"
              gap={"15px"}
              mb={"15px"}>
              <Switch 
                checked={formData.Enabled}
                onChange={(e) => {
                  handleChange({ target: { name: "Enabled", value: e.target.checked} })
                }}
                />
              <Typography>
                Enabled
              </Typography>
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.Url !== "" && formData.EventType !== "" )}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default WebhooksModal;