import React from 'react';

import "./styles.scss";

const SDKNavigation = ({
  path, 
  currentPage, 
  navigate
}) => {
  return (
    <></>
  );
}

export default SDKNavigation