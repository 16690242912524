import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

import AuthReducers from "./authReducers";
import WatchTowerReducer from "./watchTowerReducers";

// MODULE - CONSUMER
import ConsumerReducer from "./consumerReducers";
// SUBMODULE - CONSUMER > PAYMENTS
import ConsumerPaymentReducer from "./consumer[payments]Reducers";
// SUBMODULE - CONSUMER > VIRTUAL_CARDS
import ConsumerVirtualCardsReducer from "./consumer[virtualCards]Reducers";

// MODULE - BUSINESS
import BusinessReducer from "./businessReducers";
// MODULE - BUSINESS > NOTIFICATIONS
import BusinessNotificationsReducer from "./business[notifications]Reducers";
// MODULE - BUSINESS > SUBSCRIPTIONS
import BusinessSubscriptionsReducer from "./business[subscriptions]Reducers";
// MODULE - BUSINESS > IDENTITY
import BusinessIdentityReducer from "./business[identity]Reducers";
// MODULE - BUSINESS > WEBHOOK
import BusinessWebhookReducer from "./business[webhook]Reducers";
// MODULE - BUSINESS > FUSION
import BusinessFusionReducer from "./business[fusion]Reducers";
// MODULE - BUSINESS > FUSION > ORUM
import BusinessFusionOrumReducer from "./business[fusion][orum]Reducers";
// MODULE - BUSINESS > FUSION > CC
import BusinessFusionCCReducer from "./business[fusion][cc]Reducers";
// MODULE - BUSINESS > CORE
import BusinessCoreReducer from "./business[core]Reducers";

// MODULE - SUPPORT
import SupportReducer from "./supportReducers";
// SUBMODULE - SUPPORT > CONFIG
import SupportConfigReducer from "./support[config]Reducers";

import MiscellaneousReducer from "./miscellaneousReducers";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: AuthReducers,
  watchTower: WatchTowerReducer,

  consumer: ConsumerReducer,
  "consumer.payments": ConsumerPaymentReducer,
  "consumer.vcn": ConsumerVirtualCardsReducer,

  business: BusinessReducer,
  "business.notifications": BusinessNotificationsReducer,
  "business.subscriptions": BusinessSubscriptionsReducer, 
  "business.identity": BusinessIdentityReducer,
  "business.webhook": BusinessWebhookReducer,
  "business.fusion": BusinessFusionReducer,
  "business.fusion.orum": BusinessFusionOrumReducer,
  "business.fusion.cc": BusinessFusionCCReducer,
  "business.core": BusinessCoreReducer,

  support: SupportReducer,
  "support.config": SupportConfigReducer,
  
  other: MiscellaneousReducer
});

export default persistReducer(persistConfig, rootReducer);