import React from 'react';

import { 
  Box,
} from '@mui/material';

import TopBar from '../../../../components/utils/TopBar';

const HomePage = () => {
  return (
    <Box>
      <TopBar
        title={"Description"} 
        />

        
    </Box>
  )
}

export default HomePage