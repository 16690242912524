import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import Copyable from '../../../../../components/utils/Copyable';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';

import { 
  CLEAR_ACCOUNT_BIZ_FUSION_CC,
  SELECT_ACCOUNT_BIZ_FUSION_CC
} from '../../../../../redux/actionTypes';

import { 
  GetAccountsBIZfusionCC 
} from '../../../../../redux/actions/business/fusion/cc/accountsActions';
import { 
  GetContactsBIZfusionCC 
} from '../../../../../redux/actions/business/fusion/cc/contactsActions';

import global from "../../../../../global.scss";

const AccountsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(state => state["business.fusion.cc"]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const [accountsList, setAccountsList] = useState([]);
  const [accountsTotal, setAccountsTotal] = useState(0);

  const [selecting, setSeleting] = useState(false);
  const [selectId, setSelectId] = useState(null);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id | Short ref",
      sort: null,
    },
    {
      label: "Account name",
      sort: null,
    },
    {
      label: "Brand",
      sort: null,
    },
    {
      label: "Status",
      sort: null,
    }, 
    {
      label: "Address",
      sort: null,
    },
    {
      label: "Spread table",
      sort: null,
    },
    {
      label: "Legal entity type",
      sort: null,
    },
    {
      label: "Identification",
      sort: null,
    },
    {
      label: "Time stamps",
      sort: null,
    },
    {
      label: "",
      sort: null,
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
		handleGetAccountsList();
    dispatch({ type: CLEAR_ACCOUNT_BIZ_FUSION_CC });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sort, sortDirection]);

  useEffect(() => {
    try {
      formatAccountsList(state.accountsList.records || []);
      setAccountsTotal(state.accountsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatAccountsList = (list) => {
    setAccountsList(list);
  };

	async function handleGetAccountsList(customLoading) {
    let query = ""
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetAccountsBIZfusionCC(page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
	};

  function handleSelectAccount(row){
    setSelectId(row.id);

    let query = "";
    query +=`&status=enabled`;
    query +=`&account_id=${row.id}`;
    dispatch(GetContactsBIZfusionCC(1, 10, query, setSeleting)).then(({res, statusCode}) => {
      if(statusCode === 200 && res.data.total > 0){
        let obj = {
          ...row, 
          contactId: res.data.records[0].id
        };
        dispatch({ type: SELECT_ACCOUNT_BIZ_FUSION_CC, payload: obj });
        navigate("beneficiaries");
      } else {
        toast.error("No valid contacts found");
        setSelectId(null);
      }

    });
  };

  return (
    <Box>

      <TopBar
        title={"Accounts"}
        button1Text={"Add Account"}
        button1OnClick={() => {

        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          accountsList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No account found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                accountsList.map((row, index) => (
                  <TableRow 
                    key={index}
                    className='table__clickable-row'
                    onClick={
                      selectId === null
                      ? () => { handleSelectAccount(row) }
                      : () => {}
                      }>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
												<Copyable>
                        	{row.id}
												</Copyable>
                      </Typography>
                      <Tooltip
                        title="Short reference">
                        <Typography
                          variant='caption'
                          color={"grey"}>
                          <Copyable>
                            {row.shortReference}
                          </Copyable>
                        </Typography>
                      </Tooltip>
                    </TableCell>
										<TableCell>
											<Typography
												variant='caption'>
												<Copyable>
													{row.accountName || <NA />}
												</Copyable>
											</Typography>
                      <Tooltip
                        title="Your reference">
                        <Typography
                          variant='caption'
                          color={"grey"}>
                          <Copyable>
                            ({row.yourReference || <NA />})
                          </Copyable>
                        </Typography>
                      </Tooltip>
										</TableCell>
										<TableCell>
                      {row.brand || <NA />}
										</TableCell>
                    <TableCell>
                      {row.status || <NA />}
										</TableCell>
                    <TableCell>
                      {row.street + ", " + row.city + ", " + row.stateOrProvince +  ", " + 
                      row.country + ", " + row.postalCode|| <NA />}
										</TableCell>
                    <TableCell>
                      {row.spreadTable || <NA />}
										</TableCell>
                    <TableCell>
                      {row.legalEntityType || <NA />}
										</TableCell>
                    <TableCell>
											<Typography
												variant='caption'>
												<Copyable>
													{row.identificationValue || <NA />}
												</Copyable>
											</Typography>
                      <Typography
												variant='caption'
                        color={"grey"}>
												<Copyable>
													({row.identificationType || <NA />})
												</Copyable>
											</Typography>
										</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                    <TableCell>
                      {
                        selecting && row.id === selectId
                        ?
                        <CircularProgress 
                          size={24}
                          sx={{ ml: "10px" }}
                          />
                        :
                          selectId === null
                          &&
                          <IconButton>
                            <LoginOutlinedIcon sx={{ color: global['secondary_light'] }}/>
                          </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                   rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={accountsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default AccountsPage;