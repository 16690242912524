import * as actionTypes from "../actionTypes.js";

const initialState = {
  categoriesList: null,
  stagesList: null
};

const supportConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORIES_SPT_CONFIG:
      return { ...state, categoriesList: action.payload };
    case actionTypes.GET_STAGES_SPT_CONFIG:
      return { ...state, stagesList: action.payload };

    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default supportConfigReducer;