import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import useSelectedCompany from '../../../../../components/hooks/useSelectedCompany';
import LoadingButton from '../../../../../components/buttons/Button';

import { 
  CreateEmployeeBIZcore, 
  GetEmployeesBIZcore, 
  UpdateEmployeeBIZcore 
} from '../../../../../redux/actions/business/core/employeesActions';

import global from "../../../../../global.scss";

const Data = {
  Id: "",
  Name: "",
  Role: "",
  Email: "",
  CCContactId: ""
};

const rolesList = [
  {
    value: "Admin",
    label: "Admin"
  },
  {
    value: "Contributor",
    label: "Contributor",
  },
  {
    value: "Accountant",
    label: "Accountant"
  }
];

const EmployeesModal = ({
  open, 
  setOpen, 
  title,
  update,
  page, 
  rowsPerPage
}) => {
  const dispatch = useDispatch();
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  }

  useEffect(() => {
    try {
      if(open){
        if(update.defaultMode === 1){
          // mode = 1 - create employee
          setMode(1);
        }else if(update.defaultMode === 2){
          // mode = 2 - edit employee
          setMode(2);
          setFormData({
            ...formData,
            Id: update.employee_id || "",
            Name: update.employee_name || "",
            Role: update.employee_role || "",
            Email: update.employee_email || "",
            CCContactId: update.cc_contact_id || "",
          })
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if(mode === 1){
      // create employee
      let obj = {
        employee_id: formData.Id || "",
        employee_name: formData.Name || "",
        employee_role: formData.Role || "",
        employee_email: formData.Email || "",
        cc_contact_id: formData.CCContactId || "",
      };
      dispatch(CreateEmployeeBIZcore(companyId, obj, setLoading)).then(() => {
        dispatch(GetEmployeesBIZcore(companyId, page+1, rowsPerPage, "", setLoading)).then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // update employee
      let obj = {
        employee_name: formData.Name || "",
        employee_role: formData.Role || "",
        employee_email: formData.Email || "",
        cc_contact_id: formData.CCContactId || "",
      };
      dispatch(UpdateEmployeeBIZcore(companyId, update.employee_id, obj, setLoading)).then(() => {
        dispatch(GetEmployeesBIZcore(companyId, page+1, rowsPerPage, "", setLoading)).then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            {
              mode === 1
              &&
              <Box 
                className="flexCenterSBRow"
                gap={"15px"}
                mb={"15px"}>
                <TextField 
                  label="GuId"
                  fullWidth
                  name="Id"
                  value={formData.Id}
                  onChange={handleChange}
                  inputProps={{ maxLength: 40 }}
                  />
              </Box>
            }
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Name"
                fullWidth
                name="Name"
                value={formData.Name}
                onChange={handleChange}
                inputProps={{ maxLength: 100 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Email"
                fullWidth
                name="Email"
                value={formData.Email}
                onChange={handleChange}
                inputProps={{ maxLength: 100 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Role"
                fullWidth
                name="Role"
                value={formData.Role}
                onChange={handleChange}
                inputProps={{ maxLength: 100 }}>
                {
                  rolesList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="CC Contact Id"
                fullWidth
                name="CCContactId"
                value={formData.CCContactId}
                onChange={handleChange}
                inputProps={{ maxLength: 40 }}
                />
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.Name !== "" && formData.Id !== "" && 
                  formData.Email !== "" && formData.Role !== "")}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default EmployeesModal;