import React from 'react';

import { 
  Box,
  Typography
} from '@mui/material';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';

import "../styles.scss";

const Identity = ({
  navigate,
  currentPage
}) => {
  return (
    <Box className="nav__side-common" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/identity");
          }}>
          <HomeOutlinedIcon />
          <Typography variant='body2'>
            Home Page
          </Typography>
        </Box>
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/identity/tenants");
          }}>
          <ApartmentOutlinedIcon />
          <Typography variant='body2'>
            Tenants
          </Typography>
        </Box>
        <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/identity/companies");
          }}>
          <BusinessOutlinedIcon />
          <Typography variant='body2'>
            Companies
          </Typography>
        </Box>
        <Box className={`${currentPage === 3 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/identity/branches");
          }}>
          <StoreOutlinedIcon />
          <Typography variant='body2'>
            Branches
          </Typography>
        </Box>
        <Box className={`${currentPage === 4 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/identity/users");
          }}>
          <PeopleAltOutlinedIcon />
          <Typography variant='body2'>
            Users
          </Typography>
        </Box>
        <Box className={`${currentPage === 5 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/identity/company-employees");
          }}>
          <BadgeOutlinedIcon />
          <Typography variant='body2'>
            Company Employees
          </Typography>
        </Box>
      </Box>
    </Box>  
  )
}

export default Identity;