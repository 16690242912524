import * as actionTypes from "../../actionTypes";
import * as api from "../../../api/index";
import { toast } from 'react-toastify';

export const GetContactRequestsWT = (page, limit, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getContactRequestsWT(page, limit);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_CONTACT_REQUESTS_WT, payload: data.data });
    }else{
      toast.error(data.message);
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const UpdateContactRequestsWT = (id, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data } = await api.updateContactRequestsWT(id);
    if (data.error === false) {
      toast.success(data.message);
    }else{
      toast.error(data.message);
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};