import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import useSelectedTenant from '../../../../../components/hooks/useSelectedTenant';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import Copyable from '../../../../../components/utils/Copyable';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { 
    GetTenantBankAccountsBIZcore 
} from '../../../../../redux/actions/business/core/tenantBankAccountsActions';

import TenantBankAccountsModal from './TenantBankAccountsModal';

import global from "../../../../../global.scss";

const TenantBankAccountsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.core"]);
  const tenantId = useSelectedTenant();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [tenantBankAccountsModalOpen, setTenantBankAccountsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [tenantBankAccountsList, setTenantBankAccountsList] = useState([]);
  const [tenantBankAccountsTotal, setTenantBankAccountsTotal] = useState(0);

  const [tenantBankAccountsMenuOpen, setTenantBankAccountsMenuOpen] = useState(false);
  const [tenantBankAccountsMenuAnchor, setTenantBankAccountsMenuAnchor] = useState(null);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "ACH Routing",
      sort: "ach_routing",
    },
    {
      label: "Account no.",
      sort: "account_number",
    },
    {
      label: "Bank > Type",
      sort: "ref_bank_id",
    },
    {
      label: "Is Default",
      sort: "is_default",
    },
    {
      label: "Orum Id",
      sort: "orum_id",
    },
    {
      label: "Time stamps",
      sort: "created_at",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetTenantBankAccountsBIZcore();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatTenantBankAccountsList(state.tenantBankAccountsList.records || []);
      setTenantBankAccountsTotal(state.tenantBankAccountsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatTenantBankAccountsList = (list) => {
    setTenantBankAccountsList(list)
  };

  async function handleGetTenantBankAccountsBIZcore(customeLoading) {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    dispatch(GetTenantBankAccountsBIZcore(tenantId, page+1, rowsPerPage, query, customeLoading ? customeLoading : setLoading));
  };

  return (
    <Box>

      <TenantBankAccountsModal 
        open={tenantBankAccountsModalOpen}
        setOpen={setTenantBankAccountsModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetTenantBankAccountsBIZcore}
        />

      <TopBar
        title={"Tenant Bank Accounts"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
				button1Text={"Add Bank account"}
				button1OnClick={() => {
					setUpdate({
						defaultMode: 1
					});
					setModalTitle("Add bank account");
					setTenantBankAccountsModalOpen(!tenantBankAccountsModalOpen);
				}}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
					tenantBankAccountsList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No bank account found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                tenantBankAccountsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        <Copyable>
                          {row.id || <NA />}
                        </Copyable>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Copyable>
                        {row.ach_routing || <NA />}
                      </Copyable>
                    </TableCell>
                    <TableCell>
                      <Copyable>
                        {row.account_number || <NA />}
                      </Copyable>
                    </TableCell>
                    <TableCell>
                      {row?.ref_bank_id?.bank || <NA />}&nbsp;{">"}&nbsp;
                      {row?.account_type_id?.type || <NA />}
                    </TableCell>
                    <TableCell>
                      {row?.is_default === true ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      <Copyable>
                        {row?.orum_id || <NA />}
                      </Copyable>
                    </TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.created_at}
                        updatedAt={row.updated_at}
                        />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          setTenantBankAccountsMenuAnchor(e.currentTarget);
                          setTenantBankAccountsMenuOpen(true);
                          setUpdate(row);
                          setModalTitle(row.account_number || "");
                        }}>
                        <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={tenantBankAccountsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }

      <Menu
        open={tenantBankAccountsMenuOpen}
        anchorEl={tenantBankAccountsMenuAnchor}
        onClose={() => setTenantBankAccountsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setTenantBankAccountsMenuOpen(false);
            setTenantBankAccountsModalOpen(!tenantBankAccountsModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default TenantBankAccountsPage;