import React from 'react';

import { 
  Box,
} from '@mui/material';

import TopBar from '../../../../components/utils/TopBar';

const ConsumerPage = () => {
  return (
    <Box>
      <TopBar 
        title={"Consumer"}
        />
    </Box>
  )
}

export default ConsumerPage