import React from 'react';

import { 
  Box, 
  Typography
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

import "../styles.scss";

const CoreGlobal = ({
  navigate, 
  currentPage
}) => {
  return (
    <Box className="nav__side-common flex__Column" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business/core");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/currencies");
          }}>
          <AttachMoneyOutlinedIcon />
          <Typography variant='body2'>
            Currencies
          </Typography>
        </Box>
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/industries");
          }}>
          <FactoryOutlinedIcon />
          <Typography variant='body2'>
            Industries
          </Typography>
        </Box>
        <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/account-types");
          }}>
          <CategoryOutlinedIcon />
          <Typography variant='body2'>
            Account types
          </Typography>
        </Box>
        <Box className={`${currentPage === 3 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/chart-of-accounts");
          }}>
          <AccountTreeOutlinedIcon />
          <Typography variant='body2'>
            Chart of accounts [ROOT]
          </Typography>
        </Box>
      </Box>
      {/* <Typography
        mt={"12px"}
        mb={"-5px"}>
        <i>Expense</i>
      </Typography>
      <Box className="nav__side-container">
        <Box className={`${currentPage === 3 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/payment-methods");
          }}>
          <AttachMoneyOutlinedIcon />
          <Typography variant='body2'>
            Payment methods
          </Typography>
        </Box>
      </Box> */}
    </Box>
  )
}

export default CoreGlobal;