import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import useOnBehalfOfAccount from '../../../../../components/hooks/useOnBehalfOfAccount';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import Copyable from '../../../../../components/utils/Copyable';
import TopBar from '../../../../../components/utils/TopBar';

import { 
  GetTransfersBIZfusionCC 
} from '../../../../../redux/actions/business/fusion/cc/transfersActions';

const TransferPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.fusion.cc"]);
  const [loading, setLoading] = useState(true);
  const contactId = useOnBehalfOfAccount();
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const [transfersList, setTransfersList] = useState([]);
  const [transfersTotal, setTransfersTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

//   {
//     "id": "168d2bcf-6724-46e4-bb18-8562953bb6ef",
//     "shortReference": "BT-20241126-CFYZKW",
//     "sourceAccountId": "9b58e4a9-941b-4c18-9259-a4e7826bc92b",
//     "destinationAccountId": "a228a31a-97ed-41a7-af79-36922dbc8863",
//     "currency": "CAD",
//     "amount": "200.00",
//     "status": "pending",
//     "reason": "",
//     "createdAt": "2024-11-26T04:29:33+00:00",
//     "updatedAt": "2024-11-26T04:29:33+00:00",
//     "completedAt": "",
//     "creatorAccountId": "a228a31a-97ed-41a7-af79-36922dbc8863",
//     "creatorContactId": "236aef39-41af-4d15-b5c8-27e264a2deb4",
//     "uniqueRequestId": ""
// }

  const tableHeadingsList = [
    {
      label: "Id",
      sort: null,
    },
    {
      label: "Name",
      sort: null,
    },
    {
      label: "Payment types",
      sort: null,
    },
    {
      label: "Beneficiary address",
      sort: null,
    }, 
    {
      label: "Currency",
      sort: null,
    },
    {
      label: "Bic swift",
      sort: null,
    },
    {
      label: "Iban",
      sort: null,
    },
    {
      label: "Time stamps",
      sort: null,
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
		handleGetTransfersList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sort, sortDirection]);

  useEffect(() => {
    try {
      formatTransfersList(state.transfersList.records || []);
      setTransfersTotal(state.transfersList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatTransfersList = (list) => {
    setTransfersList(list);
  };

	async function handleGetTransfersList(customLoading) {
    let query = ""
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    query += `&on_behalf_of=${contactId}`;
    dispatch(GetTransfersBIZfusionCC(page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
	};

  return (
    <Box>

      <TopBar
        title={"Transfers"}
        button1Text={"Add Transfer"}
        button1OnClick={() => {

        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          transfersList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No transfer found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                transfersList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
												<Copyable>
                        	{row.id}
												</Copyable>
                      </Typography>
                    </TableCell>
										{/* <TableCell>
											<Typography
												variant='caption'>
												<Copyable>
													{row.bankAccountHolderName || <NA />}
												</Copyable>
											</Typography>
                      <Tooltip
                        title="Name">
                        <Typography
                          variant='caption'
                          color={"grey"}>
                          <Copyable>
                            ({row.name || <NA />})
                          </Copyable>
                        </Typography>
                      </Tooltip>
										</TableCell>
										<TableCell>
                      {row.paymentTypes.join(", ") || <NA />}
										</TableCell>
                    <TableCell>
                      {row.beneficiaryAddress.join(", ") + ", " + row.bankCountry + ", " + row.stateOrProvince +  ", " + 
                      row.country + ", " + row.postalCode|| <NA />}
										</TableCell>
                    <TableCell>
                      {row.currency || <NA />}
										</TableCell>
                    <TableCell>
                      {row.bicSwift || <NA />}
										</TableCell>
                    <TableCell>
											<Typography
												variant='caption'>
												<Copyable>
													{row.iban || <NA />}
												</Copyable>
											</Typography>
										</TableCell> */}
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                   rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={transfersTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default TransferPage;