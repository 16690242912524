import React from 'react';

import { 
  Box, 
  Typography
} from '@mui/material';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';

import "../styles.scss";

const Subscriptions = ({
  navigate,
  currentPage
}) => {
  return (
    <Box className="nav__side-common" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/subscriptions");
          }}>
          <HomeOutlinedIcon />
          <Typography variant='body2'>
            Home
          </Typography>
        </Box>
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/subscriptions/products");
          }}>
          <RocketLaunchOutlinedIcon />
          <Typography variant='body2'>
            Products
          </Typography>
        </Box>
        <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/subscriptions/delivery-methods");
          }}>
          <DeliveryDiningOutlinedIcon />
          <Typography variant='body2'>
            Delivery Methods
          </Typography>
        </Box>
      </Box>
      <Typography
        mt={"12px"}
        mb={"-5px"}>
        <i>Tenant</i>
      </Typography>
      <Box className="nav__side-container">
        <Box className={`${currentPage === 3 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/subscriptions/integrations");
          }}>
          <PrecisionManufacturingIcon />
          <Typography variant='body2'>
            Integrations
          </Typography>
        </Box>
        <Box className={`${currentPage === 4 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/subscriptions/subscription-plans");
          }}>
          <SubscriptionsOutlinedIcon />
          <Typography variant='body2'>
            Subscription Plans
          </Typography>
        </Box>
      </Box>
      <Box className="nav__side-container">
        <Box className={`${currentPage === 5 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/subscriptions/services");
          }}>
          <MiscellaneousServicesOutlinedIcon />
          <Typography variant='body2'>
            Serivces
          </Typography>
        </Box>
        <Box className={`${currentPage === 6 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/subscriptions/plan-addons");
          }}>
          <DashboardCustomizeOutlinedIcon />
          <Typography variant='body2'>
            Plan addons
          </Typography>
        </Box>
      </Box>
      <Typography
        mt={"12px"}
        mb={"-5px"}>
        <i>Company</i>
      </Typography>
      <Box className="nav__side-container">
        <Box className={`${currentPage === 7 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/subscriptions/company-plan-addons");
          }}>
          <DashboardCustomizeOutlinedIcon />
          <Typography variant='body2'>
            Company Plan addons
          </Typography>
        </Box>
        <Box className={`${currentPage === 8 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/subscriptions/disallowed-services");
          }}>
          <BlockOutlinedIcon />
          <Typography variant='body2'>
            Disallowed Services
          </Typography>
        </Box>
      </Box>
    </Box>  
  )
}

export default Subscriptions