const useCurrencyService = () => {
  const commas = (str) => {
    return str?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function convertIntAmountToFloatAmount(amount, multiplier) {
    return (amount / Math.pow(10, multiplier)).toFixed(multiplier || 2);
  };

  function handleShowAmount(code, amount){
    try {
      let item;
      amount = parseInt(amount);

      if( code !== "" || code !== null || code !== undefined ){
        if(localStorage.getItem("CurrencyList") === null){
          return code + " " + commas(convertIntAmountToFloatAmount(amount, 2));
        }else{
          let list = JSON.parse(localStorage.getItem("CurrencyList"));
          item = list.find(i => i.code === code);
          if(item){
            return (item.symbol === null || item.symbol === undefined) 
              ? code + " " + commas(convertIntAmountToFloatAmount(amount, 2))
              : item.symbol + commas(convertIntAmountToFloatAmount(amount, item.decimalPosition || 2));
          }else{
            return code + " " + commas(convertIntAmountToFloatAmount(amount, 2));
          }
        }
      }else{
        return code + " " + commas(convertIntAmountToFloatAmount(amount, 2));
      }
    } catch (err) {
      return code + " " + commas(convertIntAmountToFloatAmount(amount, 2));
    }
  };

  function handleEditAmount(code, amount){
    try {
      let item;
      amount = parseInt(amount);

      if( code !== "" || code !== null || code !== undefined ){
        if(localStorage.getItem("CurrencyList") === null){
          return commas(convertIntAmountToFloatAmount(amount, 2));
        }else{
          let list = JSON.parse(localStorage.getItem("CurrencyList"));
          item = list.find(i => i.code === code);
          if(item){
            return (item.symbol === null || item.symbol === undefined) 
              ? commas(convertIntAmountToFloatAmount(amount, 2))
              : commas(convertIntAmountToFloatAmount(amount, item.decimalPosition || 2));
          }else{
            return commas(convertIntAmountToFloatAmount(amount, 2));
          }
        }
      }else{
        return commas(convertIntAmountToFloatAmount(amount, 2));
      }
    } catch (err) {
      return commas(convertIntAmountToFloatAmount(amount, 2));
    }
  }

  function handleCalculateAmount(code, amount){
    let integerAmount = 0;
    let multiplier = 2;

    const cleanedAmount = (amount+"").replace(/[^\d.]/g, '');
    const amountInFloat = parseFloat(cleanedAmount);
    try {
      let selectedCurrency;
      if( code !== "" || code !== null || code !== undefined ){
        if(localStorage.getItem("CurrencyList") === null){
          multiplier = 2;
        }else{
          let list = JSON.parse(localStorage.getItem("CurrencyList"));
          selectedCurrency = list.find(i => i.code === code);
          if(selectedCurrency){
            multiplier = selectedCurrency.decimalPosition || 2;
          }else{
            multiplier = 2;
          }
        }
      }else{
        multiplier = 2;
      }
    } catch (err) {
      multiplier = 2;
    }

    integerAmount = Math.round(amountInFloat * Math.pow(10, multiplier));
    return integerAmount;
  }

  return {
    handleShowAmount, 
    handleEditAmount,
    handleCalculateAmount,
  };
}

export default useCurrencyService;