import * as actionTypes from "../actionTypes.js";

const initialState = {
  uploadedFilesList: null,
  currenciesList: null
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_UPLOADED_FILES_BIZ:
      return { ...state, uploadedFilesList: action.payload };
    case actionTypes.GET_CURRENCIES_BIZ:
      localStorage.setItem("CurrencyList", JSON.stringify(action.payload.records));
      return { ...state, currenciesList: action.payload };
      

    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default businessReducer;