import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,    
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../components/utils/TopBar';
import NA from '../../../../components/utils/NA';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import {
  GetOtpRecordsBIZnotifications 
} from '../../../../redux/actions/business/notifications/OtpRecordsActions';

const OtpRecordsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.notifications"]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const [otpRecordsList, setOtpRecordsList] = useState([]);
  const [otpRecordsTotal, setOtpRecordsTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Ref Id",
      sort: "ref_id",
    },
    {
      label: "To",
      sort: "to",
    },
    {
      label: "Channel",
      sort: "channel",
    },
    {
      label: "Source",
      sort: "source",
    },
    {
      label: "Otp",
      sort: "otp",
    },
    {
      label: "Is used",
      sort: "is_used",
    },
    {
      label: "Is expired",
      sort: "is_expired",
    },
    {
      label: "Time stamps",
      sort: "createdAt",
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetOtpRecordsBIZnotifications(page+1, rowsPerPage, query, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      console.log(state);
      formatOtpRecordsList(state.otpRecordsList.records || []);
      setOtpRecordsTotal(state.otpRecordsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatOtpRecordsList = (list) => {
    setOtpRecordsList(list)
  };

  return (
    <Box>

      <TopBar
        title={"OTP Records"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        />
      
      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          otpRecordsList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No otp records found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                otpRecordsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography 
                        variant='caption'
                        color={"grey"}>
                        {row.ref_id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.to || <NA />}</TableCell>    
                    <TableCell>{row.channel || <NA />}</TableCell>
                    <TableCell>{row.source || <NA />}</TableCell>    
                    <TableCell>{row.otp || <NA />}</TableCell>    
                    <TableCell>
                      {
                        row.is_used === true
                        ? <CheckCircleOutlineOutlinedIcon color='success'/>
                        : <CancelOutlinedIcon color='error'/>
                      }
                    </TableCell>
                    <TableCell>
                      {
                        row.is_expired === true
                        ? <CheckCircleOutlineOutlinedIcon color='success'/>
                        : <CancelOutlinedIcon color='error'/>
                      }
                    </TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                   rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={otpRecordsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default OtpRecordsPage;