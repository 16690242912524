import { Box } from '@mui/material';
import React from 'react';
import NA from './NA';
import { toast } from 'react-toastify';

const Copyable = (props) => {

  const handleCopyText = () => {
    if(typeof props.children === "string") {
      navigator.clipboard.writeText(props.children.toString())
      toast.info("Text copied");
    } else {
      toast.error("Invalid item to copy")
    }
  }

  return (
    props?.children
    ?
    <Box
      sx={{ 
        cursor: "pointer",
        borderRadius: "10px",
        transition: "all .15s",
        width: "fit-content",
        margin: "-5px",
        padding: "5px",
        "&:hover": {
          backgroundColor: "#454545"
        } }}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onDoubleClick={() => {
          handleCopyText();
        }}>
      {props.children || <NA />}
    </Box>
    :
    <></>
  )
}

export default Copyable;