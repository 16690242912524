import React from 'react';

import { 
  Box, 
  Typography
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import "../styles.scss";

const Core = ({
  navigate, 
  currentPage
}) => {
  return (
    <Box className="nav__side-common flex_SBColumn" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Core;