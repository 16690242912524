import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress, 
  IconButton, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import TableTimeStamps from '../../../components/utils/TableTimeStamps';
import TopBar from '../../../components/utils/TopBar';

import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';

import { 
  GetContactRequestsWT, 
  UpdateContactRequestsWT 
} from '../../../redux/actions/watchTower/contactRequestActions';

import style from "../../../global.scss";
import "./styles.scss";

const ContactRequestsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.watchTower);
  const [loading, setLoading] = useState(true);
  const [markLoading, setMarkLoading] = useState(false);
  const [markId, setMarkId] = useState(null);

  const [contactRequestsList, setContactRequestsList] = useState([]);
  const [contactRequestsTotal, setContactRequestsTotal] = useState(0);

  // --------------------- table pagination ----------------------
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  useEffect(() => {
    dispatch(GetContactRequestsWT(page+1, rowsPerPage, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    try {
      formatContactRequestsList(state.contactRequestsList.Records || []);
      setContactRequestsTotal(state.contactRequestsList.Total || 0);
    } catch (err) {
      console.log(err);
    }
  }, [state]);

  // ======================== list formatter ========================
  const formatContactRequestsList = (list) => {
    setContactRequestsList(list);
  } 

  const handleMarkRead = (id) => {
    setMarkId(id);
    dispatch(UpdateContactRequestsWT(id, setMarkLoading)).then(() => {
      dispatch(GetContactRequestsWT(page+1, rowsPerPage, setMarkLoading)).then(() => {
        setMarkId(null);
      });
    })
  }

  return (
    <Box width={"100%"}>

      <TopBar 
        title={"Contact Requests"}
        />
      {
        loading
        ?
        <Box height={"200px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          contactRequestsList.length === 0
          ?
          <Box height={"200px"} className="flexCenterCenterRow">
            <Typography>No Contact Requests Found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Requested on</TableCell>
                <TableCell>Mark read</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                contactRequestsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>{row.Name}</TableCell>
                    <TableCell>{row.Email}</TableCell>
                    <TableCell>{row.Company}</TableCell>
                    <TableCell>{row.Message}</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        />
                    </TableCell>
                    <TableCell>
                      {
                        markId === row.Id && markLoading
                        ?
                        <CircularProgress size={34} />
                        :
                        <IconButton
                          onClick={() => {
                            handleMarkRead(row.Id);
                          }}>
                          {
                            row.MarkRead
                            ?
                            <DraftsOutlinedIcon sx={{ color: style['secondary_light'] }} />
                            :
                            <MarkunreadOutlinedIcon sx={{ color: style['primary_light'] }} />
                          }
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[1, 5, 10, 25, { label: "All", value: -1 }]} 
                  colSpan={10}
                  count={contactRequestsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default ContactRequestsPage