import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box,  
  CircularProgress, 
  IconButton, 
  Switch, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../components/utils/TopBar';

import EditIcon from '@mui/icons-material/Edit';

import { 
  GetCategoriesSPTconfig, 
  ToggleCategorySPTconfig 
} from '../../../../redux/actions/support/categoriesActions';

import IssueCategoriesModal from './IssueCategoriesModal';

import global from "../../../../global.scss";
import "./styles.scss";

const IssueCategoriesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["support.config"]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [toggling, setToggling] = useState(false);
  const [categoriesModalOpen, setCategoriesModalOpoen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesTotal, setCategoriesTotal] = useState(0);

  const [
    page, 
    rowsPerPage,
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  useEffect(() => {
    dispatch(GetCategoriesSPTconfig(page+1, rowsPerPage, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    try {
      formatCategoriesList(state.categoriesList.Records || []);
      setCategoriesTotal(state.categoriesList.Total || 0);
    } catch (err) {
      console.log(err);
    }
  }, [state]);

  // ======================== list formatter ========================
  const formatCategoriesList = (list) => {
    setCategoriesList(list);
  }

  const handleToggle = (id) => {
    dispatch(ToggleCategorySPTconfig(id, setToggling)).then(() => {
      dispatch(GetCategoriesSPTconfig(page+1, rowsPerPage, setToggling))
    })
  }

  return (
    <Box>
      <IssueCategoriesModal 
        open={categoriesModalOpen}
        setOpen={setCategoriesModalOpoen}
        title={modalTitle}
        update={update}
        page={page}
        rowsPerPage={rowsPerPage}
        />

      <TopBar
        title={"Categories"}
        button1Text={"Add Category"}
        button1OnClick={() => {
          setModalTitle("Add Category");
          setCategoriesModalOpoen(!categoriesModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"400px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          categoriesList.length === 0
          ?
          <Box height={"200px"} className="flexCenterCenterRow">
            <Typography>No category found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Order</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created/Updated on</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                categoriesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>{row.Title}</TableCell>
                    <TableCell>{row.Description}</TableCell>
                    <TableCell>{row.Order}</TableCell>
                    <TableCell>
                      <Switch
                        checked={row.Status} 
                        onChange={(e) => {
                          handleToggle(row.Id);
                        }}
                        />
                    </TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt} 
                        />
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={"Edit Category:" + row.Title}>
                        <IconButton
                          onClick={() => {
                            setUpdate(row);
                            setModalTitle("Edit Category " + row.Title);
                            setCategoriesModalOpoen(!categoriesModalOpen);
                          }}>
                          <EditIcon sx={{ color: global["primary_light"] }}/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                   rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={categoriesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default IssueCategoriesPage;