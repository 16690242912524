import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  Table, 
  TableBody,
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../components/utils/TopBar';
import NA from '../../../../components/utils/NA';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import {
  GetCompanyEmployessBIZidentity 
} from '../../../../redux/actions/business/identity/companyEmployeesAction';


const CompanyEmployeesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.identity"]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const [companyEmployeesList, setCompanyEmployeesList] = useState([]);
  const [companyEmployeesTotal, setCompanyEmployeesTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "GuId",
      sort: "guid",
    },
    {
      label: "User",
      sort: "user_id",
    },
    {
      label: "Company",
      sort: "company_id",
    },
    {
      label: "Branch",
      sort: "branch_id",
    }, 
    {
      label: "Employee type",
      sort: "employee_type",
    },
    {
      label: "Employee role",
      sort: "employee_role",
    },
    {
      label: "Employee status",
      sort: "employee_status",
    },
    // {
    //   label: "Employee code",
    //   sort: "employee_code",
    // },
    // {
    //   label: "Department",
    //   sort: "department",
    // },
    {
      label: "Is login allowed",
      sort: "is_login_allowed",
    },
    {
      label: "Time stamps",
      sort: "createdAt",
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetCompanyEmployessBIZidentity(page+1, rowsPerPage, query, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatCompanyEmployeesList(state.companyEmployeesList.records || []);
      setCompanyEmployeesTotal(state.companyEmployeesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatCompanyEmployeesList = (list) => {
    setCompanyEmployeesList(list);
  };

  return (
    <Box>

      <TopBar
        title={"Company Employees"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          companyEmployeesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No company employee found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                companyEmployeesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.guid}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{row?.user?.first_name || <NA />}&nbsp;{row.user?.last_name}</Typography>
                        <i><Typography>{row?.user.user_name || <NA />}</Typography></i>
                      </Box>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row?.user.id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{row?.company?.legal_name || <NA />}</Typography>
                      </Box>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row?.company?.id|| <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{row?.branch?.legal_name || <NA />}</Typography>
                      </Box>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row?.branch?.id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.employee_type || <NA />}</TableCell>
                    <TableCell>{row.employee_role || <NA />}</TableCell>
                    <TableCell>{row.employee_status || <NA />}</TableCell>
                    {/* <TableCell>{row.employee_code || <NA />}</TableCell>
                    <TableCell>{row.department || <NA />}</TableCell> */}
                    <TableCell>
                      { 
                        row.is_login_allowed === true
                        ? <CheckCircleOutlineOutlinedIcon color='success'/>
                        : <CancelOutlinedIcon color='error'/>
                      } 
                    </TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={companyEmployeesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default CompanyEmployeesPage;