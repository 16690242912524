import * as actionTypes from "../actionTypes.js";

const initialState = {
  usersList: null,
  internalUsersList: null
};

const consumerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_USERS_CON:
      return { ...state, usersList: action.payload };
    case actionTypes.GET_INTERNAL_USERS_CON:
      return { ...state, internalUsersList: action.payload };

    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default consumerReducer;