import React from 'react';

import { 
  Box, 
  Divider, 
  Typography 
} from '@mui/material';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';
import PublicIcon from '@mui/icons-material/Public';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BusinessIcon from '@mui/icons-material/Business';
import StorefrontIcon from '@mui/icons-material/Storefront';
import DataArrayIcon from '@mui/icons-material/DataArray';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';

import "./styles.scss";

const WatchTowerNavigation = ({
  path,
  currentPage, 
  navigate
}) => {
  return (
    path.length > 2 && 
    path[2] === "environment"
    ?
    <Box className="nav__side-common" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/watch");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/watch/environment");
          }}>
          <HomeOutlinedIcon />
          <Typography variant='body2'>
            Home Page
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/watch/environment/consumer");
          }}>
          <StorefrontIcon />
          <Typography variant='body2'>
            Consumer (B2C)
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/watch/environment/business");
          }}>
          <BusinessIcon />
          <Typography variant='body2'>
            Business (B2B)
          </Typography>
        </Box>
        <Divider />
        <Box className={`${currentPage === 3 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/watch/environment/sdk");
          }}>
          <DataArrayIcon />
          <Typography variant='body2'>
            SDK
          </Typography>
        </Box>
        <Box className={`${currentPage === 4 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/watch/environment/support");
          }}>
          <SupportAgentOutlinedIcon />
          <Typography variant='body2'>
            Support
          </Typography>
        </Box>
      </Box>


    </Box>
      :
        <Box className="nav__side-common flex_SBColumn" 
          display={"flex"} flexDirection={"column"} gap={"10px"}>
          <Box className="nav__side-container">
            <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
              onClick={() => {
                navigate("/watch");
              }}>
              <HomeOutlinedIcon />
              <Typography variant='body2'>
                Home Page - WatchTower
              </Typography>
            </Box>
            <Divider />
            <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
              onClick={() => {
                navigate("/watch/contact-requests");
              }}>
              <BackHandOutlinedIcon />
              <Typography variant='body2'>
                Contact Requests
              </Typography>
            </Box>
          </Box>

          <Box display={"flex"}
            flexDirection={"column"}
            gap={"10px"}>
            <Box className="nav__side-container">
              <Box className={`nav__side-item flexCenter_Row`}
                onClick={() => navigate("/watch/environment")}>
                <PublicIcon />
                <Typography variant='body2'>
                  Environments
                </Typography>
              </Box>
            </Box>
          </Box>  
        </Box>
  )
}

export default WatchTowerNavigation;