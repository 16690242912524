import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Checkbox, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Switch,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel,  
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import useSelectedTenant from '../../../../../components/hooks/useSelectedTenant';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { 
  GetCategoriesBIZcore, 
  ToggleCategoryBIZcore,
  ToggleCategoryPaymentMethodBIZcore
} from '../../../../../redux/actions/business/core/categoriesActions';

import CategoriesModal from './CategoriesModal';

import global from "../../../../../global.scss";

const CategoriesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.core"]);
  const tenantId = useSelectedTenant();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [categoriesModalOpen, setCategoriesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesTotal, setCategoriesTotal] = useState(0);

  const [categoriesMenuOpen, setCategoriesMenuOpen] = useState(false);
  const [categoriesMenuAnchor, setCategoriesMenuAnchor] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [toggling, setToggling] = useState(null);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "category_id",
    },
    {
      label: "Category",
      sort: "category",
    },
    {
      label: "Description",
      sort: "description",
    },
    {
      label: "Payment Methods",
      sort: null,
    },
    {
      label: "Active",
      sort: "is_active",
    },
    {
      label: "Time stamps",
      sort: "created_at",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    dispatch(GetCategoriesBIZcore(tenantId, page+1, rowsPerPage, query, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatCategoriesList(state.categoriesList.records || []);
      setCategoriesTotal(state.categoriesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatCategoriesList = (list) => {
    setCategoriesList(list)
  };


  const handleToggle = (id, bool) => {
    let obj = {
      "category_id": id,
      "is_active": bool
    };
    dispatch(ToggleCategoryBIZcore(tenantId, obj, setToggling)).then(() => {
      let query = ""
      if(search) query += `&search=${search}`;
      if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
      dispatch(GetCategoriesBIZcore(tenantId, page+1, rowsPerPage, query, setToggling));
    });
  };

  const handleTogglePaymentMethod = (id, paymentMethodId, bool) => {
    let obj = {
      "category_id": id,
      "payment_method_xref_id": paymentMethodId,
      "is_active": bool
    };
    dispatch(ToggleCategoryPaymentMethodBIZcore(tenantId, obj, setToggling)).then(() => {
      let query = ""
      if(search) query += `&search=${search}`;
      if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
      dispatch(GetCategoriesBIZcore(tenantId, page+1, rowsPerPage, query, setToggling));
    });
  };

  const handleGetAllPaymentMethodsBool = (list) => {
    let count = 0;
    list.forEach(i => {
      if(i.is_active === true) count += 1 
    });
    return count === list.length ? true : false;
  }


  return (
    <Box>

      <CategoriesModal 
        open={categoriesModalOpen}
        setOpen={setCategoriesModalOpen}
        title={modalTitle}
        update={update}
        page={page}
        rowsPerPage={rowsPerPage}
        />

      <TopBar
        title={"Expense categories"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add Expense category"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add Expense category");
          setCategoriesModalOpen(!categoriesModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          categoriesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No category found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                categoriesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.category_id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.category || <NA />}</TableCell>
                    <TableCell>
                      <Typography
                        variant='caption'>
                        {row.description || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Box width={"100%"}>
                        <Box className="flexCenter_Row" 
                          gap={"15px"} 
                          sx={{ width: "450px", margin: "auto" }}> 
                          <Box
                            className="flexCenter_Row">
                            <Checkbox
                              checked={handleGetAllPaymentMethodsBool(row.payment_methods)}
                              onChange={(e) => {
                                //
                              }}
                              />
                            All
                          </Box>
                          {
                            row.payment_methods.map((item, paymentMethodIndex) => (
                              <Box
                                key={paymentMethodIndex}
                                className="flexCenter_Row">
                                <Checkbox 
                                  checked={item.is_active}
                                  onChange={(e) => {
                                    handleTogglePaymentMethod(row.category_id, item.id, e.target.checked)
                                  }}
                                  />
                                {item.payment_method.name}
                              </Box>
                            ))
                          }
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={row.is_active} 
                        onChange={(e) => {
                          handleToggle(row.category_id, e.target.checked)
                        }}
                        />
                    </TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.created_at}
                        updatedAt={row.updated_at}
                        />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          setCategoriesMenuAnchor(e.currentTarget);
                          setCategoriesMenuOpen(true);
                          setUpdate(row);
                          setModalTitle(row.category || "");
                        }}>
                        <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={categoriesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={categoriesMenuOpen}
        anchorEl={categoriesMenuAnchor}
        onClose={() => setCategoriesMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setCategoriesMenuOpen(false);
            setCategoriesModalOpen(!categoriesModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default CategoriesPage;