import React from 'react';

import { 
  Box,
  Typography
} from '@mui/material';

import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// import "../styles.scss";

const FusionCurrencyCloudAccountSelect = ({
  navigate,
  currentPage
}) => {
  return (
    <Box className="nav__side-common" 
      display={"flex"} flexDirectsion={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/currency-cloud");
          }}>
          <BusinessOutlinedIcon />
          <Typography variant='body2'>
            Accounts
          </Typography>
        </Box>
      </Box>
    </Box>  
  )
}

export default FusionCurrencyCloudAccountSelect;