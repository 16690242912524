import * as actionTypes from "../../../actionTypes";
import * as api from "../../../../api/index";
import { toast } from "react-toastify";

export const GetHolidaysBIZcore = (tenantId, page, limit, query, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getHolidaysBIZcore(tenantId, page, limit, query);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_HOLIDAYS_BIZ_CORE, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const CreateHolidayBIZcore = (tenantId, formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.createHolidayBIZcore(tenantId, formData);
    if (status === 201) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const UpdateHolidayBIZcore = (tenantId, id, formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.updateHolidayBIZcore(tenantId, id, formData);
    if (status === 202) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const DeleteHolidayBIZcore = (tenantId, id, setLoading) => async (dispatch) => {
    dispatch({ type: actionTypes.CLEAR_ERRORS });
    setLoading(true);
    try {
      const { data, status } = await api.deleteHolidayBIZcore(tenantId, id);
      if (status === 202) {
        toast.success(data.message);
      }else{
        dispatch({ type: actionTypes.SET_ERRORS, payload: data });
      }
      setLoading(false);
    } catch (error) {
      dispatch({ type: actionTypes.SET_CRASHES, payload: error });
      setLoading(false);
    }
  };