import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Switch,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import useCurrencyService from '../../../../components/hooks/useCurrencyService';
import TableTimeStamps from '../../../../components/utils/TableTimeStamps';
import Copyable from '../../../../components/utils/Copyable';
import TopBar from '../../../../components/utils/TopBar';
import NA from '../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { 
  DeletePlanAddonBIZsubs, 
  GetPlanAddonsBIZsubs, 
  UpdatePlanAddonBIZsubs 
} from '../../../../redux/actions/business/subscriptions/planAddonsActions';

import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import PlanAddonsModal from './PlanAddonsModal';

import global from "../../../../global.scss";

const PlanAddonsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.subscriptions"]);
  const [loading, setLoading] = useState(true);
  const { handleShowAmount } = useCurrencyService();
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [planAddonsModalOpen, setPlanAddonsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [planAddonsList, setPlanAddonsList] = useState([]);
  const [planAddonsTotal, setPlanAddonsTotal] = useState(0);

  const [planAddonsMenuOpen, setPlanAddonsMenuOpen] = useState(false);
  const [planAddonsMenuAnchor, setPlanAddonsMenuAnchor] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Addon name",
      sort: "addon_name",
    },
    {
      label: "Addon description",
      sort: "addon_description",
    },
    {
      label: "Currency",
      sort: "currency",
    },
    {
      label: "One time setup fee",
      sort: "one_time_setup_fee",
    },
    {
      label: "Monthly fee",
      sort: "monthly_fee",
    },
    {
      label: "Yearly fee",
      sort: "yearly_fee",
    },
    {
      label: "Plan",
      sort: "ref_plan_id",
    },
    {
      label: "Active",
      sort: "is_active",
    },
    {
      label: "Time stamps",
      sort: "createdAt",
    },
    {
      label: "",
      sort: null,
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetPlanAddonsList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatPlanAddonsList(state.planAddonsList.records || []);
      setPlanAddonsTotal(state.planAddonsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatPlanAddonsList = (list) => {
    setPlanAddonsList(list)
  };

  async function handleGetPlanAddonsList(){
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetPlanAddonsBIZsubs(page+1, rowsPerPage, query, setLoading));
  };

  const handlePlanAddonsToggle = (id, planAddon, bool) => {
    let obj = {
      ...planAddon,
      is_active: bool
    };
    dispatch(UpdatePlanAddonBIZsubs(id, obj, setLoading)).then(() => {
      handleGetPlanAddonsList()
    })
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeletePlanAddonBIZsubs(deleteId, setDeleting)).then(() => {
      handleGetPlanAddonsList().then(() => {
        setDeleteId(null);
      })
    });
  }

  return (
    <Box>

      <PlanAddonsModal
        open={planAddonsModalOpen}
        setOpen={setPlanAddonsModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetPlanAddonsList}
        />

      <ConfirmationModal 
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete}
        />

      <TopBar
        title={"Plan Addons"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add Plan addon"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add Plan addon");
          setPlanAddonsModalOpen(!planAddonsModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          planAddonsList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No plan addon found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                planAddonsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.addon_name || <NA />}</TableCell>
                    <TableCell>{row.addon_description || <NA />}</TableCell>
                    <TableCell>{row.currency || <NA />}</TableCell>
                    <TableCell>
                      <Typography
                        fontWeight={700}>
                        {handleShowAmount(row.currency, row.one_time_setup_fee) || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        fontWeight={700}>
                        {handleShowAmount(row.currency, row.monthly_fee) || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        fontWeight={700}>
                        {handleShowAmount(row.currency, row.yearly_fee) || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{row?.subscription_plan?.plan_name || <NA />}</Typography>
                      </Box>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        <Copyable>
                          {row.ref_plan_id || <NA />}
                        </Copyable>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Switch 
                        checked={row.is_active}
                        onChange={(e) => {
                          handlePlanAddonsToggle(row.id, row, e.target.checked);
                        }}
                        />
                    </TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                    <TableCell>
                      {
                        row.id === deleteId && deleting
                        ?
                        <CircularProgress size={26} sx={{ margin: "7px" }}/>
                        :
                        <IconButton
                          onClick={(e) => {
                            setPlanAddonsMenuAnchor(e.currentTarget);
                            setPlanAddonsMenuOpen(true);
                            setDeleteId(row.id);
                            setUpdate(row);
                            setModalTitle(row.addon_name || "");
                          }}>
                          <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={planAddonsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={planAddonsMenuOpen}
        anchorEl={planAddonsMenuAnchor}
        onClose={() => setPlanAddonsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setPlanAddonsMenuOpen(false);
            setPlanAddonsModalOpen(!planAddonsModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setPlanAddonsMenuOpen(false);
            setDeleteConfirmationModalOpen(true);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default PlanAddonsPage;