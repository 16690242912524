import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import useSelectedTenant from '../../../../../components/hooks/useSelectedTenant';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { 
  DeleteCostObjectsBIZcore,
  GetCostObjectsBIZcore 
} from '../../../../../redux/actions/business/core/costObjectsActions';

import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import CostObjectsModal from './CostObjectsModal';

import global from "../../../../../global.scss";

const CostObjectsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.core"]);
  const tenantId = useSelectedTenant();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [costObjectsModalOpen, setCostObjectsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [costObjectsList, setCostObjectsList] = useState([]);

  const [costObjectsMenuOpen, setCostObjectsMenuOpen] = useState(false);
  const [costObjectsMenuAnchor, setCostObjectsMenuAnchor] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [deleting, setDeleting] = useState(false);  

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Name",
      sort: "name",
    },
    {
      label: "Time stamps",
      sort: "created_at",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    dispatch(GetCostObjectsBIZcore(tenantId, query, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sort, sortDirection]);

  useEffect(() => {
    try {
      console.log(state)
      formatCostObjectsList(state.costObjectsList || []);
    } catch (err) {}
  }, [state]);

  const formatCostObjectsList = (list) => {
    setCostObjectsList(list)
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteCostObjectsBIZcore(tenantId, deleteId, setDeleting)).then(() => {
      let query = ""
      if(search) query += `&search=${search}`;
      if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
      dispatch(GetCostObjectsBIZcore(tenantId, query, setDeleting)).then(() => {
        setDeleteId(null);
      })
    });
  };

  return (
    <Box>

      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete} 
        />

      <CostObjectsModal 
        open={costObjectsModalOpen}
        setOpen={setCostObjectsModalOpen}
        title={modalTitle}
        update={update}
        />

      <TopBar
        title={"Cost objects - TEMPLATE"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add Cost object"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add Cost object");
          setCostObjectsModalOpen(!costObjectsModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          costObjectsList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No cost object found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                costObjectsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.name || <NA />}</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.created_at}
                        updatedAt={row.updated_at}
                        />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          setCostObjectsMenuAnchor(e.currentTarget);
                          setCostObjectsMenuOpen(true);
                          setUpdate(row);
                          setModalTitle(row.name || "");
                        }}>
                        <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
      }
      <Menu
        open={costObjectsMenuOpen}
        anchorEl={costObjectsMenuAnchor}
        onClose={() => setCostObjectsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setDeleteId(update.id);
            setCostObjectsMenuOpen(false);
            setDeleteConfirmationModalOpen(true);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default CostObjectsPage;