import React from 'react';

import { 
  Box, 
  Tooltip, 
  Typography 
} from '@mui/material';

const TableTimeStamps = ({
  createdAt,
  updatedAt
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}>
      {
        createdAt
        &&
        <Tooltip title="Created at">
          <Typography
            variant='caption'
            sx={{ "white-space": "nowrap" }}>
            <i style={{ color: "grey" }}>
              {createdAt && new Date(createdAt).toString().substring(0, 21)}
            </i>
          </Typography>
        </Tooltip>
      }
      {
        updatedAt
        &&
        <Tooltip title="Updated at">
          <Typography
            variant='caption'
            sx={{ "white-space": "nowrap" }}>
            <i style={{ color: "grey" }}>
              {updatedAt && new Date(updatedAt).toString().substring(0, 21)}
            </i>
          </Typography>
        </Tooltip>
      }
    </Box>
  )
}

export default TableTimeStamps;