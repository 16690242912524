import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Chip, 
  CircularProgress,  
  Table, 
  TableBody,
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../components/utils/TopBar';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { 
  GetUsersBIZidentity 
} from '../../../../redux/actions/business/identity/usersActions';

const UsersPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.identity"]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const [usersList, setUsersList] = useState([]);
  const [usersTotal, setUsersTotal] = useState(0);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Username",
      sort: "user_name",
    },
    {
      label: "Name",
      sort: "first_name",
    },
    {
      label: "Is login allowed",
      sort: "is_login_allowed",
    }, 
    {
      label: "Emails",
      sort: "primary_email",
    },
    {
      label: "Mobiles",
      sort: "primary_mobile",
    },
    {
      label: "Status",
      sort: "account_status",
    },
    {
      label: "Time stamps",
      sort: "createdAt",
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetUsersBIZidentity(page+1, rowsPerPage, query, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatUsersList(state.usersList.records || []);
      setUsersTotal(state.usersList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatUsersList = (list) => {
    setUsersList(list);
  };

  return (
    <Box>

      <TopBar
        title={"Users"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          usersList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No users found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                usersList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.id}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.user_name || ""}</TableCell>
                    <TableCell>
                      <Typography>
                        {row.first_name || "-"}&nbsp;{row.last_name || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      { 
                        row.is_login_allowed === true
                        ? <CheckCircleOutlineOutlinedIcon color='success'/>
                        : <CancelOutlinedIcon color='error'/>
                      } 
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title="Primary Email">
                        <Box className="flexCenter_Row" gap={"10px"}>
                          {
                            row.is_primary_email_verified === true
                            ? <CheckCircleOutlineOutlinedIcon color='success'/>
                            : <CancelOutlinedIcon color='error'/>
                          } 
                          <Typography>{row.primary_email || ""}</Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title="Secondary Email">
                        <Box className="flexCenter_Row" gap={"10px"}>
                          {
                            row.is_secondary_email_verified === true
                            ? <CheckCircleOutlineOutlinedIcon color='success'/>
                            : <CancelOutlinedIcon color='error'/>
                          } 
                          <Typography>{row.secondary_email || ""}</Typography>
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title="Primary Mobile">
                        <Box className="flexCenter_Row" gap={"10px"}>
                          {
                            row.is_primary_mobile_verified === true
                            ? <CheckCircleOutlineOutlinedIcon color='success'/>
                            : <CancelOutlinedIcon color='error'/>
                          } 
                          <Typography>{row.primary_mobile || ""}</Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title="Secondary Mobile">
                        <Box className="flexCenter_Row" gap={"10px"}>
                          {
                            row.is_secondary_mobile_verified === true
                            ? <CheckCircleOutlineOutlinedIcon color='success'/>
                            : <CancelOutlinedIcon color='error'/>
                          } 
                          <Typography>{row.secondary_mobile || ""}</Typography>
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Chip
                        color={row.account_status === "active" 
                          ? "success" 
                          : row.account_status === "pending"
                            ? "warning"
                            : "error" }
                        label={row.account_status || ""} 
                        />
                    </TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={usersTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default UsersPage;