import { useSelector } from 'react-redux';

const useSelectedTenant = () => {
  const state = useSelector(state => state["business.core"]);

  return (
    state.selectedTenant 
    ? state.selectedTenant.tenant_id  
    : null
  )
}

export default useSelectedTenant;