import React from 'react';

import "./styles.scss";

const PriceRulesPage = () => {
  return (
    <div>PriceRulesPage</div>
  )
}

export default PriceRulesPage;