import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import global from "../../../../global.scss";
import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateCategorySPTconfig, 
  GetCategoriesSPTconfig, 
  UpdateCategorySPTconfig 
} from '../../../../redux/actions/support/categoriesActions';

const Data = {
  Title: "",
  Description: "",
  Order: -1 
}

const IssueCategoriesModal = ({
  open, 
  setOpen, 
  title,
  update,
  page, 
  rowsPerPage
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  }

  useEffect(() => {
    try {
      if(open){
        console.log(update)
        if(title === "Add Category"){
          // mode = 1 - create
          setMode(1);
        }else if(title.includes("Edit Category")){
          // mode = 2 - edit
          setMode(2);
          setFormData({
            ...formData,
            Title: update.Title || "",
            Description: update.Description || "",
            Order: update.Order || -1
          })
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = () => {
    if(mode === 1){
      // create 
      dispatch(CreateCategorySPTconfig(formData, setLoading)).then(() => {
        dispatch(GetCategoriesSPTconfig(page+1, rowsPerPage, setLoading)).then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // edit
      dispatch(UpdateCategorySPTconfig(update.Id, formData, setLoading)).then(() => {
        dispatch(GetCategoriesSPTconfig(page+1, rowsPerPage, setLoading)).then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Title"
                fullWidth
                name="Title"
                inputProps={{ maxLength: 128 }}
                value={formData.Title}
                onChange={handleChange}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Description"
                fullWidth
                name="Description"
                inputProps={{ maxLength: 256 }}
                value={formData.Description}
                onChange={handleChange}
                />
            </Box>
            {
              mode === 2
              &&
              <Box 
                className="flexCenterSBRow"
                gap={"15px"}
                mb={"15px"}>
                <TextField 
                  label="Order"
                  fullWidth
                  name="Order"
                  type='number'
                  inputProps={{ maxLength: 2 }}
                  value={formData.Order}
                  onChange={handleChange}
                  />
              </Box>
            }
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.Title !== "")}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default IssueCategoriesModal;