import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';

import { 
  GetCompaniesBIZcore 
} from '../../../../../redux/actions/business/core/companiesActions';

import { 
  CLEAR_COMPANY_BIZ_CORE,
  SELECT_COMPANY_BIZ_CORE
} from '../../../../../redux/actionTypes';

import CompaniesModal from './CompaniesModal';

import global from "../../../../../global.scss";
import Copyable from '../../../../../components/utils/Copyable';

const CompaniesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.core"]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [companiesModalOpen, setCompaniesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [companiesList, setCompaniesList] = useState([]);
  const [companiesTotal, setCompaniesTotal] = useState(0);

  const [companiesMenuOpen, setCompaniesMenuOpen] = useState(false);
  const [companiesMenuAnchor, setCompaniesMenuAnchor] = useState(null);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "company_id",
    },
    {
      label: "Name",
      sort: "company_name",
    },
    {
      label: "Tenant",
      sort: "tanent_id",
    },
    {
      label: "Orum Id",
      sort: "orum_id",
    },
    {
      label: "CC Account Id",
      sort: "cc_account_id"
    },
    {
      label: "Time stamps",
      sort: "created_at",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetCompanies();
    dispatch({ type: CLEAR_COMPANY_BIZ_CORE });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatCompaniesList(state.companiesList.records || []);
      setCompaniesTotal(state.companiesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatCompaniesList = (list) => {
    setCompaniesList(list)
  };

  async function handleGetCompanies(customLoading) {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    dispatch(GetCompaniesBIZcore(page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  return (
    <Box>

      <CompaniesModal 
        open={companiesModalOpen}
        setOpen={setCompaniesModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetCompanies}
        />

      <TopBar
        title={"Companies"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add Company"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add Company");
          setCompaniesModalOpen(!companiesModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          companiesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No company found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                companiesList.map((row, index) => (
                  <TableRow 
                    key={index}
                    className='table__clickable-row'
                    onClick={() => {
                      dispatch({ type: SELECT_COMPANY_BIZ_CORE, payload: row });
                      navigate("employees");
                    }}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        <Copyable>
                          {row.company_id || <NA />}
                        </Copyable>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Copyable>
                        {row.company_name || <NA />}
                      </Copyable>
                    </TableCell>
                    <TableCell>
                      <Typography>{row?.tanent_id?.tenant_name || <NA />}</Typography>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row?.tanent_id?.tenant_id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.orum_id || <NA />}</TableCell>
                    <TableCell>{row.cc_account_id || <NA />}</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.created_at}
                        updatedAt={row.updated_at}
                        />
                    </TableCell>
                    <TableCell>
                      <Box 
                        className="flexCenter_Row"
                        gap={"20px"}>
                        <IconButton>
                          <LoginOutlinedIcon sx={{ color: global['secondary_light'] }}/>
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation()
                            setCompaniesMenuAnchor(e.currentTarget);
                            setCompaniesMenuOpen(true);
                            setUpdate(row);
                            setModalTitle(row.name || "");
                          }}>
                          <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={companiesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={companiesMenuOpen}
        anchorEl={companiesMenuAnchor}
        onClose={() => setCompaniesMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setCompaniesMenuOpen(false);
            setCompaniesModalOpen(!companiesModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default CompaniesPage;