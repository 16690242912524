import React from 'react';

import {
  Box,
  Button,
  Divider,
  Modal, 
  Typography
} from '@mui/material';

import style from "../../global.scss";
import "./styles.scss";

const ConfirmationModal = ({open, setOpen, title, onConfirm, onCancel }) => {

  const handleClose = () => {
    setOpen(false);
    onCancel && onCancel();
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box minWidth={"400px"} className="confirm">
          <Box width={"100%"}
            sx={{ margin: "15px 15px 30px 15px"}}>
            <Typography variant='h6' sx={{ color: style["primary"] }}>
              {title}
            </Typography>
            <Typography variant='body1'>
              Are you sure you want to continue?
            </Typography>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='outlined'
              onClick={handleClose}>
              Cancel
            </Button>
            <Button 
              variant='contained' 
              color='error'
              onClick={onConfirm}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmationModal;