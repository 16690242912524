import React from 'react';

import { 
  Box, 
} from '@mui/material';

import TopBar from '../../../../components/utils/TopBar';

const SupportPage = () => {
  return (
    <Box>
      <TopBar
        title={"Support"} 
        />
        
    </Box>
  )
}

export default SupportPage