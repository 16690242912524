import { useSelector } from 'react-redux';

const useSelectedCompany = () => {
  const state = useSelector(state => state["business.core"]);

  return (
    state.selectedCompany 
    ? state.selectedCompany.company_id  
    : null
  )
}

export default useSelectedCompany;