import React from 'react';

import { 
  Typography 
} from '@mui/material';

const NA = () => {
  return (
    <Typography
      variant='caption'
      color={"grey"}>
      <i>NA</i>
    </Typography>
  )
}

export default NA;