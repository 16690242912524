import * as actionTypes from "../actionTypes.js";

const initialState = {
  activitiesList: null,
  webhooksList: null
};

const businessFusionOrumReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WEBHOOKS_BIZ_FUSION_ORUM:
      return { ...state, webhooksList: action.payload };
      

    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default businessFusionOrumReducer;