import React from 'react';

import "./styles.scss";

const CardRulesPage = () => {
  return (
    <div>CardRulesPage</div>
  )
}

export default CardRulesPage;