import React from 'react';

import { 
  Box,
  Typography
} from '@mui/material';

import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CloudQueueOutlinedIcon from '@mui/icons-material/CloudQueueOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CableIcon from '@mui/icons-material/Cable';

import "../styles.scss";

const Fusion = ({
  navigate,
  currentPage
}) => {
  return (
    <Box className="nav__side-common" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion");
          }}>
          <HomeOutlinedIcon />
          <Typography variant='body2'>
            Home Page
          </Typography>
        </Box>
				<Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/fusion/activities");
          }}>
          <FormatListNumberedIcon />
          <Typography variant='body2'>
            Activities Page
          </Typography>
        </Box>
        {/* <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/identity/tenants");
          }}>
          <ApartmentOutlinedIcon />
          <Typography variant='body2'>
            Tenants
          </Typography>
        </Box> */}

      </Box>
			<Box display={"flex"}
        flexDirection={"column"}
        gap={"10px"}>
				<Typography
					mt={"12px"}
					mb={"-5px"}>
					<i>Orum</i>
				</Typography>
        <Box className="nav__side-container">
          <Box className={`nav__side-item flexCenter_Row`}
            onClick={() => {
              navigate("/business/fusion/orum");
            }}>
            <CableIcon />
            <Typography variant='body2'>
              Orum
            </Typography>
          </Box>
        </Box>
				<Typography
					mt={"12px"}
					mb={"-5px"}>
					<i>Currency Cloud</i>
				</Typography>
        <Box className="nav__side-container">
          <Box className={`nav__side-item flexCenter_Row`}
            onClick={() => {
              navigate("/business/fusion/currency-cloud");
            }}>
            <CloudQueueOutlinedIcon />
            <Typography variant='body2'>
              Currency Cloud
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>  
  )
}

export default Fusion;