import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Chip, 
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../components/utils/TableTimeStamps';
import TopBar from '../../../components/utils/TopBar';
import NA from '../../../components/utils/NA';

import {
  GetIssuesSPT 
} from '../../../redux/actions/support/issuesActions';

import IssuesDetailsModal from './IssuesDetailsModal';

import "./styles.scss";

const IssuesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.support);
  const [loading, setLoading] = useState(true);
  const [issueDetailsModalOpen, setIssuesDetailsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [issuesList, setIssuesList] = useState([]);
  const [issuesTotal, setIssuesTotal] = useState(0);

  const [
    page, 
    rowsPerPage,
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  useEffect(() => {
    dispatch(GetIssuesSPT(page+1, rowsPerPage, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    try {
      formatIssuesList(state.issuesList.Records || []);
      setIssuesTotal(state.issuesList.Total || 0);
    } catch (err) {
      console.log(err);
    }
  }, [state]);

  // ======================== list formatter ========================
  const formatIssuesList = (list) => {
    setIssuesList(list);
  }

  return (
    <Box>

      <IssuesDetailsModal 
        open={issueDetailsModalOpen}
        setOpen={setIssuesDetailsModalOpen}
        title={modalTitle}
        id={update}
        />

      <TopBar
        title={"Issues (raised by customers)"}
        />

      {
        loading
        ?
        <Box height={"400px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          issuesList.length === 0
          ?
          <Box height={"200px"} className="flexCenterCenterRow">
            <Typography>No issues found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>User Type</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Stage</TableCell>
                <TableCell>Created/Updated on</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                issuesList.map((row, index) => (
                  <TableRow 
                    key={index}
                    onClick={() => {
                      setUpdate(row.Id);
                      setModalTitle("Details for issue - " + row.Title || <NA />);
                      setIssuesDetailsModalOpen(!issueDetailsModalOpen);
                    }}
                    className='table__clickable-row'>
                    <TableCell>{row.Title}</TableCell>
                    <TableCell>{row.Description}</TableCell>
                    <TableCell>
                      <Chip 
                        label={row.UserType}
                        />
                    </TableCell>
                    <TableCell>
                      {
                        "Title" in row.Category
                        &&
                        <Chip
                          label={row.Category.Title || ""}
                          />
                      }
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={row.Stage.Title || ""}
                        sx={{
                          backgroundColor: "#"+row.Stage.Color,
                          color: "white"
                        }}
                        />
                    </TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt} 
                        />
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                   rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={issuesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default IssuesPage;