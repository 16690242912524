import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Tooltip, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import useOnBehalfOfAccount from '../../../../../components/hooks/useOnBehalfOfAccount';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import Copyable from '../../../../../components/utils/Copyable';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { 
  DeleteBeneficiaryBIZfusionCC,
  GetBeneficiariesBIZfusionCC 
} from '../../../../../redux/actions/business/fusion/cc/beneficiariesActions';

import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';

import global from "../../../../../global.scss";

const BeneficiariesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.fusion.cc"]);
  const [loading, setLoading] = useState(true);
  const contactId = useOnBehalfOfAccount();
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const [beneficiariesList, setBeneficiariesList] = useState([]);
  const [beneficiariesTotal, setBeneficiariesTotal] = useState(0);

  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);  

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: null,
    },
    {
      label: "Name",
      sort: null,
    },
    {
      label: "Payment types",
      sort: null,
    },
    {
      label: "Beneficiary address",
      sort: null,
    }, 
    {
      label: "Currency",
      sort: null,
    },
    {
      label: "Bic swift",
      sort: null,
    },
    {
      label: "Iban",
      sort: null,
    },
    {
      label: "Time stamps",
      sort: null,
    },
    {
      label: "",
      sort: null,
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
		handleGetBeneficiariesList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sort, sortDirection]);

  useEffect(() => {
    try {
      formatBeneficiariesList(state.beneficiariesList.records || []);
      setBeneficiariesTotal(state.beneficiariesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatBeneficiariesList = (list) => {
    setBeneficiariesList(list);
  };

	async function handleGetBeneficiariesList(customLoading) {
    let query = ""
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    query += `&on_behalf_of=${contactId}`;
    dispatch(GetBeneficiariesBIZfusionCC(page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
	};

  // 
  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteBeneficiaryBIZfusionCC(deleteId, setDeleting)).then(() => {
      handleGetBeneficiariesList(setDeleting).then(() => {
        setDeleteId(null);
      })
    });
  };


  return (
    <Box>

      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete} 
        />  

      <TopBar
        title={"Beneficiaries"}
        button1Text={"Add Beneficiary"}
        button1OnClick={() => {

        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          beneficiariesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No beneficiary found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                beneficiariesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
												<Copyable>
                        	{row.id}
												</Copyable>
                      </Typography>
                    </TableCell>
										<TableCell>
											<Typography
												variant='caption'>
												<Copyable>
													{row.bankAccountHolderName || <NA />}
												</Copyable>
											</Typography>
                      <Tooltip
                        title="Name">
                        <Typography
                          variant='caption'
                          color={"grey"}>
                          <Copyable>
                            ({row.name || <NA />})
                          </Copyable>
                        </Typography>
                      </Tooltip>
										</TableCell>
										<TableCell>
                      {row.paymentTypes.join(", ") || <NA />}
										</TableCell>
                    <TableCell>
                      {row.beneficiaryAddress.join(", ") + ", " + row.bankCountry + ", " + row.stateOrProvince +  ", " + 
                      row.country + ", " + row.postalCode|| <NA />}
										</TableCell>
                    <TableCell>
                      {row.currency || <NA />}
										</TableCell>
                    <TableCell>
                      {row.bicSwift || <NA />}
										</TableCell>
                    <TableCell>
											<Typography
												variant='caption'>
												<Copyable>
													{row.iban || <NA />}
												</Copyable>
											</Typography>
										</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                    <TableCell>
                      {
                        deleteId === row.id && deleting
                        ?
                        <CircularProgress
                          size={24} 
                          />
                        : 
                        <IconButton
                          onClick={(e) => {
                            setModalTitle(row.name || "");
                            setDeleteId(row.id);
                            setDeleteConfirmationModalOpen(true);
                          }}>
                          <DeleteOutlineOutlinedIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                   rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={beneficiariesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
    </Box>
  )
}

export default BeneficiariesPage;