import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../../../../components/buttons/Button';

import { 
  CreateCompanyBIZcore, 
  UpdateCompanyBIZcore 
} from '../../../../../redux/actions/business/core/companiesActions';

import global from "../../../../../global.scss";

const Data = {
  Id: "",
  Name: "",
  TenantId: "",
  OrumId: "",
  CCAccountId: ""
};

const CompaniesModal = ({
  open, 
  setOpen, 
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  }

  useEffect(() => {
    try {
      if(open){
        if(update.defaultMode === 1){
          // mode = 1 - create company
          setMode(1);
        }else if(update.defaultMode === 2){
          // mode = 2 - edit company
          setMode(2);
          setFormData({
            ...formData,
            Id: update.company_id || "",
            Name: update.company_name || "",
            TenantId: update.tanent_id?.tenant_name || "",
            OrumId: update.orum_id || "",
            CCAccountId: update.cc_account_id || ""
          })
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if(mode === 1){
      // create company
      let obj = {
        company_id: formData.Id || "",
        company_name: formData.Name || "",
        tanent_id: formData.TenantId || "",
        orum_id: formData.OrumId || "",
        cc_account_id: formData.CCAccountId || ""
      };
      dispatch(CreateCompanyBIZcore(obj, setLoading)).then(() => {
        handleGet(setLoading).then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // update company
      let obj = {
        company_id: formData.Id || "",
        company_name: formData.Name || "",
        tanent_id: formData.TenantId || "",
        orum_id: formData.OrumId || "",
        cc_account_id: formData.CCAccountId || ""
      };
      dispatch(UpdateCompanyBIZcore(update.company_id, obj, setLoading)).then(() => {
        handleGet(setLoading).then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            {
              mode === 1
              &&
              <Box 
                className="flexCenterSBRow"
                gap={"15px"}
                mb={"15px"}>
                <TextField 
                  label="Id"
                  fullWidth
                  name="Id"
                  value={formData.Id}
                  onChange={handleChange}
                  inputProps={{ maxLength: 40 }}
                  />
              </Box>
            }
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Name"
                fullWidth
                name="Name"
                value={formData.Name}
                onChange={handleChange}
                inputProps={{ maxLength: 100 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Tenant Id"
                fullWidth
                name="TenantId"
                value={formData.TenantId}
                onChange={handleChange}
                inputProps={{ maxLength: 40 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Orum Id"
                fullWidth
                name="OrumId"
                value={formData.OrumId}
                onChange={handleChange}
                inputProps={{ maxLength: 128 }}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="CC Account Id"
                fullWidth
                name="CCAccountId"
                value={formData.CCAccountId}
                onChange={handleChange}
                inputProps={{ maxLength: 128 }}
                />
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.Name !== "" && formData.Id !== "" && formData.TenantId !== "")}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default CompaniesModal;