import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../components/utils/TableTimeStamps';
import TopBar from '../../../components/utils/TopBar';
import NA from '../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { 
  GetCurrenciesBIZ 
} from '../../../redux/actions/business/currenciesActions';

import CurrenciesModal from './CurrenciesModal';

import global from "../../../global.scss";

const CurrenciesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.business);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [currenciesModalOpen, setCurrenciesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [currenciesList, setCurrenciesList] = useState([]);
  const [currenciesTotal, setCurrenciesTotal] = useState(0);

  const [currenciesMenuOpen, setCurrenciesMenuOpen] = useState(false);
  const [currenciesMenuAnchor, setCurrenciesMenuAnchor] = useState(null);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Name",
      sort: "name",
    },
    {
      label: "Code",
      sort: "code",
    },
    {
      label: "Symbol",
      sort: "symbol",
    },
    {
      label: "Decimals",
      sort: "decimal_position",
    },
    {
      label: "Time stamps",
      sort: "createdAt",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetCurrencies();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatCurrenciesList(state.currenciesList.records || []);
      setCurrenciesTotal(state.currenciesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatCurrenciesList = (list) => {
    setCurrenciesList(list)
  };

  async function handleGetCurrencies(){
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&field=${sort}`;
    if(sortDirection) query += `&order=${sortDirection}`;
    dispatch(GetCurrenciesBIZ(page+1, rowsPerPage, query, setLoading));
  };

  return (
    <Box>

      <CurrenciesModal 
        open={currenciesModalOpen}
        setOpen={setCurrenciesModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetCurrencies}
        />

      <TopBar
        title={"Currencies - Identity"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add Currency"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add Currency");
          setCurrenciesModalOpen(!currenciesModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          currenciesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No currency found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                currenciesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.name || <NA />}</TableCell>
                    <TableCell>{row.code || <NA />}</TableCell>
                    <TableCell>{row.symbol || <NA />}</TableCell>
                    <TableCell>{row.decimal_position.toString() || <NA />}</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.createdAt}
                        updatedAt={row.updatedAt}
                        />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          setCurrenciesMenuAnchor(e.currentTarget);
                          setCurrenciesMenuOpen(true);
                          setUpdate(row);
                          setModalTitle(row.name || "");
                        }}>
                        <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={currenciesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={currenciesMenuOpen}
        anchorEl={currenciesMenuAnchor}
        onClose={() => setCurrenciesMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setCurrenciesMenuOpen(false);
            setCurrenciesModalOpen(!currenciesModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default CurrenciesPage;