import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Switch,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel,  
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import useCurrencyService from '../../../../../components/hooks/useCurrencyService';
import useSelectedTenant from '../../../../../components/hooks/useSelectedTenant';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { 
  DeleteServiceFeeBIZcore,
  GetServiceFeesBIZcore, 
  UpdateServiceFeeBIZcore
} from '../../../../../redux/actions/business/core/serviceFeesActions';

import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import ServiceFeesModal from './ServiceFeesModal';

import global from "../../../../../global.scss";

const ServiceFeesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.core"]);
  const tenantId = useSelectedTenant();
  const { handleShowAmount } = useCurrencyService();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [serviceFeesModalOpen, setServiceFeesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [serviceFeesList, setServiceFeesList] = useState([]);
  const [serviceFeesTotal, setServiceFeesTotal] = useState(0);

  const [serviceFeesMenuOpen, setServiceFeesMenuOpen] = useState(false);
  const [serviceFeesMenuAnchor, setServiceFeesMenuAnchor] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "fee_id",
    },
    {
      label: "Service Id",
      sort: "ref_service_id",
    },
    {
      label: "Fee name",
      sort: "fee_name",
    },
    {
      label: "Amount",
      sort: "fee_amount",
    },
    {
      label: "Percent",
      sort: "fee_percent",
    },
    {
      label: "Currency",
      sort: "fee_currency",
    },
    {
      label: "Min",
      sort: "fee_min",
    },
    {
      label: "Max",
      sort: "fee_max",
    },
    {
      label: "Min trx amount",
      sort: "min_trx_amount",
    },
    {
      label: "Max trx amount",
      sort: "max_trx_amount",
    },
    {
      label: "Active",
      sort: "is_active",
    },
    {
      label: "Time stamps",
      sort: "created_at",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    dispatch(GetServiceFeesBIZcore(tenantId, page+1, rowsPerPage, query, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatServiceFeesList(state.serviceFeesList.records || []);
      setServiceFeesTotal(state.serviceFeesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatServiceFeesList = (list) => {
    setServiceFeesList(list)
  };

  const handleSwitchServiceFee = (id, serviceFee, bool) => {
    let obj = {
      ...serviceFee,
      is_active: bool,
    }
    dispatch(UpdateServiceFeeBIZcore(tenantId, id, obj, setLoading)).then(() => {
      let query = ""
      if(search) query += `&search=${search}`;
      if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
      dispatch(GetServiceFeesBIZcore(tenantId, page+1, rowsPerPage, query, setLoading));
    })
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteServiceFeeBIZcore(tenantId, deleteId, setDeleting)).then(() => {
      let query = ""
      if(search) query += `&search=${search}`;
      if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
      dispatch(GetServiceFeesBIZcore(tenantId, page+1, rowsPerPage, query, setDeleting)).then(() => {
        setDeleteId(null);
      })
    });
  };

  return (
    <Box>

      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={"Delete " + modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete} 
        />

      <ServiceFeesModal 
        open={serviceFeesModalOpen}
        setOpen={setServiceFeesModalOpen}
        title={modalTitle}
        update={update}
        page={page}
        rowsPerPage={rowsPerPage}
        />

      <TopBar
        title={"Service Fees"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add Service fees"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add Service fees");
          setServiceFeesModalOpen(!serviceFeesModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          serviceFeesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No service fees found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                serviceFeesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.fee_id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.ref_service_id || <NA />}</TableCell>
                    <TableCell>{row.fee_name || <NA />}</TableCell>
                    {/* <TableCell>{row.delivery_speed?.toString() || <NA />}</TableCell>
                    <TableCell>{row.fee_type || <NA />}</TableCell>
                    <TableCell>{row.who_pays || <NA />}</TableCell> */}
                    <TableCell>{handleShowAmount(row.fee_currency, row.fee_amount || 0)}</TableCell>
                    <TableCell>{row.fee_percent?.toString() + "%" || <NA />}</TableCell>
                    <TableCell>{row.fee_currency || <NA />}</TableCell>
                    <TableCell>{handleShowAmount(row.fee_currency, row.fee_min || 0)}</TableCell>
                    <TableCell>{handleShowAmount(row.fee_currency, row.fee_max || 0)}</TableCell>
                    <TableCell>{handleShowAmount(row.fee_currency, row.min_trx_amount || 0)}</TableCell>
                    <TableCell>{handleShowAmount(row.fee_currency, row.max_trx_amount || 0)}</TableCell>
                    <TableCell>
                      <Switch 
                        checked={row.is_active}
                        onChange={(e) => {
                          handleSwitchServiceFee(row.fee_id, row, e.target.checked)
                        }}
                        />
                    </TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.created_at}
                        updatedAt={row.updated_at}
                        />
                    </TableCell>
                    <TableCell>
                      {
                        deleteId === row.fee_id && deleting
                        ?
                        <CircularProgress size={24}/>
                        :
                        <IconButton
                          onClick={(e) => {
                            setServiceFeesMenuAnchor(e.currentTarget);
                            setServiceFeesMenuOpen(true);
                            setUpdate(row);
                            setModalTitle(row.fee_name || "");
                          }}>
                          <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={serviceFeesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={serviceFeesMenuOpen}
        anchorEl={serviceFeesMenuAnchor}
        onClose={() => setServiceFeesMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setServiceFeesMenuOpen(false);
            setServiceFeesModalOpen(!serviceFeesModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDeleteId(update.fee_id);
            setServiceFeesMenuOpen(false);
            setDeleteConfirmationModalOpen(true);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default ServiceFeesPage;