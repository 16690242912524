import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../../components/utils/TopBar';
import NA from '../../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';

import TenantsModal from './TenantsModal';

import { 
  GetTenantsBIZcore 
} from '../../../../../redux/actions/business/core/tenantsActions';

import { 
  SELECT_TENANT_BIZ_CORE,
  CLEAR_TENANT_BIZ_CORE
} from '../../../../../redux/actionTypes';

import global from "../../../../../global.scss";

const TenantsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.core"]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [tenantsModalOpen, setTenantsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [tenantsList, setTenantsList] = useState([]);
  const [tenantsTotal, setTenantsTotal] = useState(0);

  const [tenantMenuOpen, setTenantMenuOpen] = useState(false);
  const [tenantMenuAnchor, setTenantMenuAnchor] = useState(null);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "tenant_id",
    },
    {
      label: "Name",
      sort: "tenant_name",
    },
    {
      label: "Time stamps",
      sort: "created_at",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    dispatch(GetTenantsBIZcore(page+1, rowsPerPage, query, setLoading));
    dispatch({ type: CLEAR_TENANT_BIZ_CORE })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatTenantsList(state.tenantsList.records || []);
      setTenantsTotal(state.tenantsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatTenantsList = (list) => {
    setTenantsList(list)
  };

  return (
    <Box>

      <TenantsModal 
        open={tenantsModalOpen}
        setOpen={setTenantsModalOpen}
        title={modalTitle}
        update={update}
        page={page}
        rowsPerPage={rowsPerPage}
        />

      <TopBar
        title={"Tenants"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add Tenant"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add Tenant");
          setTenantsModalOpen(!tenantsModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          tenantsList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No tenant found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                tenantsList.map((row, index) => (
                  <TableRow 
                    key={index}
                    className='table__clickable-row'
                    onClick={() => {
                      dispatch({ type: SELECT_TENANT_BIZ_CORE, payload: row });
                      navigate("bank-account-types");
                    }}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.tenant_id || <NA />}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.tenant_name || <NA />}</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.created_at}
                        updatedAt={row.updated_at}
                        />
                    </TableCell>
                    <TableCell>
                      <Box 
                        className="flexCenter_Row"
                        gap={"20px"}>
                        <IconButton>
                          <LoginOutlinedIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation()
                            setTenantMenuAnchor(e.currentTarget);
                            setTenantMenuOpen(true);
                            setUpdate(row);
                            setModalTitle(row.tenant_name || "");
                          }}>
                          <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={tenantsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={tenantMenuOpen}
        anchorEl={tenantMenuAnchor}
        onClose={() => setTenantMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setTenantMenuOpen(false);
            setTenantsModalOpen(!tenantsModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default TenantsPage;