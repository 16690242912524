import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';
import { GetCurrenciesBIZ } from "./business/currenciesActions";

export const LogIn = (formData, navigate, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    console.log(formData)
    const { data, status} = await api.login(formData);
    console.log(data, status);
    if (status === 200) {
      dispatch({ type: actionTypes.LOG_IN, payload: data.tokens });
      await dispatch(GetCurrenciesBIZ(1, 500, "", setLoading));
      toast.success(data.message);
      navigate("/watch");
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data.message });
      toast.error("");
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};
