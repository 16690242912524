import React from 'react';

import { 
  Box, 
  Typography
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';

import "../styles.scss";

const CoreTenantSelect = ({
  navigate, 
  currentPage
}) => {
  return (
    <Box className="nav__side-common flex__Column" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business/core");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/tenants");
          }}>
          <BusinessOutlinedIcon />
          <Typography variant='body2'>
            Tenant
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default CoreTenantSelect;