import React from 'react';

import { 
  Box, 
  Typography
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import "../styles.scss";

const CoreTenant = ({
  navigate, 
  currentPage
}) => {
  return (
    <Box className="nav__side-common flex__Column" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/tenants");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
      </Box>
      <Typography
        mt={"12px"}
        mb={"-5px"}>
        <i>Account</i>
      </Typography>
      <Box className="nav__side-container">
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/tenants/bank-account-types");
          }}>
          <SavingsOutlinedIcon />
          <Typography variant='body2'>
            Bank account types
          </Typography>
        </Box>
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/tenants/banks");
          }}>
          <AccountBalanceOutlinedIcon />
          <Typography variant='body2'>
            Banks
          </Typography>
        </Box>
        <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/tenants/holidays");
          }}>
          <CalendarMonthOutlinedIcon />
          <Typography variant='body2'>
            Holidays
          </Typography>
        </Box>
        <Box className={`${currentPage === 3 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/tenants/cost-objects");
          }}>
          <PriceChangeOutlinedIcon />
          <Typography variant='body2'>
            Cost objects [ROOT]
          </Typography>
        </Box>
        <Box className={`${currentPage === 4 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/tenants/tenant-bank-accounts");
          }}>
          <AccountBalanceOutlinedIcon />
          <Typography variant='body2'>
            Tenant Bank Accounts
          </Typography>
        </Box>
      </Box>
      <Typography
        mt={"12px"}
        mb={"-5px"}>
        <i>Billing</i>
      </Typography>
      <Box className="nav__side-container">
        <Box className={`${currentPage === 5 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/tenants/service-fees");
          }}>
          <SellOutlinedIcon />
          <Typography variant='body2'>
            Service Fees
          </Typography>
        </Box>
      </Box>
      <Typography
        mt={"12px"}
        mb={"-5px"}>
        <i>Pay</i>
      </Typography>
      <Box className="nav__side-container">
        <Box className={`${currentPage === 6 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/tenants/payment-frequencies");
          }}>
          <HistoryOutlinedIcon />
          <Typography variant='body2'>
            Payment frequencies
          </Typography>
        </Box>
        {/* <Box className={`${currentPage === 5 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/tenants/delivery-methods");
          }}>
          <LocalShippingOutlinedIcon />
          <Typography variant='body2'>
            Delivery methods
          </Typography>
        </Box> */}
      </Box>
      <Typography
        mt={"12px"}
        mb={"-5px"}>
        <i>Expense</i>
      </Typography>
      <Box className="nav__side-container">
        <Box className={`${currentPage === 7 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/tenants/payment-methods");
          }}>
          <ContactlessOutlinedIcon />
          <Typography variant='body2'>
            Payment methods
          </Typography>
        </Box>
        <Box className={`${currentPage === 8 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/tenants/categories");
          }}>
          <CategoryOutlinedIcon />
          <Typography variant='body2'>
            Categories [ROOT]
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default CoreTenant;