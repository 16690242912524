import React from 'react';

import { 
  Box,
  Typography
} from '@mui/material';

import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhishingIcon from '@mui/icons-material/Phishing';

import "../styles.scss";

const Webhook = ({
  navigate,
  currentPage
}) => {
  return (
    <Box className="nav__side-common" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/webhook");
          }}>
          <HomeOutlinedIcon />
          <Typography variant='body2'>
            Home Page
          </Typography>
        </Box>
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/webhook/activities");
          }}>
          <FormatListNumberedIcon />
          <Typography variant='body2'>
            Activities Page
          </Typography>
        </Box>
        <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/webhook/webhook-logs");
          }}>
          <PhishingIcon />
          <Typography variant='body2'>
            Webhook Logs
          </Typography>
        </Box>
      </Box>
    </Box>  
  )
}

export default Webhook;