import * as actionTypes from "../../../actionTypes";
import * as api from "../../../../api/index";
import { toast } from 'react-toastify';

export const GetSmsTemplatesBIZnotifications = (page, limit, query, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getSmsTemplatesBIZnotifications(page, limit, query);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_SMS_TEMPLATES_BIZ_NOTIFICATIONS, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const GetSmsTemplateBIZnotifications = (id, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.getSmsTemplateBIZnotifications(id);
    if (status === 200) {
      dispatch({ type: actionTypes.GET_SMS_TEMPLATE_BIZ_NOTIFICATIONS, payload: data.data });
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const CreateSmsTemplateBIZnotifications = (formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.createSmsTemplateBIZnotifications(formData);
    if (status === 201) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const UpdateSmsTemplateBIZnotifications = (id, formData, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.updateSmsTemplateBIZnotifications(id, formData);
    if (status === 202) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const DeleteSmsTemplateBIZnotifications = (id, setLoading) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.deleteSmsTemplateBIZnotifications(id);
    if (status === 202) {
      toast.success(data.message);
    }else{
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};